

.psPlugin_chatHistory {
	border: 1px solid #ccc;
	margin-top: 2px;
	background-color: #fff;
	overflow-y: scroll;
}

.psPlugin_chatHistoryBackground {
	background-color: #fff;
}

.psPlugin_Question {
	border-bottom: none;
	padding: 1px 2px;
	color: #666;
	font-weight: normal;
	font-size: 10px;
}

.chatFormArea {
	position: relative;
	/*height:30px;*/
}
/*
.psCaseAutoTextIcon {
    z-index:10;
    position: absolute;
	top: 0;
	left:1px;
	width: 12px;
	background: url(../../static/img/chat/ico_autotext.png) no-repeat 0 4px;
	height: 20px;
	cursor: pointer;
	opacity:0.5;
}

*/
.psCaseAutoTextIcon {
	cursor: pointer;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	line-height: 14px;
	padding: 1px 2px 1px;
	font-weight: bold;
	color: #999;
	background-color: #fff;
	-webkit-text-shadow: 0 1px 0 rgba(255,255,255,.3);
	text-shadow: 0 1px 0 rgba(255,255,255,.3);
	font-size: 10px;
	-webkit-transition: color 300ms;
	-moz-transition: color 300ms;
	-o-transition: color 300ms;
	transition: color 300ms;
}

	.psCaseAutoTextIcon:before {
		/*content: "◄";*/
        font-family: 'icon';
        content: '\e812';
		padding: 0 2px;
		color: #999;
        position: relative;
        font-size: 14px;
        top: 1px;
        margin-right: 5px;
	}

	.psCaseAutoTextIcon:hover {
		color: #444;
		background-position: 2px 50%;
	}

		.psCaseAutoTextIcon:hover:before {
			padding: 0 3px 0 1px;
		}


.psCaseAutoTextContainer {
	position: absolute;
	top: 10px;
	right: 260px;
	width: 250px;
	padding: 0 10px;
}

.psPlugin_chatInput {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 170px;
	padding: 0;
	padding-left: 14px;
	color: #666;
	font-family: Arial;
	font-size: 11px;
	margin: 0;
}

.psPlugin_chatInputNotes {
	width: 170px;
	margin: 2px;
	padding: 0;
	padding-left: 10px;
	height: 40px;
	font-family: Arial;
	font-size: 11px;
	color: #b9ab7f;
	font-style: italic;
	background-color: #f8f4e7;
}

.psPlugin_chatInputNotesButton {
	position: absolute;
	top: 2px;
	left: 198px;
}

.psPlugin_chatButton {
	position: absolute;
	top: 0;
	right: 0;
	width: 70px;
	height: 25px;
}



.cf {
	*zoom: 1;
}
	/* ie6/7 */
	.cf:before, .cf:after {
		content: " ";
		display: table;
	}

	.cf:after {
		clear: both;
	}

.chat-toolbar {
	padding: 0 3px 0;
	text-align: right;
	font-size: 12px;
	color: #999;
	line-height: 20px;
}

.chat-extras {
	float: left;
}

.chat textarea {
	font-family: sans-serif;
	display: block;
	width: 100%;
	margin: 0;
	padding: 4px;
	padding-left: 4px;
	padding-right: 0;
	line-height: 12px;
	font-size: 11px;
	-webkit-box-shadow: inset 0 1px 4px #ccc;
	box-shadow: inset 0 1px 4px #ccc;
	border: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: min-height 400ms ease-in-out;
	-moz-transition: min-height 400ms ease-in-out;
	-o-transition: min-height 400ms ease-in-out;
	transition: min-height 400ms ease-in-out;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	-o-transform-origin: 0 0;
	transform-origin: 0 0;
	resize: none; /* none|horizontal|vertical|both */
	height: 32px;
	max-height: 200px;
	min-height: 32px;
}

	.chat textarea:focus,
	.chat input:focus {
		outline: 0;
		outline: none;
	}

.chat-content {
	position: relative;
	margin: 0;
	padding-right: 40px;
}

	.chat-content button {
		margin: 0;
		padding: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 40px;
		text-align: center;
		font-size: 18px;
		line-height: 16px;
		font-weight: bold;
		background-color: #F1EFEF;
		/*background-image: -moz-linear-gradient(top, #eee, #cccccc);
		background-image: -webkit-linear-gradient(top, #eee, #cccccc);
		background-image: linear-gradient(top, #eee, #cccccc);*/
		color: #444;
		-webkit-text-shadow: 0 1px 0 rgba(255,255,255,.3);
		text-shadow: 0 1px 0 rgba(255,255,255,.3);
		border: none;
		position: absolute;
		/*font-family: "Arial Unicode MS", sans-serif;*/
		-webkit-transition: background 400ms 0ms;
		-moz-transition: background 400ms 0ms;
		-o-transition: background 400ms 0ms;
		transition: background 400ms 0ms;
		cursor: default;
		border-radius: 0;
		font-size: 16px;
		font-weight: bold;
	}

    .chat-content button:focus {
        box-shadow: inset 0 0 5px rgba(0,0,0,.15);
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.15);
        -moz-box-shadow: inset 0 0 5px rgba(0,0,0,.15);
        -o-box-shadow: inset 0 0 5px rgba(0,0,0,.15);
        outline: none;
    }

		.chat-content button:disabled {
			color: #888;
			box-shadow: none;
			&:hover { background-color: #999; }
		}

		.chat-content button:hover {
			background-color: #cecece;
		}

    .chatFormArea .chat-content button:after {
        font-family: 'icon';
        content: '\e810';
    }
    .notes .chat-content button:after {
        font-family: 'icon';
        content: '\e815';
    }
	.chat-content .charcount {
		-moz-pointer-events: none;
		-webkit-pointer-events: none;
		-ms-pointer-events: none;
		pointer-events: none;
		color: #999;
		width: 40px;
		overflow: hidden;
		text-align: right;
		font-size: 10px;
		position: absolute;
		bottom: 2px;
		right: 2px;
		font-weight: normal;
		line-height: 10px;
		white-space: nowrap;
		opacity: 0;
		-webkit-transition: opacity 300ms 0;
		-moz-transition: opacity 300ms 0;
		-o-transition: opacity 300ms 0;
		transition: opacity 300ms 0;
	}

.chat textarea:focus {
	border-color: #999;
}

	.chat textarea:focus + button {
		color: #666;
		border-color: #444;
	}

.chat textarea.notempty:focus + button {
	color: #444;
}

.chat-content button:disabled .charcount {
	opacity: 0;
}

.chat textarea.notempty:focus + button .charcount {
	opacity: 1;
}

.chat textarea.notempty,
.chat textarea.notempty:focus {
	/*min-height:90px;*/
	outline: none;
}

.chat textarea.exceeded {
	background-color: #fee;
}
