.modal-scroll-wrapper {
		overflow: auto;
}

.modal-content {
	.vngage-icon-cancel {
		color: #a3a4a6;
		transition: color .1s linear;

		&:hover {
			color: #444;
			transition: color .1s linear;
			cursor: pointer;
		}
	}
}

.modal-header {
	background: #e9eaee;
	border-bottom: 1px solid #b8b9bb;
	h3 {
		margin: 0 0 -5px;
	}
}

.modal-footer {
	text-align: left;
	border-top: 1px solid #c8c8c8;
}

.modal-backdrop {
	bottom:0;
}
//clickable but invisible backdrop
.modal-backdrop-invisible {
	background:transparent;
}

.modal-body .modal-title {
	margin-bottom:0.8em;
}



.modal-connection {

	.modal-dialog {
		margin-top: 20px;
	}
	//  .modal-content {
	//    border-radius: 0 0 6px 6px;
	//  }

	.modal-body {
		padding:10px;
	}
	.modal-connection-msg {
		padding: 6px 20px 6px 10px;
		float:left;
	}
	.modal-connection-actions {
		float:right;
	}

}
