.header-toolbar {
	position: absolute;
	right: 3px;
	top: 7px;
	font-size: 12px;
	button {
		margin-left: 3px;
		color: #fff;
		&:focus {
			color: #fff;
			outline-color: transparent;
		}
	}
	
}