
.header {
	color: #fff;
	height: 35px;
	background: #64BD6B;
	position: relative;
	border-radius: 10px 10px 0 0;
	flex-shrink: 0;
	&:hover {
		cursor: pointer;
	}

	.header-text {
		position: absolute;
		height: 100%;
		width: 273px;
		padding: 0 8px;
		display: flex;
		align-items: center;
		i {
			margin-right: 4px;
			font-size: 18px;
		}
	}

	.notifier {
		display: none;
	}

	@import "header-status";
	@import "toolbar";
}

