// Vendor libs
@import (less) "../node_modules/angular-bootstrap-colorpicker/css/colorpicker.css";
@import (less) "../vendor/codemirror-5.57.0/codemirror.css";
@import (less) "../vendor/dc-2.0.2/dc.css";
@import (less) "bootstrap.less";	// "bootstrap.less" imports bootstrap v4 + migration fixes
@import (less) "../vendor/angular-toastr-2.1.1.css";	// Must be imported AFTER bootstrap 4, since BS4 also defines ".toastr"
@import (less) "../vendor/fullcalendar-3.9.0/fullcalendar.css";
//@import (less) "../vendor/fullcalendar-3.9.0/lib/cupertino/jquery-ui.min.css";
@import (less) "../vendor/ui-grid-4.8.3/ui-grid.css";
@import (less) "../vendor/ui-bootstrap-4_v3.0.6/ui-bootstrap-custom-3.0.6-csp.css";
@import (less) "../vendor/codemirror-5.57.0/addon/merge/merge.css";
@import "workspace/ui-grid-overrides"; // Overrides the font paths from ui-grid css



// Common
@import "common/typography";
@import (less) "../src/css/icons-fontello/css/icon.css";
@import "common/icons-fontello"; // Overrides the font paths from fontello css
@import "common/icons";
@import "common/variables";
@import "common/styledown";
@import "common/tool-tip";


// Next - migration of workspace to react
@import "workspaceReact/main";

// Workspace
@import "workspace/main";

// Desktop / CS App
@import "legacyDesktop/desktop";

// Dialog Guest
@import "dialogguest/dialogguest";

// Offline
@import (less) "offline/offline-dark.css";
@import (less) "offline/offline-english.css";
body.sg {
	overflow-x: auto;
	overflow-y: auto;
}
.debug-report {
	z-index:99998; position:absolute; bottom:4px; right:8px; padding: 3px 5px; width:30px; height:30px; border-radius:100%; border:1px solid #ccc;
	&:hover {
		border-color: #999;
		cursor: pointer;
		transition: border-color .2s ease;
	}
}
#bug-report-icon {
	width:	100%;
	height: auto;
}
@import "theme";
