.finish-btn-container {
	padding: 8px 0px 0 0px;

	.finish-btn {
		letter-spacing: .03em;
		padding: 3px 17px 3px 6px;
		width: 100%;
		color: #00b7c9;
		background-color: transparent;
		border-color: #00b7c9;

	}
}
