.form-group .alert-danger {
    display: block;
}

.nav-tabs > li {
  cursor: pointer;
}

tr[ui-sref] {
    cursor: pointer;
}

.vngage-selectable-items {
	padding-bottom: 18px;

	.card-heading {
		padding: 15px 15px;
		label {
			display: inline-block;
			margin-bottom: 0;
		}
	}

	.card-title {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 16px;
		color: inherit;
	}

	.form-group {
		position: relative;
		top: -8px;
		width: 100%;

		input[type="search"] {
			border: 0;
			background: transparent;
			box-shadow: none;
			max-width: 150px;
			position: absolute;
			right: 0;
			padding-right: 25px;
			text-align: right;
			font-family: 'vngage';
		}

		i {
			color: #d2d2d2;
			font-size: 26px;
			position: absolute;
			// top: 4px;
			right: -8px;
			pointer-events: none;
		}
	}

    .card-body {
			height: 400px;
			overflow: auto;
			padding: 0px 15px;
			.row {
				display: block;
				border-bottom: 1px solid lighten(#e3e3e3, 5%);
				padding: 10px 15px;
				cursor: pointer;
			}
			p {
				color: #00b7c9;
				cursor: pointer;
				display: inline;
				vertical-align: sub;
				margin-bottom: 0;
				width: 100%;
			}
		}

	&.items-controls {
		margin-top: 55px;

		.row {
			margin-left: 0;
			margin-right: 0;

			.btn {
				width: 100%;
				margin-bottom: 10px;
				[class^="vngage-icon-"]:before, [class*=" vngage-icon-"]:before {
					margin: 0;
					display: inline;
					font-weight: bold;
				}
			}

		}
	}

	p:after {
		color: darken(@table-bg-hover, 10%);
		display: inline-block;
		font-size: 18px;
		font-family: 'icon';
		padding: 1px;
		opacity: 0;
		visibility: visible;
		vertical-align: sub;
		-webkit-transition: all .1s;
		-moz-transition: all .1s;
		-ms-transition: all .1s;
		-o-transition: all .1s;
		transition: all .1s;
	}

	.row:hover > p:after {
		opacity: 1;
	}

	&.available p:after {
		content: '\e805';
		float: right;
		margin-right: 8px;
	}

	&.selected {
		p:after {
			content: '\e821';
			float: left;
			margin-left: -15px;
		}

		.row:hover > p:after {
			margin-right: 15px;
			margin-left: 8px;
		}
	}
}

.component-stringtags-tag-x {
    cursor: pointer;
}

#navmenu i.fa {
    padding-top: 11px;
}

.funnel-head {
	span { font-size: 11px; display: block; }
}

.funnel-footer { margin-bottom: 60px; }

.arrow_box {
	position: relative;
}

.arrow_box:before {
	left: -20px;
	top: 33%;
	border: solid transparent;
	content: " ";
	height: 9px;
	width: 20px;
	position: absolute;
	background: #f27480;
}

.arrow_box:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-left-color: #f27480;
	border-width: 10px;
	margin-top: -10px;
}

.single-top-arrow {
	margin-bottom: 5px !important;
}

.arrow_down_medium {
	margin: 0 auto;
	position: unset;
	width: 20px;
	display: block;
	height: 60px;
}

.arrow_down_medium:before {
	border: solid transparent;
	content: " ";
	height: 50px;
	margin-top: 5px;
	margin-left: 4px;
	width: 12px;
	position: absolute;
	background: #c7c7c7;
}

.arrow_down_medium:after {
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(199, 199, 199, 0);
	border-top-color: #c7c7c7;
	border-width: 10px;
	margin-top: 55px;
}

.arrow_down_large {
	margin: 0 auto;
	position: unset;
	width: 20px;
	display: block;
}

.arrow_down_large:before {
	height:434px;
	margin-left: 4px;
	margin-top: 5px;
	border: solid transparent;
	content: " ";
	width: 12px;
	position: absolute;
	background: #c7c7c7;
}

.arrow_down_large:after {
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(199, 199, 199, 0);
	border-top-color: #c7c7c7;
	border-width: 10px;
	margin-top: 439px;
}

.vngage-funnel-purchase, .vngage-funnel-purchase:before {
	width: 25%;
}

.vngage-funnel-purchase {
	float:left;
	padding: 0;
}

.vngage-funnel-drill, .vngage-funnel-drill:before {
	width: 55%;
}

.vngage-funnel-drill {
	float: left;
	padding: 0;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix { display: inline-block; }

* html .clearfix { height: 1%; }
.clearfix { display: block; }

.vngage-funnel-item {
	position: relative;
}

@trapezoidHeight: 30px;

.trapezoid {
	position: relative;
	display: block;
	width: 100%;
	background: #f27480;
	height: @trapezoidHeight;
	overflow: hidden;
	text-align: center;
	margin: 0 auto;

	&.trapezoid-purchase {
		background: rgb(220,220,220);
		margin-top: 450px;

		span {
			color: black;
		}
	}

	+ div {
		position: absolute;
		left: 110%;
		top: 5px;

		.funnel-val {
			position: relative;
			right: -30px;
			font-weight: bold;
		}
	}

	span {
		position: relative;
		color: white;
		margin-top: 5px;
		display: block;
	}


	@trapezoidTrans: width 1s ease-out;
	.trapezoid-fill {
		background: rgb(158, 199, 123);
		position: absolute;
		left: 0;
		top:0;
		display: inline-block;
		min-width: 0;
		bottom: 0;
		-webkit-transition: @trapezoidTrans;
		-moz-transition: @trapezoidTrans;
		-o-transition: @trapezoidTrans;
		transition: @trapezoidTrans;
	}

	.trapezoid-left, .trapezoid-right {
		position: absolute;
		left: 0;
		top:0;
		height: 0;
		width: 0px;
		margin: 0 0 10px 0;
		border-bottom: @trapezoidHeight solid white;
		border-left: 0px solid transparent;
		border-right: (@trapezoidHeight/2) solid transparent;
		z-index:2;
		background: transparent;
	}

	.trapezoid-right {
		border-left: (@trapezoidHeight/2) solid transparent;
		border-right: 0px solid transparent;
		left: auto;
		right: 0;
	}
}

.trapezoid-header {
	&:extend(.trapezoid);
	background: rgb(218, 218, 218);
	height: (@trapezoidHeight*1.5);

	.trapezoid-left {
		&:extend(.trapezoid .trapezoid-left);
		border-bottom: @trapezoidHeight*1.5 solid white;
		border-right: ((@trapezoidHeight*1.5)/2) solid transparent;
	}

	.trapezoid-right {
		&:extend(.trapezoid .trapezoid-right);
		border-bottom: @trapezoidHeight*1.5 solid white;
		border-left: ((@trapezoidHeight*1.5)/2) solid transparent;
	}

	h4 {
		margin: 5px 0 0;
	}
}

.vngage-funnel-outcome {

	.funnel-dropout {
		text-align: center;
		position: relative;
		top: -35px;
		left: -70px;
		margin: -15px 50%;
		width: 140px;
		background: rgba(255,255,255,.7);
		box-shadow: 0 0 13px rgba(255,255,255,1);

		h5 {
			margin: 1px 0 0;
		}

		span {
			font-size: 80%;
			color: rgba(0,0,0,0.4);
		}

	}

	&.vngage-funnel-outcome-last {
		.arrow_down_medium {
			visibility: hidden;
		}
	}
}

.submit-buttons {
	border-top: solid 1px #ddd;
	margin-top: 30px;
	padding-top: 15px;
}

.preview-parsed-html {
	background: #f1f1f1;
	width: 100%;
	display: block;
	padding: 10px 20px;
	border-radius: 4px;
	word-wrap: break-word;

	b {
		font-family: 'vngage-semibold';
		font-weight: normal;
	}
}

.status-indication {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: inline-block;
	margin: 6px 7px;
	&.col-1 {
		padding: 0;
	}
	&.active { background: @brand-success; }
	&.suspended { background: @brand-warning; }
	&.blocked { background: @brand-danger; }
}

.no-show {
	.arrow_box,
	.funnel-val {
		visibility: hidden;
	}
}
