.psPanel_header .icon {
	float: right;
	height: 20px;
	width: 40px;
	text-align: center;
	cursor: pointer;
	margin-top: 1px;
	opacity: 0.5;
}

.psPanel_header .icon-minmax:hover,
.psPanel_header:hover .icon-minmax {
	opacity: 1;
}

.header-identification {
	margin-left: -6px;
	padding-left: 22px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAVCAMAAABvwg4pAAAAMFBMVEX///////////////////////////////////////////////////////8AAAD///++CtLxAAAAD3RSTlMq8brMyBEMbbeePMnvgAC+V5o/AAAAZklEQVR42o2OWwrAIAwE7dtX3PvftluCGEoE50PIDgOGNqhANadVIItK0Gnc+XQkYKZA5X9DlcVRGdjCtyhWHbx3vzqpil8VVV71UIlX6Xb7VaJKl262yhFKzP8Kg3VlWVVTgsyMvFUQHjwOwhnZAAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	background-size: 18px;
	background-position: 0 0;
}

.header-identification:after {
	content: " - ";
}

.header-identification.suggested {
	background: none;
	padding-left: 0;
}

.header-identification.suggested:before {
	content: '?';
	float: left;
	min-width: 22px;
}

.icon-minmax {
	background: no-repeat url(img/desktop/icons/ico_fullscreen.png) 50% 50%;
}

.visitorPanelMaximized .icon-minmax {
	background-image: url(img/desktop/icons/ico_minimize.png);
}

.visitorPanelWrapper {
	top: 10px;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.visitorPanelMaximized {
	position: absolute;
	right: 280px;
	z-index: 800;
	top: 10px;
	left: 5px;
	bottom: 15px;
	width: auto;
	overflow-y: visible;

	.psPanel_container {
		height: 100%;
		display: flex;
		flex-direction: column;

		.psPanel_content {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.coworker-container {
			flex: 1
		}
	}

	.coWorkerRightArea {
		width: 271px;
		background: #F3F3F3;
		border-left: 1px solid #ccc;
	}
}

.visitorPanelMinimized {
	position: static;
	margin-right: 0;
	margin-left: auto;
	width: 272px;
	top: auto;
	left: auto;
	right: 0;
	height: auto;
	z-index: 801;
}

.coWorkerContentElem {
	overflow: visible;
	position: relative;
}

//.coWorkerLeftArea {}

.coWorkerRightArea {
	text-align: left;
	width: 270px;
}

.coWorkerCoBrowsingArea {
	text-align: left;
}

.coWorkerPlugininArea {
	text-align: left;
}

.coWorkerDropZoneWrapper {
	border-top: 1px solid #ccc;
	flex-shrink: 0;
}

.coWorkerDropZoneArea {
	background-color: #e6e6e6;
	height: 80px;
	text-align: left;
	overflow-y: auto;
	position: relative;
	box-sizing:border-box;

	&:before {
		font-family: 'icon';
		content: '\e814';
		position: absolute;
		left: 50%;
		margin-left: -25px;
		top: 0;
		color: #ccc;
		font-size: 48px;
		line-height: 80px;
		pointer-events: none;
	}
	div {
		display: inline-block;
		margin-right: 7px;
	}
}

.coWorkerActionPanelArea {
	max-height: 110px;
	text-align: left;
	border-top: 1px solid #ccc;
	overflow-y: auto;
	flex-shrink: 0;
}

.coWorkerSectionHeader {
	background-color: #F1EFEF;
	background: -moz-linear-gradient(top, #dadbdc, #cccccc);
	background: -webkit-linear-gradient(top, #dadbdc, #cccccc);
	color: #999;
	font-size: 10px;
	padding: 0 3px;
	text-align: center;
	height: 12px;
	line-height: 10px;
}


.psPanel_headerGray {
	background-color: #6e6e6e !important;
}

.panelSelectedBlue {
	background-color: #2369ac !important;
}

.panelSelectedYellow {
	background-color: #ff6666 !important;
}

.panelSelectedGreen {
	background-color: #ab3192 !important;
}

.panelSelectedRed {
	background-color: #000000 !important;
}

.panelSelectedOrange {
	background-color: #ab3192 !important;
}

.coWorkerButtonArea {
	position: relative;
	padding: 0 5px 5px !important;

	.coworker-btn-start {
		background-color: #f80;
	}
	.coworker-btn-end {
		background: #1b9f2a;
		border-color:darken(#1b9f2a, 2%);

		&:hover {
			background: darken(#1b9f2a, 5%);
		}
	}

	button:hover {
		-webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
		box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
	}
}

.coWorkerVisitorDetailsArea {
	.chatWrapper {
		.legacy-tool-bar { margin-top: -5px; }
	}
}
//.copy-title {
//	&.tool-tip {
//		position: absolute;
//	}
//}
