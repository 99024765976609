.booked-meeting-info-section-modal {
  display: flex;
  flex-direction: column;

  .btn-toolbar {
    text-align: right;
  }

  .form-label {
    font-family: 'vngage-bold';
  }

  .form-content {
  }
}