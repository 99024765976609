//Switch-style checkboxes,
//adapted from this article:
//lea.verou.me/2013/03/ios-6-switch-style-checkboxes-with-pure-css/

@color-off: #f04e5e;
@color-on:#00b7c9;
@color-switch:#888;

input[type="checkbox"].switch:not(:checked),
input[type="checkbox"].switch:checked { /* :checked here acting as a filter for older browsers */
	position: absolute;
	opacity: 0;
	cursor: pointer;
	pointer-events: none;
}

label.flipswitch {
	position: relative;
	display: inline-block;
	margin: 0 5px;
	-webkit-text-shadow: 0 1px 1px rgba(255,255,255,.5);
	text-shadow: 0 1px 1px rgba(255,255,255,.5);
	cursor: pointer;
	font-weight: normal;
	-webkit-user-select:none;
	-moz-user-select:none;
	user-select:none;
	box-sizing:border-box;
}
.flipswitch-label {
	vertical-align: middle;
}



.flipswitch-size-m {
	input[type="checkbox"].switch + span { font-size:16px; }
}
.flipswitch-size-l {
	input[type="checkbox"].switch + span { font-size:32px; }
}
.flipswitch {
	input[type="checkbox"].switch + span {
		box-sizing:content-box;
		display: inline-block;
		vertical-align: middle;
		width: 1.8em;
		height: 1em;
		border: 1px solid;
		border-color: #cecece;
		border-radius: 10px;
		margin: 0 .2em;
		background-image: linear-gradient(rgba(0,0,0,.4), transparent);
		-moz-background-origin: border-box;
		-webkit-background-origin: border-box;
		background-origin: border-box;
		-moz-background-clip: border-box;
		-webkit-background-clip: border-box;
		background-clip: border-box;
		overflow: hidden;
		-moz-transition-duration: 200ms;
		-o-transition-duration: 200ms;
		-webkit-transition-duration: 200ms;
		transition-duration: 200ms;
		-moz-transition-property: padding, width, text-indent;
		-o-transition-property: padding, width, text-indent;
		-webkit-transition-property: padding, width, text-indent;
		transition-property: padding, width, text-indent, background;
		font-size: 120%; /* change this and see how they adjust! */
		background:#fff;
	}

	//ON
	input[type="checkbox"].switch:checked + span {
		padding-left: .8em;
		width: 1em;
		border-color: #00b7c9;
		-webkit-text-shadow: 0 -1px 1px rgba(0,0,0,.3);
		text-shadow: 0 -1px 1px rgba(0,0,0,.3);
	}

	input[type="checkbox"].switch:checked + span:before {
		background: #00b7c9;
	}

	input[type="checkbox"].switch + span:before {
		content: '';
		float: left;
		display: block;
		width: 1.7em;
		height: 1.7em;
		border-radius: inherit;
		background: #00b7c9;
		background-image: linear-gradient(hsla(0,0%,100%,.3), hsla(0,0%,100%,0));
		color: white;
		text-indent: -4em;
		text-align: center;
		background: #999;
	}


	input[type="checkbox"].switch:active + span:before,
	input[type="checkbox"].switch:hover + span:before {
		//background-color: #f3f3f3;
	}

	//input[type="checkbox"].switch:focus + span {
	//	-webkit-box-shadow: 0 0 .3em 1px #fff, 0 1px 1px hsla(0,0%,100%,.8);
	//	box-shadow: 0 0 .3em 1px #fff, 0 1px 1px hsla(0,0%,100%,.8);
	//}

	input[type="checkbox"].switch + span:before,
	input[type="checkbox"].switch + span:after {
		font: normal 60%/1.9 sans-serif;
		text-transform: uppercase;
	}

	input[type="checkbox"].switch + span:after {
		content: '';
		float: left;
		text-indent: .84em;
		color: #fff;
	}

}

.flipswitch.flipswitch-colorful {
	input[type="checkbox"].switch + span {
		border: 1px solid #000;
		background: @color-off;
	}
	input[type="checkbox"].switch:checked + span {
		background: @color-on;
	}
	input[type="checkbox"].switch + span:before {
		background:@color-switch;
	}
	input[type="checkbox"].switch:active + span:before,
	input[type="checkbox"].switch:hover + span:before {
		background-color: #999;
	}
}
