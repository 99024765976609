
// Contains chat message formatting
// as well as chat history popover-styling.
// broken out from chat.less, due to that the elements are used
// outside of #desktopLegacy

.psPlugin_chatMsg {
	font-size:11px;
	margin: 2px 10px;
	padding: 4px;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	position: relative;

	p {
		//background:red;
	}

	ol {
		margin:1em 0;
		padding:0;
		border:none;

		li {
			margin:0.2em 0 .2em 1.5em;
			list-style-type:decimal;
			overflow:visible;
		}
	}

	ul {
		margin:1em 0;
		padding:0;
		border:none;

		li {
			padding:0;
			margin:0.2em 0 .2em 1.5em;
			list-style-type:square;
			overflow:visible;
		}
	}


}



.psPlugin_chatMsg_user {
	color: #25517c;
	font-style: normal;
	background-color: #e1ebf3;
	border: 1px solid #e1ebf3;
	margin: 3px 9px 2px 41px;
	border-radius: 2px;
	padding: 5px 10px;

	h1, h2, h3, h4, h5, h6 {
		font-size: 16px;
		line-height: 18px;
		padding-bottom: 0;
		margin-bottom: .5em;
	}

	blockquote {
		font-style: italic;
		border-left: 3px solid #25517c;
		&:before, &:after {
			content: ' " ';
		}
	}

	.chat-timestamp {
		position: absolute;
		left: -40px;
		font-size: 9px;
		width: 40px;
		top: 7px;
	}

}

.psPlugin_chatMsg_user:before {
	border-color: transparent transparent transparent #e1ebf3;
	-moz-border-image: none;
	-o-border-image: none;
	-webkit-border-image: none;
	border-image: none;
	border-style: solid;
	border-width: 5px;
	content: "";
	display: inline-block;
	position: absolute;
	right: 0;
	left: auto;
	width: 0;
}


.psPlugin_chatMsg_visitor {
	color: #665544;
	font-style: normal;
	background-color: #fff4d4;
	border: 1px solid #fff4d4;
	margin: 2px 40px 2px 9px;
	border-radius: 2px;
	padding: 5px 10px;

	h1, h2, h3, h4, h5, h6 {
		font-size: 16px;
		line-height: 18px;
		padding-bottom: 0;
		margin-bottom: .5em;
	}

	blockquote {
		font-style: italic;
		border-left: 3px solid #665544;
		&:before, &:after {
			content: ' " ';
		}
	}

	.chat-timestamp {
		position: absolute;
		right: -43px;
		font-size: 9px;
		width: 40px;
		top: 7px;
	}
}


.psPlugin_chatMsg_visitor:before {
	border-color: transparent #fff4d4 transparent transparent;
	-moz-border-image: none;
	-o-border-image: none;
	-webkit-border-image: none;
	border-image: none;
	border-style: solid;
	border-width: 5px;
	content: "";
	display: inline-block;
	position: absolute;
	left: -10px;
	right: auto;
	width: 0;
}

.psPlugin_chatMsg_guest {
	color: #665544;
	font-style: normal;
	background-color: #EDE3E9;
	border: 1px solid #F4DDE3;
	margin: 2px 40px 2px 9px;
	border-radius: 2px;
	padding: 5px 10px;

	h1, h2, h3, h4, h5, h6 {
		font-size: 16px;
		line-height: 18px;
		padding-bottom: 0;
		margin-bottom: .5em;
	}

	blockquote {
		font-style: italic;
		border-left: 3px solid #665544;
		&:before, &:after {
			content: ' " ';
		}
	}

	.chat-timestamp {
		position: absolute;
		right: -43px;
		font-size: 9px;
		width: 40px;
		top: 7px;
	}
}


.psPlugin_chatMsg_guest:before {
	border-color: transparent #F4DDE3 transparent transparent;
	-moz-border-image: none;
	-o-border-image: none;
	-webkit-border-image: none;
	border-image: none;
	border-style: solid;
	border-width: 5px;
	content: "";
	display: inline-block;
	position: absolute;
	left: -10px;
	right: auto;
	width: 0;
}


.psPlugin_chatMsg_comment {
	color: #b6b6b6;
	font-style: italic;
	margin: 0 10px;
	padding: 2px 5px;
}


	.popover-overlay {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,.2);
		z-index: 799;
		display: block;
		-webkit-transition: opacity 400ms 100ms linear;
		-moz-transition: opacity 400ms 100ms linear;
		-o-transition: opacity 400ms 100ms linear;
		transition: opacity 400ms 100ms linear;
	}

	.popover {
		position: absolute;
		z-index: 1010;
		display: none;
		padding: 1px;
		background-color: #ffffff;
		border: 1px solid #ccc;
		border: 1px solid rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
		-webkit-background-clip: padding-box;
		-moz-background-clip: padding;
		background-clip: padding-box;
	}
	.popover.left {
		left:auto;
		right: 270px;
		color: #666;
		width: 270px;
		height: auto;
	}


	.popover-title, h3.popover-title {
		padding: 2px 8px;
		margin: 0;
		font-size: 11px;
		font-weight: bold;
		line-height: 14px;
		color: #444;
		-webkit-text-shadow: 0 1px 0 rgba(255,255,255,.3);
		text-shadow: 0 1px 0 rgba(255,255,255,.3);
		border-bottom: 1px solid #aaa;
		-webkit-border-radius: 4px 4px 0 0;
		-moz-border-radius: 4px 4px 0 0;
		border-radius: 4px 4px 0 0;
		background: #dadbdc;
		background: -moz-linear-gradient(top, #dadbdc, #cccccc);
		background: -webkit-linear-gradient(top, #dadbdc, #cccccc);
		background: linear-gradient(top, #dadbdc, #cccccc);
	}

	.popover-content {
		font-size: 11px;
		padding: 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.popover-msg {
		padding: 16px 8px;
	}

	.popover ul.list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.popover .list li {
		list-style: none;
		padding: 8px;
		border-top: 1px solid #eee;
	}

	.popover .list li:first-child {
		border-top: none;
	}

	.popover .list li:hover {
		background: #e1ebf3;
	}

	.popover p {
		margin: 0 -5px;
		padding: 5px;
	}


	.popover-content p, .popover-content ul, .popover-content ol {
		margin-bottom: 0;
	}

	.popover .arrow,
	.popover .arrow::after {
		position: absolute;
		display: inline-block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	.popover .arrow::after {
		z-index: -1;
		content: "";
	}

	.popover.right .arrow {
		top: 50%;
		left: -10px;
		margin: -10px 0;
		border-right-color: #fff;
		border-width: 10px 10px 10px 0;
	}

	.popover.right .arrow::after {
		top: 50%;
		left: -10px;
		margin: -10px -1px;
		border-width: 10px;
		border-right-color: #ccc;
		border-right-color: rgba(0,0,0,0.2);
	}

	.popover.left .arrow {
		top: 50%;
		right: -10px;
		margin: -5px 0 0;
		border-left-color: #fff;
		border-width: 10px 0 10px 10px;
	}

	.popover.left .arrow::after {
		top: 50%;
		right: -10px;
		margin: -10px -1px;
		border-width: 10px;
		border-left-color: #ccc;
		border-left-color: rgba(0,0,0,0.2);
	}


[data-cstooltip],
.cstooltip {
	position: relative;
	cursor: pointer;
	color: rgb(181, 181, 181);

	&:hover {
		color: rgb(0,0,0);
	}
}

/* Base styles for the entire tooltip */
[data-cstooltip]:before,
[data-cstooltip]:after,
.cstooltip:before,
.cstooltip:after {
	position: absolute;
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	-webkit-transition:
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	-webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	-moz-transition:
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	-moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	transition:
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform:    translate3d(0, 0, 0);
	transform:         translate3d(0, 0, 0);
	pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-cstooltip]:hover:before,
[data-cstooltip]:hover:after,
[data-cstooltip]:focus:before,
[data-cstooltip]:focus:after,
.cstooltip:hover:before,
.cstooltip:hover:after,
.cstooltip:focus:before,
.cstooltip:focus:after {
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.cstooltip:before,
[data-cstooltip]:before {
	z-index: 1001;
	border: 6px solid transparent;
	background: transparent;
	content: "";
}

/* Base styles for the tooltip's content area */
.cstooltip:after,
[data-cstooltip]:after {
	z-index: 1000;
	padding: 8px;
	width: 130px;
	background-color: #000;
	background-color: hsla(0, 0%, 20%, 0.9);
	color: #fff;
	content: attr(data-cstooltip);
	font-size: 11px;
	line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-cstooltip]:before,
[data-cstooltip]:after,
.cstooltip:before,
.cstooltip:after,
.cstooltip-top:before,
.cstooltip-top:after {
	bottom: 100%;
	left: 50%;
}

[data-cstooltip]:before,
.cstooltip:before,
.cstooltip-top:before {
	margin-left: -6px;
	margin-bottom: -12px;
	border-top-color: #000;
	border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-cstooltip]:after,
.cstooltip:after,
.cstooltip-top:after {
	margin-left: -80px;
}

[data-cstooltip]:hover:before,
[data-cstooltip]:hover:after,
[data-cstooltip]:focus:before,
[data-cstooltip]:focus:after,
.cstooltip:hover:before,
.cstooltip:hover:after,
.cstooltip:focus:before,
.cstooltip:focus:after,
.cstooltip-top:hover:before,
.cstooltip-top:hover:after,
.cstooltip-top:focus:before,
.cstooltip-top:focus:after {
	-webkit-transform: translateY(-12px);
	-moz-transform:    translateY(-12px);
	transform:         translateY(-12px);
}

/* Left */
.cstooltip-left:before,
.cstooltip-left:after {
	right: 100%;
	bottom: 50%;
	left: auto;
}

.cstooltip-left:before {
	margin-left: 0;
	margin-right: -12px;
	margin-bottom: 0;
	border-top-color: transparent;
	border-left-color: #000;
	border-left-color: hsla(0, 0%, 20%, 0.9);
}

.cstooltip-left:hover:before,
.cstooltip-left:hover:after,
.cstooltip-left:focus:before,
.cstooltip-left:focus:after {
	-webkit-transform: translateX(-12px);
	-moz-transform:    translateX(-12px);
	transform:         translateX(-12px);
}

/* Bottom */
.cstooltip-bottom:before,
.cstooltip-bottom:after {
	top: 100%;
	bottom: auto;
	left: 50%;
}

.cstooltip-bottom:before {
	margin-top: -12px;
	margin-bottom: 0;
	border-top-color: transparent;
	border-bottom-color: #000;
	border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.cstooltip-bottom:hover:before,
.cstooltip-bottom:hover:after,
.cstooltip-bottom:focus:before,
.cstooltip-bottom:focus:after {
	-webkit-transform: translateY(12px);
	-moz-transform:    translateY(12px);
	transform:         translateY(12px);
}

/* Right */
.cstooltip-right:before,
.cstooltip-right:after {
	bottom: 50%;
	left: 100%;
}

.cstooltip-right:before {
	margin-bottom: 0;
	margin-left: -12px;
	border-top-color: transparent;
	border-right-color: #000;
	border-right-color: hsla(0, 0%, 20%, 0.9);
}

.cstooltip-right:hover:before,
.cstooltip-right:hover:after,
.cstooltip-right:focus:before,
.cstooltip-right:focus:after {
	-webkit-transform: translateX(12px);
	-moz-transform:    translateX(12px);
	transform:         translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.cstooltip-left:before,
.cstooltip-right:before {
	top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.cstooltip-left:after,
.cstooltip-right:after {
	margin-left: 0;
	margin-bottom: -16px;
}
