
.edit-navigation {
	//display: flex;
}

.back-navigation {
	//position: absolute;
	position: relative;
	top: -60px;
	left: -6px;
	display: inline-block;
	font-size: 12px;
	cursor: pointer;
	padding: 5px;

	&.disabled {
		pointer-events: none;
		cursor: not-allowed;
		opacity: .5;
	}

	.back-border {
		padding: 2px;
		border: 2px solid #cecece;
		border-radius: 100%;
		transition: all .2s ease;
	}

	i {
		color: #cecece;
		text-shadow: -1px 0px 0px #cecece;
		transition: all .2s ease;

		&.mirrored {
			&:before{
				transform-origin: center;
				transform: scale(-1,1);
			}
		}
	}

	&:hover {

		.back-border {
			border-color: darken(#cecece, 30%);
			transition: all .2s ease;
			transform: scale(1.1);

		}

		i {
			color: darken(#cecece, 30%);
			text-shadow: -1px 0px 0px darken(#cecece, 30%);
			transition: all .2s ease;
			transform: scale(1);
		}
	}
}