@table-header-color: #fdf3e5;

.monitor-is-paused {
	vertical-align: super;
	padding: 0.2em 0.3em;
	font-size: 12px;
}

//temp. displaying metadata on :hover
.temp-extra-info {
	display:none;
	position:absolute;
	margin-top:20px;
	right:0;
}
.convo-meta-duration:hover + .temp-extra-info {
	display:block
}
/** oiuhergoihergoihergoiherg **/

.monitor-content {
	.no-data {
		position: absolute;
		top: 60px;
		left: 50%;
		max-width: 200px;
		text-align: center;
		color: #999;
		border: 1px dashed #ccc;
		border-radius: 3px;
		padding: 10px 20px;
		margin-left: -100px;
		margin-top:20px;
	}
}

.mod-monitor {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height:100%;

	.legacy-tip {
		cursor:help !important;
	}
	//TODO: consistency! fixes a gap between tip block and arrow...
	.legacy-tip:before {
		margin-top: 12px;
	}

	.col-monitor-main {
		width:70%;
		//padding:15px;
		padding:40px;
		flex:1 1 auto;
		display: block;
		overflow-y:auto;

		h2 {
			i { font-size: 120%; }
		}

	}
	.col-monitor-convos {
		width:300px;
		overflow-y: auto;
		flex:1 1 300px;
		display: flex;
		flex-direction: column;
		flex-wrap:nowrap;
		align-items:stretch;
		border-left:1px solid #ccc;
	}
}
.mod-monitor-header {
	padding-top:10px;
}



.header-aside {
	.metric-site-visitors span {
		font-size: 12px;
	}
	.metric-dialogs-today {
		padding-left:1em;
	}
}

.monitor-header,
.monitor-toolbar {
	flex:0 0 auto;
	//background:#ccc;
	//border-bottom:1px solid #999;
}
.monitor-header {
	.nav-tabs {
		padding: 8px 0 0;
		background: #eee;

		.nav-link {
			font-family: 'vngage-semibold';
		}
		.nav-item.active .nav-link, .nav-item.active .nav-link:focus {
			color:#fff;
			border-color:#444;
			background:#444;
		}
	}
}
.monitor-footer {
	flex:0 0 30px;
	background:#ccc;
	border-top:1px solid #999;
}
.monitor-content {
	flex: 1 1 auto;
	overflow-y:auto;
	position:relative;

}

.convos-header {
	margin:0;
	padding:4px .2em;
	background:#ccc;
	min-height: 28px;


	button {
		margin-top:-5px;
		margin-bottom:-3px;
	}

	&.convos-header-group {
		background:#444;
		color:#eee;
		padding-left:.4em;
		line-height: 1.4em;
		border-bottom:1px solid #222;
		border-top:1px solid #555;

		&:first-child {
			border-top:none;
			padding-left: 7px;
		}
	}
	&.convos-header-state {
		background:@table-header-color;
		border-top:1px solid darken(@table-header-color, 10%);
		font-size:12px;
		padding-right:0.8em;
	}

	a {color:@text-color;}

	.badge {
		margin-left:2px;
		font-size:10px;
		padding:2px 5px 1px;
		border-radius:5px;
	}
}

.convos {
	margin: 0;
	padding:0;
	list-style:none;

	.convo {
		margin: 0;
		padding:4px .8em 4px .2em;
		list-style:none;
		border-bottom:1px solid #ddd;
		display:flex;
		align-items:flex-end;

		.vngage-icon-agent:before{
			margin-right: 7px;
		}
	}
}

.convo-meta {
	font-size:12px;
	margin:0 .2em;
	small {
		display: block;
		font-size: 10px;
		color:#ccc;
	}
}

.convo-meta-agent,
.convo-meta-visitor,
.convo-meta-state {
	flex:1;
}

.convo-meta-duration,
.convo-meta-activity {
	text-align:right;
}

.mod-monitor {
	//narrow down on padding on this section:
	&#main {
		padding:40px;
	}

	.kpi {
		white-space: nowrap;
		border-radius: 2px;
		padding:0 2px;
	}
	.kpi-reached {
		border-radius: 2px;
		background-color:@presence-away-color;
		color: white;
	}
	.kpi-reached-text {
		color: @presence-away-color;
	}
}


thead.thead-accent {
	font-size:12px;
	background:@table-header-color;
	border-top:1px solid #ddd;//darken(@table-header-color, 10%);
	//border-bottom:1px solid darken(@table-header-color, 10%);
	font-family: 'vngage-semibold';
}
.col-label {
	float:right;
	font-weight:normal;
	color:rgba(0,0,0,.5);
	margin-right:.2em;
}

.table-queue {
	margin-bottom: 40px;
	> thead > tr > th { border: 0; }
	th, tr {text-align: right; font-weight: normal;}

	.value-duration {white-space:nowrap;}

	._0 {
		text-align:left;
		white-space: nowrap;
	}
}
.table-agents-online {
	> thead > tr > th { border: 0; }
	th { font-weight: normal; }
}
	.vngage-users-online {

		.profile-picture {
			width:32px;
			height:32px;
			border-radius: 100%;
			vertical-align: middle;
			display:inline-block;
			margin-right:10px;

			img {
				border-radius: 100%;
				width: 32px;
				height: 32px;
			}
		}


		.presence-away {
			.profile-picture {

				img {
					border: 3px solid @presence-away-color;
				}
			}

			a.username {
				color: @presence-away-color;
			}
		}

		.table {
			tbody {
				td {
					vertical-align: middle;
					padding:5px 8px;
				}
			}
		}

	}

.col-realtime-updates {
	padding-top:20px;

	.flipswitch {
		margin-right:0;
		.switch + span {margin-right:0;}
	}
}


.convos-byGroup {
	li.convo {
		padding-left:1.4em;
		padding-right: .8em;
	}
}

.convos-byOwnerUser {
	li.convo {
		padding-left:1.2em;
		padding-right: .8em;
	}
}

.mod-monitor {
	.monitor-item-wrapper {
		&:hover {
			background:#F1F1F1;
			cursor:pointer;
		}
	}

	.vngage-hide {
		visibility:hidden;
	}

	.btn-margin-top {
		margin-top:8px;
	}
}
