.psIframeTopMenu {
	padding: 0 1px 1px 1px;
	vertical-align: top;
	border-bottom: 1px solid #ccc;
}

	.psIframeTopMenu input {
		/*width: 350px;*/
		padding: 0 5px;
		border: 1px solid #ccc;
		height: 14px;
		line-height: 14px;
		margin: 0;
		font: 11px arial;
	}
/*
.psIframeTopMenu button {}
*/

.psDialoglockScreen {
	background-color: #fff;
	background-color: rgba(255,255,255,.8);
	/*line-height: 330px;*/
	/*text-align: center;*/
	font-size: 16px;
	font-weight: bold;
	color: rgba(0,0,0,.6);
	position: absolute;
	top: 0;
	right: 17px; /*for scrollbars */
	bottom: 17px;
	left: 0;
	display: none;
	/*pointer-events: none;*/
}



.vg-status {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	//height: 0;
	height:40px;
	overflow:hidden;
	-webkit-perspective: 300px;
	-moz-perspective: 300px;
	-ms-perspective: 300px;
	perspective: 300px;
	-webkit-perspective-origin: 0% 50%;
	-moz-perspective-origin: 0% 50%;
	-ms-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
	z-index: 1;
	width:40px;
}
.vg-status.show,
.vg-status:hover {
	width:auto;
}

.vg-status-msg {
	height: 36px;
	line-height: 36px;
	padding-left: 36px;
	white-space: nowrap;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: rgba(0,0,0,.5);
	color: #fff;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
	-webkit-transform-origin: 100% 100%;
	-moz-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	-o-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
	-webkit-transform: translate3d( 0, -100px, -100px ) rotateX(-90deg);
	-moz-transform: translate3d( 0, -100px, -100px ) rotateX(-90deg);
	-ms-transform: translate3d( 0, -100px, -100px ) rotateX(-90deg);
	-o-transform: translate3d( 0, -100px, -100px ) rotateX(-90deg);
	transform: translate3d( 0, -100px, -100px ) rotateX(-90deg);
	margin-right: 20px;
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	display: block;
}

	.vg-status.show .vg-status-msg,
	.vg-status-msg.show,
	.vg-status-msg:hover,
	.vg-status-icon:hover + .vg-status-msg {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
		-moz-transform: translate3d(0, 0, 0) scale(1);
		-ms-transform: translate3d(0, 0, 0) scale(1);
		-o-transform: translate3d(0, 0, 0) scale(1);
		transform: translate3d(0, 0, 0) scale(1);
		opacity: 1;
	}

.vg-status-icon {
	cursor: help;
}

.vg-status-icon .icon-bg {
	content: "";
	position: absolute;
	width: 56px;
	height: 56px;
	z-index: 1;
	-webkit-transform-origin: left top;
	-webkit-transform: translate(-40px, 0) rotate(-45deg);
	-webkit-transition: background-color 500ms;
	-moz-transform-origin: left top;
	-ms-transform-origin: left top;
	-o-transform-origin: left top;
	transform-origin: left top;
	-moz-transform: translate(-40px, 0) rotate(-45deg);
	-ms-transform: translate(-40px, 0) rotate(-45deg);
	-o-transform: translate(-40px, 0) rotate(-45deg);
	transform: translate(-40px, 0) rotate(-45deg);
	-moz-transition: background-color 500ms;
	-o-transition: background-color 500ms;
	transition: background-color 500ms;
	background-color: #eee;
	background-image: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,.4) 100%);
}


.vg-status.active .icon-bg,
.vg-status-active .icon-bg {
	background-color: #3c3;
}

.vg-status.paused .icon-bg,
.vg-status-paused .icon-bg {
	background-color: #eee;
}

.vg-status.passive .icon-bg,
.vg-status-passive .icon-bg {
	background-color: #e00;
}

.vg-status.progress .icon-bg,
.vg-status-progress .icon-bg {
	background-color: #fc0;
}


.vg-status-icon:before {
	position: absolute;
	top: 1px;
	left: 6px;
	content: "";
	display: block;
	width: 20px;
	height: 15px;
	z-index: 2;
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAJdJREFUeNpi/P//PwMMMDExyQApPyAWA+JXQLzp379/T2DyjDDFQIVsQKoAiLkYEOAbEE8AavgFVoMkIYWmkAHKl4HbzEAYsJGi+C82xW+A+B+aQhD/OYZioCe+AKmjaIqPQsVRFQNDgwNIiaIpFoWKI4IOKKAFZHsBMQ8WN4NM3ga04RojEIQDOZpEePQ6SHEDA5EAIMAAqN4rrrEg/UkAAAAASUVORK5CYII=);
}

.vg-toolbar {
	background:#f3f3f3;
}
