.video-chat-section {
	width: 100%;
}

.web-call-section {
	width: 100%;
}

.web-call-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: black;

	.mic {
		width: 33px;
		height: 33px;
		font-size: 21px;
		background: darkgray;
		color: white;
		border-radius: 50%;
		border: 2px solid gainsboro;

		&.recording {
			background: red;
		}

		&:hover {
			background: @component-active-bg;
		}
	}

	.mic-status-text {
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		user-select: none;
		color: white;
		padding-left: 15px;
		display: flex;
		align-items: center;
		height: 64px;
		max-width: 120px;
		width: 100px;
	}

	.audio-div {
		width: 1px;
	}
}

.video-chat-container {
	width: 100%;
	min-height: 205px;
	position: relative;
	user-select: none;
	background: #000000;

	&:hover {
		.video-toolbar-bottom {
			display: inline-block;
		}

		.video-peer-info {
			display: flex;
		}
	}

	.video-overlay {
		width: 100%;
		height: 100%;
	}

	.video-loading {
		background: #000;
		position: absolute;
		width: 100%;
		height: 100%;
		color: #fff;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		Button {
			margin-top: 15px;
		}

		.loading-spinner {
			border-radius: 100%;
			width: 24px;
			height: 24px;
			margin-top: 15px;
			border: 1px solid @brand-success;
			border-bottom-color: transparent;
			.animation(rotation .8s infinite linear);
		}

		.icon {
			color: @component-active-bg;
			font-size: 25px;
			margin-top: 7px;
			border-color: transparent;
		}
	}

	.video-error {
		background: #000;
		position: absolute;
		width: 100%;
		height: 100%;
		color: #fff;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		Button {
			margin-top: 15px;
		}

		.loading-spinner {
			border-radius: 100%;
			width: 24px;
			height: 24px;
			margin-top: 15px;
			border: 1px solid @brand-success;
			border-bottom-color: transparent;
			.animation(rotation .8s infinite linear);
		}

		.icon {
			color: @component-active-bg;
			font-size: 25px;
			margin-top: 7px;
			border-color: transparent;
		}
	}

	.video-toolbar-bottom {
		background: rgba(0, 0, 0, 0.6);
		height: 30px;
		width: 100%;
		display: none;
		position: absolute;
		bottom: 0;

		i {
			color: darkgray;
			display: block;
			float: right;
			margin: 1px 7px 0 3px;
			font-size: 20px;

			&:hover {
				color: fade(#00b7c9, 70%);
				cursor: pointer;
			}

			&.active {
				color: #00b7c9;
			}
		}
	}

	.video-peer-info {
		width: 100%;
		position: absolute;
		display: none;
		flex-direction: column;

		.cameraUnavailableInfo {
			flex: 1;
			display: none;
			position: absolute;
			height: 100%;
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #fff;
			background: #000;

			i {
				color: #444;
				font-size: 25px;
				margin-bottom: 7px;
				border-color: transparent;
			}
		}

		&.cameraUnavailable {
			display: flex;

			.cameraUnavailableInfo {
				display: flex;
			}
		}

		&.modal {
			padding-right: 30px;
		}

		&.fullscreen {
			padding-right: 30px;
		}
	}

	.video-stream-info {
		padding-top: 3px;

		.icons {
			height: 30px;

			i {
				color: #444;
				display: block;
				float: right;
				margin: 1px 7px 0 3px;
				font-size: 20px;

				&.available {
					color: @brand-success;
				}
			}
		}
	}

	.video-visitor-window {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.video-agent-window {
		position: absolute;
		bottom: 5px; //due to pos:rel on .video-canvas
		left: 5px;
		width: 20%;
		height: auto;
		min-height: 45px;
		max-height: 100%;
		z-index: 210;
		border-radius: 5px;
		-webkit-transition: all 400ms ease-in-out;
		-moz-transition: all 400ms ease-in-out;
		-ms-transition: all 400ms ease-in-out;
		-o-transition: all 400ms ease-in-out;
		transition: all 400ms ease-in-out;
		transition-delay: 200ms;
		transition-duration: 300ms;
	}

	.video-agent-window:hover {
		width: 50%;
	}
}