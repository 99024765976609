.navbar, .navbar-inverse, .navbar-fixed-top {
  border: 0;
  border-width: 0;
  background: @black;
}

.navbar > .container-fluid .navbar-brand {
  margin-left: 0;
}

.navbar-header {
  .nav {
    margin: 10px 75px 0;

    > li > a {
      background: transparent;
      color: #999;
      transition: all .3s ease 0s;
    }

    > li > a:hover {
      color: #fff;
      transition: all .3s ease 0s;
    }
  }
}

.table-bordered {
  border: 0;

  > thead,
  > tbody,
  > tfoot {
    > tr {
			> th,
			> td {
				border-style: solid;

				+ th,
				+ td {
					border-left-width: 1px !important;
				}
			}

			> th {
				border-color: @table-border-color;
				border-width: 0 0 1px 0 !important;
			}
			> td {
				border-color: @table-bg-hover;
				border-width: 0 !important;
			}

    }
  }
}

// First line transparent fix
// Needs to also reset hover style, as it is otherwise overridden
.table-striped > tbody {
	> tr:nth-child(even) {
		> td,
		> th {
			background-color: @table-bg-accent;
		}
	}
	> tr:nth-child(odd) {
		> td,
		> th {
			background-color: transparent;
		}
	}

    > tr:nth-of-type(odd) {
        background-color: transparent;
    }
}

.table-hover > tbody > tr:hover {
	> td,
	> th {
		background-color: @table-bg-hover;
	}
}
// End first line transparent fix

.tab-wrap {
  .tab-content {
    margin-top: 20px;
  }

  .label {
    margin-left: 20px;
  }
}

.nav-open .breadcrumb {
	width: ~"calc(100% - 335px)";
}
.breadcrumb {
  color: rgba(0,0,0,.3);
  border-radius: 0;
  padding: 0;
  position: absolute;
  z-index: 999;
  padding-left: 40px;
  background: rgba(255, 255, 255, 0.92);
  font-size: 12px;
	width: 70%;
	width: ~"calc(100% - 215px)";
  height: 37px;
  padding-top: 3px;

  li {
    position: relative;
    padding: 7px 0 8px;
    cursor: default;
  }

  > li i {
    font-size: 15px;
    line-height: 0;
    color: rgba(0,0,0,.2);
    position: relative;
    top: 1px;
  }

  > li + li:before {
		font-family: 'icon';
    content: @breadcrumb-separator;
	}

  .active {
    color: rgba(0,0,0,.3);
  }

  a {
    color: rgba(0,0,0,.3);
    transition: color .3s ease 0s;
  }
  a:hover {
    color: rgba(0,0,0,.5);
    text-decoration: none;
    transition: color .3s ease 0s;
  }
}

.btn {
	border-width: 2px;
	&:focus,
	&:active { outline: 0; }
  + .btn {
    margin-left: 5px;
  }

  &[class^="vngage-icon-"]:before,
  &[class*=" vngage-icon-"]:before {
    margin-left: -0.2em;
  }
}

.btn-group-vertical {
	> .btn + .btn,
	> .btn + .btn-group,
	> .btn-group + .btn,
	> .btn-group + .btn-group {
		margin-top: -1px;
		margin-left: 0;
	}
}

.header-btn {
  margin-top: 5px;
}

.card {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none;

	&.card-default {
		background-color: #fff;
		border-color: #e3e3e3;
		border-width: 2px;
		> .card-heading   {
			color: #444444;
			background-color: #f5f5f5;
			border-color: #ddd;
		}
	}

	.card-header {
		background: #f2f0f1;
		border-bottom: 2px solid #e3e3e3;
		//font-family: @headings-font-family;
	}

	.card-heading {
		border-bottom: 1px solid transparent;
		padding: 10px 15px;
		border-width: 2px;
	}
	.card-body {
		svg {
			rect.bar:hover {
				fill-opacity: 1;
			}
		}
	}

	.card-section {
		padding: @card-heading-padding;
		background-color: @card-default-heading-bg;
	}

	.card-footer {
		border-width: 2px;
	}

	.x-axis-label,
	.y-axis-label {
		font-size: 9px;
		text-transform: uppercase;
		font-family: @headings-font-family;
	}
}

.btn-default:hover {
	background: #9a9a9a;
	color: #fff;
}

.btn-link-delete {
  color: @btn-delete-color;
  background: @btn-delete-bg;
	border-color: fade(@btn-delete-border-color,70%);
	//font-style: italic;
	//text-decoration: underline;

	&:disabled,
	&.disabled {
		color: @btn-delete-color;
	}
  &:hover,
  &:focus {
    color: @btn-delete-color-hover;
    background: @btn-delete-bg-hover;
    //text-decoration: none;
  }
}

.btn-success {
	padding: 6px 20px;
}

.tooltip-inner {
	padding: 10px 20px;
	text-align: left;
}
.nav-tabs {
	border-width: 2px;
	> .nav-item {
		&.active > .nav-link, .nav-link.active {	// Angular's <uib-tabset> sets the nav-link "active", while BS4 sets "nav-item" active. Style them both...
			font-family: 'vngage-semibold';
			&,
			&:hover,
			&:focus {
				color: @nav-tabs-active-link-hover-color;
				background-color: @nav-tabs-active-link-hover-bg;
				border: 2px solid @nav-tabs-active-link-hover-border-color;
				border-bottom-color: transparent;
				cursor: default;
			}
		}
	}
}

.btn[disabled] {
  opacity: 0.35;
}

.form-control {
	height: 36px;
}
.form-control, textarea {
	min-height: 36px;
}
.form-control,
textarea,
.CodeMirror{
	border-width: 2px !important;
	box-shadow: none;
	border-radius: 4px;
}

.input-group-btn {
	&:last-child {
		> .btn,
		> .btn-group {
			margin-left: -2px;
		}
	}
}
.input-group-addon { border-width: 2px; }
.input-group-append {
	.input-group-text {
		border-width: 2px 2px 2px 1px;
	}
}


/***********************************************************************************
	Bootstrap 4 adaptations
************************************************************************************/
/*
Style bootstrap 4's "badge" as the old "label" used to look
*/
.badge {
	min-width: 10px;
	padding: .2em .6em .3em;
	color: @badge-color;
}
.badge-pill {
	border-radius: @badge-pill-border-radius;
}

/*
Class "btn-xs" is removed from bootstrap 4. Re-implement...
*/
.btn-xs,
.btn-group-xs > .btn {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}


/*
Adjust buttons to match Bootstrap 3
 */
.btn:focus,
.btn.active,
.btn:active {
	outline: 0;
}
/*
btn-default is removed from BS4 (or rather replaced by "btn-secondary", but that's styled differently!)
Re-implement...
*/
.btn-default,
.btn-default.disabled,
.btn-default:disabled {
	color: @btn-default-color;
	background-color: #fff;
	border-color: #ccc;
}
.btn-default:active, .btn-default.active {
	background-color: #e6e6e6;
	border-color: #adadad;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
// Change button colors where BS4 differs from BS3
.btn-warning,
.btn-warning:focus,
.btn-warning:hover,
.btn-warning:active,.btn-warning.active,
.btn-warning.disabled,.btn-warning:disabled,
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
	color: @btn-warning-color;
}
.btn-success,
.btn-success:focus,
.btn-success:hover,
.btn-success:active,.btn-success.active,
.btn-success.disabled,.btn-success:disabled,
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
	color: @btn-success-color;
}
.btn-info,
.btn-info:focus,
.btn-info:hover,
.btn-info:active,.btn-info.active,
.btn-info.disabled,.btn-info:disabled,
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
	color: @btn-info-color;
}

// Add bottom margin to form-check
.form-check {
	margin-bottom: 5px;
}

// Buttons appended to input-elements with empty content would be too narrow to look good. Set min-width to make them ~ square
.input-group-append .btn.input-group-text {
	min-width: 32px;
}

// Style the IDP-selector component using BS3 class "panel" (its src is not in Workspace project)
.container.idp-selector {
	h5 {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.panel.panel-default {
		margin-bottom: 20px;
		background-color: #fff;
		border: 2px solid #e3e3e3;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-ms-box-shadow: none;
		box-shadow: none;

		.panel-heading {
			color: #444444;
			background: #f5f5f5;
			padding: 10px 15px;
			border-bottom: 2px solid #ddd;
		}
		.panel-body {
			padding: 15px;
		}
	}
}

// Remove focus effect on close-buttons in modals (added by default in BS4)
.modal-header .close:focus {
	outline: 0;
}
