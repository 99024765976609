.mirrored-x {
	&:before{
		transform: scale(-1,1);
	}
}

.copy-toast {
	z-index: 1000;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the copytoast in and out */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.lineClampEllipsis {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.lineClampEllipsis1Line {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.lineClampEllipsis1LineExtraLong {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
}

.modal-container {
	position: relative;
}

.modal-container .modal, .modal-container .modal-backdrop {
	position: absolute;
}

.inline-divider {
	position: relative;
	width: 100%;
	height: 1px;
	background: #ccc;
}

.tooltip-inner {
	max-width: 100%;
}

.refCode {
	position: absolute;
	top: -14px;
	right: 10px;
	font-size: 10px;
	color: rgb(142, 142, 142);
}

.copy-button {
	cursor: pointer;
}

.line-clamp {
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	position: relative;
	line-height: 1.2;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 !important;
}

.line-clamp:after {
	content: '...';
	text-align: right;
	bottom: 0;
	right: 0;
	width: 25%;
	display: block;
	position: absolute;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
}

@supports (-webkit-line-clamp: 1) {
	.line-clamp:after {
		display: none !important;
	}
}

.line-clamp-1 {
	-webkit-line-clamp: 1;
}

.line-clamp-2 {
	-webkit-line-clamp: 2;
}

.line-clamp-3 {
	-webkit-line-clamp: 3;
}

.line-clamp-4 {
	-webkit-line-clamp: 4;
}

.line-clamp-5 {
	-webkit-line-clamp: 5;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.no-wrap {
	white-space: nowrap;
}
