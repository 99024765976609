.visitor-meta {
	min-height: 50px;
	padding: 5px 10px;
	display: flex;
	color: #444;
	border-left: 0;
	border-right: 0;
	border-bottom: 1px solid #cecece;
	flex-grow: 0;
	font-size: 12px;
	width: 273px;
	b {
		font-family: 'vngage-semibold', sans-serif;
		display: block;
	}
	.meta-data {
		flex: 1;
		p { margin: 0; }
	}
	i[class^="vngage-icon-"],
	i[class*=" vngage-icon-"] {
		align-items: center;
		display: flex;
		font-size: 22px;
		padding: 2px 10px 0px;
	}
	.duration {
		font-size: 10px;
		text-align: center;
		font-family: 'vngage-bold';
		color: #333;
	}
	&:hover {
		cursor: default;
	}
	.visitor-identification {
		display: inline-flex;
		p {
			marign: 0;
		}
		i {
			display: inline-block;
			font-size: 12px;
			padding-left: 0;
			padding-top: 0;
		}
	}
	.loading-meta-spinner {
		border-radius: 100%;
		width: 10px;
		height: 10px;
		margin-top: 5px;
		margin-bottom: 2px;
		display: block;
		z-index: 2;
		border: 1px solid @brand-success;
		border-bottom-color: transparent;
		.animation(rotation .8s infinite linear);
	}
	.no-visitor-joined {
		color: #900000;
		font-weight: bold;
	}
	.browser-meta-info {
		font-size: 11px;
	}
}
