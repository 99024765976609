.visitor-profile-section-modal {
	display: flex;
	flex-direction: column;


	.visitor-identification-area {
		padding-bottom: 30px;
	}

	.visitor-claims-area {
		padding-bottom: 15px;
	}

	.visitor-identification-area,
	.visitor-claims-area {
		display: flex;
		flex-direction: column;

		.area-head {
			display: flex;
			overflow: hidden;

			h5 {
				display: table;
				padding-right: 15px;
				font-family: 'vngage-bold', sans-serif;
			}

			.inline-divider {
				top: 8px;
			}
		}
	}

	.form-row {
		display: flex;
	}

	.form-visitorprofile {
		.form-group {
			margin-left: 0;
			margin-bottom: 15px;
		}

		.control-label {
			text-align: left;
			font-weight: bold;
			margin-bottom: 1px;
		}
	}

	.btn-toolbar {
		text-align: right;

		.btn-success {
			padding: 6px 12px;

			&.indicate-sent {
				animation-name: color-swap;
				transform-origin: center bottom;
				animation-duration: 2s;
			}
		}
	}

	.pre-chat-journey {
		border-left: 2px solid #999;

		.item {
			display: flex;

			.open-link {
				display: flex;
				overflow: hidden;
				position: relative;

				.scroll-wrapper {
					width: 300px;
					line-height: 1.3;
				}
			}

			.index {
				display: flex;
				justify-content: space-between;

				.marker {
					width: 6px;
					height: 6px;
					background: #999;
					border-radius: 100%;
					position: relative;
					left: -4px;
					top: 7px;
					padding-right: 6px;

					&.green {
						background: #84b859;
					}

					&.yellow {
						background: #ffcb05;
					}

					&.orange {
						background: orange;
					}

					&.purple {
						background: #ab3192;
					}

					&.blue {
						background: #2f4b9b;
					}

					&.red {
						background: #f04e5e;
					}

					&.brown {
						background: #d2612a;
					}

					&.gray {
						background: #444;
					}

					&.lightgray {
						background: lightgray;
					}
				}
			}
		}
	}

}

.tooltip {
	z-index: 9999;
}