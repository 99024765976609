&.medium-text-size {
	.message-agent,
	.message-visitor {
		.message {font-size: 120%;}
	}
}
&.large-text-size {
	.message-agent,
	.message-visitor  {
		.message {font-size: 160%;}
	}
}



.user-preferences-modal {
	.btn-group {
		margin-bottom: 10px;
	}
	.btn-default-text-size,
	.btn-medium-text-size,
	.btn-large-text-size {
		background: #fff;
		color: #999;
		border-color: #ccc;
	}
	&.default-text-size {
		.btn-default-text-size { color: #66d4df; }
	}
	&.medium-text-size {
		.well { font-size: 120%; }
		.btn-medium-text-size { color: #66d4df; }
	}
	&.large-text-size {
		.well { font-size: 160%; }
		.btn-large-text-size { color: #66d4df; }
	}
}