.emoji-picker {
	z-index: 100;
	position: absolute;
	bottom: 190px;
	left: 2px;
}

.emoji-symbol::before {
	font-family: 'icon';
	content: '\f118';
	cursor: pointer;
	font-size: 14px;
}