.nav-open {

	#navmenu {
		width: 150px;
		cursor: url('./img/arrow-collapse.png'), auto;
		display: flex;
		flex-direction: column;

		a.logo-container {
			padding: 0;
			background-color: @logoExpandedBackground;
			min-height: 45px;
			max-height: 60px;
			.logo-expanded {
				height: auto;
				width: 100%;
				max-width: 150px;
				max-height: 60px;
				padding: @logoExpandedPadding;
				flex: 0;
				flex-shrink: 0;
				visibility: visible;
				opacity: 1;
				transition: opacity .2s ease, height .2s ease, width .2s ease;
			}
			.logo-collapsed {
				visibility: hidden;
				opacity: 0;
				height: 0;
				width: 0;
			}
		}

		.light-logo-small {
			visibility: hidden;
			opacity: 0;
		}
		ul {
			overflow-y: auto;
			overflow-x: hidden;
			li {
				a {
					i {
						&.unpublished {
							float: right;
							margin-right: 15px;
							color: @brand-warning;
							font-size: 11px;
							opacity: 1;
						}
					}
				}
			}
		}
		ul li:hover {
			span {
				background: transparent;
				width: inherit;
				padding-left: 0;
				margin-left: 5px;
				border-radius: 0;

			}
			span:before {
				display: none;
			}
		}

		#user-container {
			cursor: pointer;
			text-align: center;
			width: 150px;
			padding: 15px 0;
			margin: 0;

			.profile-pic {
				margin-top: 4px;
				background: transparent;
				border-radius: 100%;
				padding: 3px;
				transition: background 400ms;
				margin-left: 0;

				img {
					width: 35px;
					height: 35px;
					background: #ccc;
					border-radius: 100%;
					border: 3px solid @presence-online-color;
				}
			}

			.user-controls {
				display: inline-block;
				//height: 20px;
				visibility: visible;
				li {
					float: left;
					a {
						padding-left: 0;
						:hover {
							background: transparent;
						}
						i {
							margin: 0;
						}
					}
				}
			}

			.user-details {
				padding: 0;
				margin: 0 15px 0 5px;
				visibility: visible;
				display: block;
				color: @white;

				.profile-name {
					font-size: 11px;
					margin-top: 7px;
				}

				.account-name {
					display: block;
					font-size: 11px;
					margin-top: -3px;
				}

				.online,
				.away {
					font-size: 11px;
					display: block;
					margin-top: 3px;
					margin-bottom: 2px;
				}
				.online {
					color: @presence-online-color;
				}
				.away {
					color: @presence-away-color;
				}

				.user-presence {
					text-transform: capitalize;
					font-size:12px;
					color:@presence-online-color;
					font-weight:bold;
					min-width:5em;
					padding:0 0 0 6px;
					margin-left: 2px;
					border-left: 1px solid #666;
					display:inline-block;
				}


			}

			&.away {
				.profile-pic {
					img {
						opacity: 0.8;
						background-color:@presence-away-color;
						border-color: @presence-away-color;
					}
				}
				.user-presence {
					color:@presence-away-color;
				}
			}

			.dropdown {
				z-index: 0;
				&.show {
					z-index: 999;
					.dropdown-menu {
						transition: all .2s ease-in-out;
						visibility: visible;
						opacity: 1;
						width: 160px;
					}
				}
			}
			.dropdown-menu {
				//background: red;
				position: fixed;
				width: 0;
				height: 100%;
				bottom: 0;
				left: 150px;
				display: block;
				visibility: hidden;
				opacity: 0;
				border-radius: 0;
				box-shadow: none;
				transition: all .2s ease-in-out;
				border: 0;

				.user-controls {
					position: absolute;
					width: 100%;
					text-align: center;
					padding-bottom: 15px;

					li { float: none; }

					.profile-pic {
						img {
							width: 65px;
							height: 65px;
							border-width: 3px;
						}
					}

					.user-details {
						color: @white;
						margin-bottom: 15px;
					}

					li .vngage-icon {
						background: transparent;
						margin: 0 4px 0 -3px;
						float: none;
					}
					li .vngage-icon-forward-1 {
						transform: scaleX(-1);
						filter: FlipH;
						-ms-filter: 'FlipH';
					}

					li.active {background:transparent}
					li.active > a {
						background:transparent;
						color:@white;
					}

					li > a {
						color: #aaa;
						padding-left:15px;
						transition:all 400ms;
						text-align: left;
						line-height: 22px;
						font-size: 14px;

						&.menu-item {
							color: @subMenuItemColor;
							line-height: 37px;
							&:hover {
								color: @subMenuItemHoverColor;
								background-color: @subMenuItemHoverBg;
							}
						}

						span { margin-left: 0; }

						&:hover {
							color:#eee;
							span {
								display: inline;
								background: transparent;
								line-height: 20px;
								margin-left: 0;
							}
						}
					}

					li.presence-away > a:before,
					li.presence-online > a:before{
						content: '';
						display: inline-block;
						width: 10px;
						height: 10px;
						margin: 0 10px 0 -12px;
						border-radius: 100%;
						border: 4px solid #999;
						background:#999;
						vertical-align: middle;
						transition:all 400ms;
						margin-bottom:2px;
					}
					li.presence-online a,
					li.presence-away a {
						padding-left: 30px;
					}

					li.presence-online.active > a:before,
					li.presence-online > a:hover:before {
						border-color:@presence-online-color;
						background-color:@presence-online-color;
					}

					li.presence-away.active > a:before,
					li.presence-away > a:hover:before{
						border-color:@presence-away-color;
						background-color:@presence-away-color;
					}

					.divider {
						height: 2px;
						margin: 10px 0 0;
						overflow: hidden;
						background-color: @dividerTopColor;
						border-bottom: 1px solid @dividerBottomColor;
					}

				}
			}
		}
	}
	.subnav {
		width: 160px;
		left: 150px;
		//padding: 9px 0;
		-webkit-transition: all .1s ease;
		-moz-transition: all .1s ease;
		-o-transition: all .1s ease;
		transition: all .1s ease;

		.nav > li > p {
			color: @sectionHeaderColor;
			font-size:13px;
			margin: 0;
		}
	}

	#content {
		margin-left: 150px;
	}

	#forced-pause.visible-message {
		position: absolute;
		bottom: 35px;
		left: 120px;
		&:before {
			content: "";
			top: 44px;
			left: -7px;
		}
	}

}

#navmenu {
	left: 0;
	top: 0;
	padding-left: 0;
	padding-right: 0;
	background: @navMenuBg;
	position: absolute;
	width: 40px;
	bottom: 0;
	display: flex;
	flex-direction: column;
	cursor: url('./img/arrow-expand.png'), auto;

	-webkit-transition: all .1s ease;
	-moz-transition: all .1s ease;
	-o-transition: all .1s ease;
	transition: all .1s ease;

	a.logo-container {
		line-height: 30px;
		padding: 0;
		background-color: @logoCollapsedBackground;
		.logo-collapsed {
			height: auto;
			width: 30px;
			position: absolute;
			left: 50%;
			margin-left: -15px;
			padding: @logoCollapsedPadding;
			top: 0;
			flex: 0;
			flex-shrink: 0;
			visibility: visible;
			opacity: 1;
			transition: opacity .2s ease, height .2s ease, width .2s ease;
		}
		.logo-expanded {
			visibility: hidden;
			opacity: 0;
			width: 0;
			height: 0;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		//cursor: pointer;
		flex: 1;
	}
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		cursor: pointer;

		hr {
			margin: 5px 0px;
			border-top: 1px solid @menuDividerTopColor;
			border-bottom: 1px solid @menuDividerBottomColor;
			cursor: default;
		}

		&.active {
			background: @navMenuItemActiveBg;
			transition: background .1s ease-in-out;

			a {
				color: @navMenuItemActiveColor;
			}
			i {
				background: @navMenuIconActiveBg;
				color: @navMenuIconActiveColor;
			}
			:hover {
				background: @navMenuItemActiveBg;
				i { background: @navMenuItemActiveBg; }
			}
		}

		:hover {
			text-decoration: none;
			background: @navMenuItemHoverBg;
			color: @navMenuItemHoverColor;
			transition: all .1s ease-in-out;

			span {
				position: relative;
				z-index: 9000;
				background: @collapsedMenuItemTooltipBg;
				line-height: 37px;
				display: block;
				padding-left: 10px;
				margin-left: 12px;
				border-radius: 4px;
				width: 160px;
				pointer-events: none;

				&:before {
					border:10px solid transparent;
					border-right-color: @collapsedMenuItemTooltipBg;
					width:0;
					height:0;
					display:block;
					position:absolute;
					content:"";
					left:-15px;
					top:8px;
				}

			}


		}

	}

	a {
		display: block;
		line-height: 37px;
		color: @white;
		padding-left: 35px;
		outline: none;
		white-space: nowrap;
		&:active,
		&:focus { text-decoration: none; }
	}

	i {
		float:left;
		margin-left: -30px;
		width:30px;
		height:36px;
		font-style: normal;
		text-align: center;
		&.unpublished {
			opacity: 0;
		}
	}

	span {

		margin-left: 5px;
		&.beta-tag:after {
			content: 'New';
			font-size: 9px;
			background: #fff;//@brand-primary;
			color: #444;
			border-radius: 2px;
			padding: 0 2px;
			position: relative;
			top: -2px;
			left: 5px;
		}
	}

	#user-container {
		cursor: pointer;
		width: 30px;
		margin: 0 5px 30px;
		text-align: center;
		transition: bottom .2s ease;

		.profile-pic {
			margin-top: 4px;
			background: transparent;
			border-radius: 100%;
			padding: 3px;
			transition: background 400ms;
			margin-left: 0;

			img {
				width: 20px;
				height: 20px;
				background: #ccc;
				border-radius: 100%;
				border: 2px solid @presence-online-color;
			}
		}

		&.away {
			.profile-pic {
				img {
					opacity: 0.8;
					background-color:@presence-away-color;
					border-color: @presence-away-color;
				}
			}
		}

		.user-details,
		.user-controls{
			visibility: hidden;
			display: none;
		}

		.dropdown {
			&.show {
				.dropdown-menu {
					transition: all .2s ease-in-out;
					visibility: visible;
					opacity: 1;
					width: 160px;

					.user-details,
					.user-controls{
						visibility: visible;
						display: block;
					}
				}
			}
		}
		.dropdown-menu {
			background: @subMenuBg;
			position: fixed;
			width: 0;
			top: 0;
			height: 100%;
			bottom: 0;
			left: 40px;
			display: block;
			visibility: hidden;
			opacity: 0;
			border-radius: 0;
			box-shadow: none;
			transition: all .2s ease-in-out;
			border: 0;
			margin-top: 0;

			.user-controls {
				position: absolute;
				bottom: 0;
				width: 100%;
				text-align: center;
				padding-bottom: 15px;

				.profile-pic {
					img {
						width: 65px;
						height: 65px;
						border-width: 3px;
					}
				}

				.user-details {
					color: @white;
					margin-bottom: 15px;
					visibility: visible;
					font-size: 11px;

					.profile-name {
						display: block;
						margin-top: 7px;
					}
				}

				li .vngage-icon {
					background: transparent;
					margin: 0 4px 0 -3px;
					float: none;
				}
				li .vngage-icon-forward-1 {
					transform: scaleX(-1);
					filter: FlipH;
					-ms-filter: 'FlipH';
				}

				li.active {background:transparent}
				li.active > a {
					background:transparent;
					color:@white;
				}

				li > a {
					color: #aaa;
					padding-left:15px;
					transition:all 400ms;
					text-align: left;
					line-height: 22px;
					font-size: 14px;

					&.menu-item {
						color: @white;
						line-height: 37px;
						&:hover {
							color: @subMenuItemHoverColor;
							background-color: @subMenuItemHoverBg;
						}
					}

					span { margin-left: 0; }

					&:hover {
						color:#eee;
						span {
							display: inline;
							background: transparent;
							line-height: 20px;
							margin-left: 0;
							padding-left: 0;
							&::before { display: none; }
						}
					}
				}

				li.presence-away > a:before,
				li.presence-online > a:before{
					content: '';
					display: inline-block;
					width: 10px;
					height: 10px;
					margin: 0 10px 0 -12px;
					border-radius: 100%;
					border: 4px solid #999;
					background:#999;
					vertical-align: middle;
					transition:all 400ms;
					margin-bottom:2px;
				}
				li.presence-online a,
				li.presence-away a {
					padding-left: 30px;
				}

				li.presence-online.active > a:before,
				li.presence-online > a:hover:before {
					border-color:@presence-online-color;
					background-color:@presence-online-color;
				}

				li.presence-away.active > a:before,
				li.presence-away > a:hover:before{
					border-color:@presence-away-color;
					background-color:@presence-away-color;
				}

				.divider {
					height: 2px;
					margin: 10px 0 0;
					overflow: hidden;
					background-color: #222;
					border-bottom: 1px solid rgba(255,255,255,.3);
				}

			}
		}

	}

	.legacy-tip.legacy-tip-top:before {
		border-top-color: #444;
	}
	.legacy-tip.legacy-tip-top:after {
		top: -25px;
		left: -100%;
		right: auto;
		background: #444;
	}
}
.subnav {
	background: @subMenuBg;
	padding: 8px 0;
	top: 0;
	left: 40px;
	bottom: 0;
	position: fixed;
	width: 160px;
	z-index: 500;
	overflow: auto;
	cursor: default;

	-webkit-transition: all .1s ease;
	-moz-transition: all .1s ease;
	-o-transition: all .1s ease;
	transition: all .1s ease;

	.nav > li > p {
		color: @sectionHeaderColor;
		padding: 8px 10px 0;
		line-height: 37px;
		font-size:13px;
		margin: 0;
	}
	.nav li:first-child p {
		padding-top: 0;
	}

	.nav > li.active a {
		color: @subMenuItemActiveColor;
		background: @subMenuItemActiveBg;
		outline: none;
	}

	.nav > li > a {
		padding: 8px 0 9px 20px;
		color: @subMenuItemColor;
		transition: all .1s ease-in-out;
		outline: none;
	}

	.nav > li {
		&.active a:hover {
			color: @subMenuItemActiveColor;
			background: @subMenuItemActiveBg;
			transition: all .1s ease-in-out;
		}
		a:hover {
			background: @subMenuItemHoverBg;
			transition: all .1s ease-in-out;
			color: @subMenuItemHoverColor;
		}
	}
}

#forced-pause {
	display: none;
}

#forced-pause.visible-message {
	display: block;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAVCAYAAACt4nWrAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTkzQjMwRkQ3QTRDMTFFNUI0QjI4NjI1NkNCNzkzQUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTkzQjMwRkU3QTRDMTFFNUI0QjI4NjI1NkNCNzkzQUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBOTNCMzBGQjdBNEMxMUU1QjRCMjg2MjU2Q0I3OTNBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBOTNCMzBGQzdBNEMxMUU1QjRCMjg2MjU2Q0I3OTNBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pkm6WdoAAAHESURBVHjarJXPSwJBFMd3VUQ65EWpIKhOXqMgwaPHkCKIQIi8ePGi0CFBlFChEtKbdgsC6dBl8dA/UOf6A7KjkFFYHSKyH/Yd+C5sw26tPx58eM57877zmJ0Z1eeVLcWGucAZWAMa2ACfIuFtnFgWORR7Fqdwjz5up8iO+DgogG+wSV9gfGjxDPCDY3BK72d8KPFZkAKvIMdYjuMU8wOL7wEPOABtxtoce5gfSDwIoqAFymAMLNOXGY++rMaC/YqroGLY8zdQA+fgiGN9zytYQO1HfB2EwBWoMzZJP0FfZz7E+bbE3aDE39s828IeJd9jXlgJ3bvtiCfBHG/ihSF+J3mFeY3zk/+J+0AWfIC0lJM71y3N+Vl07/tLfFc8F6AKmlLuQfIK35Ym53tZbyoeAAnwBIom23UveaMVWZdA9wEz8UPg5LvRMRG4Bg2eEEXqvsM6J3V+iYdBBNzyPJvZAlgCixb5Gusj6D6sizt444TtgK5FsTgRU/SKSfdd1gsrYwGHEI6BeXDJY2VlVX6XqtUELKBRR+jFhHheuhBWJubcGC6VlekXL6/ib+6dH6KljM6mwZeLD9A+mBmhuLhUmR8BBgBrf3X0Zm+AZwAAAABJRU5ErkJggg==") !important;
	background-position: 20px 20px;
	background-repeat: no-repeat;
	width: 305px;
	padding: 15px 10px 15px 65px;
	color: #333;
	background-color: #F3F3F3;
	position: absolute;
	bottom: 15px;
	left: 38px;
	z-index: 9999;
	border-radius: 4px;
	box-shadow: 0 0 6px #999;
	text-align: left;

	strong,
	p {
		line-height: 17px;
		margin: 0;
		display: inline-block;
	}

	strong {
		font-family: 'vngage-semibold';
		margin-bottom: 8px;
		font-size: 14px;
	}

	p { font-size: 13px; }

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 20px;
		height: 20px;
		background: #F3F3F3;
		transform: rotate(50deg);
		top: 110px;
		left: -7px;
		box-shadow: -3px 3px 4px -3px #999;
		border: 0;
	}
	&:after {
		font-family: 'icon';
		content: '\e818';
		position: absolute;
		right: 5px;
		top: 2px;
		font-size: 10px;
	}
	.btn-xs {
		margin: 10px 0 5px;
		font-family: 'vngage-semibold';
		//font-size: 11px;
	}
}
