.import-modal-toggle {
	display: flex;

	> span {
		font-size: 25px;
		cursor: pointer;
		color: #ccc;
		transition: color .2s;

		&:hover,
		&.active {
			color: #333;
		}
	}
}

.import-buttons-top {
	display: flex;
}


.import-modal-description {
	position: absolute;
	top: 15px;
	left: 185px;
	z-index: 1;

	.modal-table {
		color: #fff;
		background: rgba(0, 0, 0, 0.8);
		border-radius: 5px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.7);
		padding: 10px;

		.info {
			border-top: solid 1px rgba(255, 255, 255, 0.2);
			padding: 10px;
		}

		table {
			th, td {
				padding: 5px 10px;
			}

			th:nth-child(1) {
				min-width: 150px;
			}

			th:nth-child(2) {
				min-width: 240px;
			}

			td {
				border-top: solid 1px rgba(255, 255, 255, 0.2);
				font-size: 12px;
				font-family: 'vngage', sans-serif;
			}

			tr:first-child td {
				border-top-width: 2px;
			}

			td + td,
			th + th {
				border-left: solid 1px rgba(255, 255, 255, 0.2);
			}
		}
	}
}
