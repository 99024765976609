.help {
	> span {
		cursor: pointer;
		color: #ccc;
		transition: color .2s;
		font-size: 14px;

		&:hover,
		&.active {
			color: #333;
		}
	}
	.help-section {
		color: #fff;
		background: rgba(0, 0, 0, 0.8);
		border-radius: 5px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.7);
		padding: 10px;
		position: absolute;
		top: -15px;
		right: -15px;
		transform: translateX(100%);

		&.ng-enter, &.ng-leave {
			transition: opacity .3s, transform .2s;
		}

		&.ng-enter,
		&.ng-leave.ng-leave-active {
			opacity: 0;
			transform: scale(0.9) translateX(100%);
			transform-origin: right center;
		}

		&.ng-enter.ng-enter-active,
		&.ng-leave {
			opacity: 1;
			transform: scale(1) translateX(100%);
			transform-origin: right center;
		}
		pre {
			background: transparent;
			color: #fff;
			border: none;
		}
	}
	.tooltip-narrow + .tooltip > .tooltip-inner {
		min-width: 100px;
		max-width: 200px;
	}
	.tooltip-medium + .tooltip > .tooltip-inner {
		min-width: 150px;
		max-width: 300px;
	}
	.tooltip-wide + .tooltip > .tooltip-inner {
		min-width: 250px;
		max-width: 400px;
	}
	.tooltip-xwide + .tooltip > .tooltip-inner {
		min-width: 350px;
		max-width: 500px;
	}
	.tooltip-xxwide + .tooltip > .tooltip-inner {
		min-width: 450px;
		max-width: 600px;
	}
}
