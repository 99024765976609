div[data-ui="alert"]{
  position:absolute;
  top:150px;
  width:260px;
  background-color:#eee;
  padding:20px;
  /*border:1px solid #888;*/
	-moz-box-shadow: 0 2px 3px #ccc;
	-webkit-box-shadow: 0 2px 3px #ccc;
	box-shadow: 0 2px 3px #ccc;
  margin:auto;
  margin-left:30%;
  color:#000;
	border-radius: 8px;
  text-align:center;
}
div[data-ui="alert"] h1,
div[data-ui="alert"] p,
{
  margin:0.83em 0;
}
div[data-ui="alert"] .content{
  margin:1.5em 0 0 0;
}
div[data-ui="alert"] .icon{
  width:54px;
  height:46px;
  display:inline-block;
  background-repeat:no-repeat;
  background-position: center center;
}
div[data-ui="alert"] .icon.warn{
  background-image:url(img/desktop/ico_warning.png);
}
div[data-ui="alert"] .icon.error{
  background-image:url(img/desktop/ico_error.png);
}
div[data-ui="alert"] .icon.check{
  background-image:url(img/desktop/ico_check.png);
}
div[data-ui="alert"] .icon.loading{
  background-image:url(img/desktop/ico_loader.gif);
}
