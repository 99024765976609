.psCaseWrapper {
	margin-top: 2px;
}

.spinner {
	line-height: 20px;
	padding-left: 26px;
	display: inline-block;
	background: no-repeat url("img/desktop/ico_loader.gif") 5px 50%;
	-moz-background-size: 16px 16px;
	-o-background-size: 16px 16px;
	-webkit-background-size: 16px 16px;
	background-size: 16px 16px;
}

.psCaseAutoTextContainer {
	position: absolute;
	overflow: hidden;
	height: 300px;
	background: red;
	display: none;
}

.psCaseAutoHeader {
	height: 20px;
	padding: 0 3px;
	line-height: 20px;
	font-weight: bold;
	border-bottom: 1px solid #ccc;
}

.psCaseContent {
	padding: 1px 8px;
	/*border-bottom:1px solid #ccc;*/
}

.psCaseButtons {
	/*position:absolute;
    bottom:0px;*/
}

.psCaseLabel {
	line-height: 20px;
	font-weight: bold;
}

.psCaseAutoTexts {
	padding: 0 3px;
	height: 20px;
	line-height: 20px;
	border-bottom: 1px solid #ccc;
	background-color: #faf9f5;
	cursor: pointer;
}

	.psCaseAutoTexts:hover {
		background-color: #fff;
	}

.psCaseContent {
	padding-top: 3px;
}

.psCaseTypeChooser {
	width: 98%;
	margin-top: 2px;
}

.psCaseFormHeader {
	font-style: italic;
	font-size: 10px;
	text-align: left;
	color: #999;
	/*border-bottom:1px solid #ccc;*/
	padding: 1px 4px;
}

.psCaseForm {
	margin-top: 5px;
	font-size: 10px;
}

	.psCaseForm .panelPadding {
		padding: 0;
		margin: 0;
		padding: 3px 5px 1px;
		background-color: #E6E6E6;
	}

.formRequired {
	background-color: #F5DFDF !important;
}

.psCaseForm fieldset {
	margin: 3px 0;
}

legend, label {
	margin: 1px 0;
}

.previousCaseListItem {
	margin: 1px;
	padding: 5px;
	color: #999;
	border: 1px solid #ccc;
	background-color: #fff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}


.psPlugin_caseListItem {
	cursor: pointer;
	/*background: -moz-linear-gradient(top, #fff,#ddd);
	background: -webkit-linear-gradient(top, #fff,#ddd);
	background: linear-gradient(top, #fff,#ddd);*/
    background: #ddd;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: 1px solid #ccc;
	margin: 4px;
	padding: 5px 5px 2px 40px;
	-webkit-transition: opacity 400ms 100ms;
	-moz-transition: opacity 400ms 100ms;
	-o-transition: opacity 400ms 100ms;
	transition: opacity 400ms 100ms;
}

	.psPlugin_caseListItem:before {
		content: '';
		display: inline-block;
		width: 30px;
		height: 20px;
		background: url("img/desktop/icons/ico_casecard.png") no-repeat left center;
		float: left;
		margin-left: -35px;
	}

	.psPlugin_caseListItem:active {
		-moz-box-shadow: inset 1px 1px 4px #999;
		-webkit-box-shadow: inset 1px 1px 4px #999;
		box-shadow: inset 1px 1px 4px #999;
		background: #eee;
	}

	.psPlugin_caseListItem:hover {
		border-color: #999;
	}

	.psPlugin_caseListItem.psPlugin_caseListItem-empty {
		-moz-opacity: 0.7;
		opacity: 0.7;
		background: #fff;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

.psPlugin_caseListItem-empty .caseListTitle:before {
	content: "Dismissed";
	float: right;
	color: #900;
	font-weight: bold;
}
