.site-selector {
	display: inline-block;
	float: right;
	position: relative;
	top: -60px;
	padding: 0 0 0 10px;
	z-index: 999;

	label {
		display: inline-block;
		font-size: 12px;
		margin-right: 5px;
	}

	.site-selector-input {
		display: inline-block;

		.dropdown {
			&.show {
				.btn-default {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
				.dropdown-menu {
					-webkit-animation: rollDown .25s both;
					-moz-animation: rollDown .25s both;
					-o-animation: rollDown .25s both;
					animation: rollDown .25s both;
				}
			}

			.btn-default {
				background: #f1f1f1;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				border: 0;
				width: 100%;
				outline: none;
				color: #333;
				text-align: left;

				span {
					font-family: 'vngage-semibold';
				}
			}

			.caret {
				position: absolute;
				right: 13px;
				top: 12px;
			}

			.dropdown-menu {
				width: 100%;
				padding: 0;
				min-width: 0;
				background: #f1f1f1;
				border-radius: 0;
				//margin: 0;
				top: 29px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				border: 0;
				box-shadow: none;
				z-index: -1;
				padding-bottom: 10px;

				li {
					line-height: 16px;
					padding-left: 15px;
					text-align: left;
					padding-bottom: 10px;
					margin-bottom: -10px;

					&:hover {
						cursor: pointer;
						background: #cecece;
						&:last-child {
							border-bottom-left-radius: 4px;
							border-bottom-right-radius: 4px;
						}
					}
					.divider {
						content: '';
						height: 1px;
						margin: 0;
						overflow: hidden;
						background-color: #e5e5e5;
						display: block;
						margin: 10px 0 10px -15px;
					}
					&:first-child .divider {
						margin-top: 0;
					}
				}
			}
		}
	}
}
