.header-menu {
	position: absolute;
	right: 0;
	top: 4px;
	font-size: 12px;
}

.cobro-header-menu {
}

.dots-dropdown {
	.dots-dropdown-button, &.show .dots-dropdown-button {
		background-color: transparent;
		border: none;
		box-shadow: none;
		font-size: 14px;

		&.dots-icon:before {
			content: '\e860';
			font-family: "icon";
			font-style: normal;
			font-weight: normal;
			display: inline-block;
			text-decoration: inherit;
			width: 1em;
			margin-right: .2em;
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: 1em;
			margin-left: .2em;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transform: rotate(90deg);
		}

		&.menu-icon:before {
			content: '\e885';
			font-family: "icon";
			font-size: 20px;
		}

		&:not(:disabled):not(.disabled):active {
			background-color: transparent;
			color: white;
			border: none;
			box-shadow: none;
		}

		&:after {
			content: none;
		}

		&:focus {
			box-shadow: none;
		}
	}

	.dropdown-item {
		padding: 3px 7px;
	}

	.header-item {
		width: 220px;
	}

}

.transfer-dropdown {
	.transfer-dropdown-button {
		display: flex;
		justify-content: space-between;
		background: #fff;
		border: 0;
		text-align: left;
		border-radius: 0;
		color: #444;
		box-shadow: none;
		font-size: 14px;
		width: 100%;
		padding: 3px 7px;

		&:after {
			margin-top: 4%;
		}

		&:not(:disabled):not(.disabled):active {
			background: #fff;
			box-shadow: none;
		}

		&:focus {
			box-shadow: none;
		}

	}

	&.show .transfer-dropdown-button, &.show .transfer-dropdown-button:focus {
		background: #fff;
		-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

	.transfer-menu {
		overflow-x: hidden;
		overflow-y: auto;
		height: auto;
		max-height: 400px;

		.transfer-group {
			overflow: hidden;
			display: -webkit-box;
			width: 220px;
			position: relative;
			white-space: inherit;
			padding: 0.25rem 0.5rem;

			.scroll-wrapper {
				width: 180px;
				line-height: 1.3;
			}

			&.disabled:before {
				background-color: crimson;
				opacity: .6;
			}
		}

		.transfer-group:before {
			margin-right: 5px;
			margin-bottom: 2px;
			content: '';
			display: inline-block;
			width: 7px;
			height: 7px;
			border-radius: 100%;
			background-color: #71ce61;
			right: 15px;
			top: 10px;
		}

	}
}

.finish-dropdown {
	padding: 8px 0px 0 0px;

	.closure-dropdown {
		letter-spacing: .03em;
		background-color: transparent;
		color: #64bd6b;
		border-color: #5BB25C;
		padding: 3px 4px 3px 6px;
		width: 100%;
	}

	.dropdown-menu::-webkit-scrollbar {
		width: 1em;
	}

	.dropdown-menu::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.dropdown-menu::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}

	.dropdown-menu {
		overflow-x: hidden;
		overflow-y: auto;
		height: auto;
		max-height: 360px;

		padding-top: 0;
		width: 253px;
		margin-bottom: 4px;

		.title {
			font-family: 'vngage-bold', sans-serif;
			margin: 10px 0 5px;
			padding: 0 15px;
			display: inline-block;
		}

		li a {
			padding: 3px 15px;

			&:hover {
				background-color: #64BD6B;
				color: #fff;
			}
		}

		li.to-more-options a {
			padding: 3px 15px;
			color: #444;

			&:hover {
				cursor: pointer;
				background-color: #EBEBEB;
			}
		}

		.dropdown-head {
			background: #e9eaee;
			padding: 10px 15px;

			.title {
				margin: 0 0 5px;
				padding: 0;
			}
		}

		.form-group {
			margin-bottom: 0;

			.checkbox {
				margin: 0;
				font-family: 'vngage-semibold', sans-Serif;
				font-size: 12px;
			}
		}
	}
}

.case-menu-modal {
	margin: 10px 0 15px;
	width: 50%;

	.case-dropdown-button {
		display: flex;
		justify-content: space-between;

		width: 100%;
		background: #fff;
		border: 1px solid #ccc;
		color: #666;
		padding: 10px;
		font-size: 14px;

		&:after {
			margin-top: 4%;
		}

		&:not(:disabled):not(.disabled):active {
			background: #fff;
			box-shadow: none;
		}

		&:focus {
			box-shadow: none;
		}

	}

	.case-menu-container {
		overflow-x: hidden;
		overflow-y: auto;
		height: auto;
		max-height: 400px;

		width: 100%;
	}

	&.show .case-dropdown-button, &.show .case-dropdown-button:focus {
		background: #fff;
		-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

}

.case-menu {
	padding: 8px 0px 0 0px;
	margin-right: 10px;
	flex: 1;

	.case-dropdown-button {
		background: transparent;
		color: black;
		border-color: #BFBFBF;
		width: 100%;
		text-align: left;
		padding: 3px 5px;

		&:after {
			margin-top: 2%;
			content: '';
			display: inline-block;
			border: 4px solid;
			height: 0;
			width: 0;
			margin-right: 8px;
			border-color: transparent;
			border-bottom-color: black;
			position: absolute;
			right: 0;
		}


		&:not(:disabled):not(.disabled):active {
			background: #fff;
			box-shadow: none;
		}

		&:focus {
			box-shadow: none;
		}

	}

	&.show .case-dropdown-button, &.show .case-dropdown-button:focus {
		background: #fff;
		-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

}

.queue-menu {
	.meta-dropdown-button, &.show .meta-dropdown-button {
		margin-top: -6px;
		background-color: transparent;
		border: 0;
		font-size: 18px;
		color: #A4A3A3;

		&:before {
			content: '\e86f';
			font-family: "icon";
			font-style: normal;
			font-weight: normal;
			display: inline-block;
			text-decoration: inherit;
			width: 1em;
			margin-right: .2em;
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: 1em;
			margin-left: .2em;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		&:after {
			content: none;
		}

		&:not(:disabled):not(.disabled):active {
			background-color: transparent;
			color: #A4A3A3;
			border: none;
			box-shadow: none;
		}

		&:focus {
			box-shadow: none;
		}
	}

	.dropdown-menu {
		margin-bottom: 5px;
		width: 200px;

		a {
			display: block;
			padding: 3px 10px;
			color: #444;

			&:hover {
				cursor: pointer;
				background: #EBEBEB;
			}

			i {
				font-size: 10px;
				vertical-align: middle;
			}
		}
	}
}

