@keyframes copied-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;

	}
}

.start-code-row {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding-bottom: 10px;

	.start-code-text {
		font-size: 14px;
		margin-top: 3px;

		.copied {
			font-weight: bolder;
			font-size: 14px;
			color: #71ce61;
			margin-left: 5px;

			animation-name: copied-fade-in;
			animation-duration: .2s;
			animation-timing-function: ease-in-out;
			animation-delay: 0s;
			animation-iteration-count: 2;
			animation-direction: alternate-reverse;
		}
	}

	button {
		white-space: nowrap;
		height: 100%;
	}
}


.widget-panel {
	border: 0;
	text-align: left;
	color: black;
	font-size: 14px;
	box-shadow: 7px 20px 30px 0px #929292;
	display: inline-block;

	.toolbar {
		border-left: 1px solid #cecece;
		border-right: 1px solid #cecece;

	}

	.drag-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0;
	}
}

.widget-panel-handle {
	.widget-panel-header {
		color: white;
		//width: 100%;
		width: 300px;
		border-radius: 10px 10px 0 0;
		background: #333333;
		display: inline-block;
		padding-left: 10px;
		padding-top: 2px;
	}


	&:hover {
		cursor: move;
	}
}

.widget-panel-body {
	background: white;
}
