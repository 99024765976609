.psPlugin_videChat {
	background: no-repeat scroll center center url('img/desktop/bg_visitors_camera_disabled.png');
}

.video-toolbar {
	//position: absolute;
	z-index: 9;
	//bottom:0;
	height:25px;
	background-color: #444;
	//width: 100%;

	label {
		display:inline;
		-webkit-user-select:none;
		-moz-user-select:none;
		user-select:none;
		float: right;

		input[type="checkbox"]:not(:checked),
		input[type="checkbox"]:checked { /* :checked here acting as a filter for older browsers */
			position: absolute;
			opacity: 0;
			cursor: pointer;
			pointer-events: none;
		}

		input[type="checkbox"] + i { color: #ccc; }
		input[type="checkbox"]:checked + i { color: #5bc0de; }

		i {
			font-size: 16px;
			display: inline-block;
			margin: 3px 5px 0 0;
			&:hover {
				cursor: pointer;
			}
		}

	}

}

#videowrap {
	width: 100%;
	position: relative;

	-webkit-transition: all 400ms ease-out;
	-moz-transition: all 400ms ease-out;
	-ms-transition: all 400ms ease-out;
	-o-transition: all 400ms ease-out;
	transition: all 400ms ease-out;
}

#videowrap.videolarge {
	position: absolute;
	width: 480px;
	height: 360px;
}

#videowrap.videolarge.videolargeleft {
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	transform: translateX(-100%);
}

#videowrap.videolarge.videolargeright {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
}

/*
 *  These styles define the appearance of the default error dialog box.
 */
#easyrtcErrorDialog {
	background-color: #ffe0e0;

	position:fixed;
	right: 10px;
	top:20px;
	z-index: 30;
	opacity: 0.95;
	padding: 0.5em;
	border-radius:10px;
	border-color: red;
	border-style: solid;
	border-width: 1px;
	-webkit-box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.9);
	box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.9);
}

.easyrtcErrorDialog_title {
	position:static;
	text-align:center;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 0.5em;
	clear:both;
}

#easyrtcErrorDialog_body{
	position:static;
	height:150px;
	overflow-y:auto;
}

.easyrtcErrorDialog_element {
	position:static;
	font-style: italic;
	font-size: 12px;
	width:300px;
	margin-bottom: 0.5em;
	clear: both;
	float:left;
}

.easyrtcErrorDialog_okayButton {
	position:static;
	clear:both;
	float:right;
}


.easyRTCMirror {
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
}

.easyrtc_closeButton {
	z-index: 2;
	position: absolute;
	width: 40px;
	height:40px;
	right: 0px;
	top: 0px;
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><g stroke="#b0b0b0" stroke-width="7" ><g>		<path  d="M76.03,79.854c-0.979,0-1.958-0.373-2.704-1.12L21.184,26.592c-1.494-1.494-1.494-3.915,0-5.409c1.494-1.493,3.915-1.493,5.409,0l52.143,52.142c1.494,1.494,1.494,3.915,0,5.409C77.988,79.481,77.01,79.854,76.03,79.854z"/></g><g><path d="M23.888,79.854c-0.979,0-1.958-0.373-2.704-1.12c-1.494-1.494-1.494-3.915,0-5.409l52.142-52.142c1.493-1.493,3.915-1.493,5.409,0c1.494,1.494,1.494,3.915,0,5.409L26.593,78.734C25.846,79.481,24.867,79.854,23.888,79.854z"/></g></g><g fill="#000000"><g><path  d="M76.03,79.854c-0.979,0-1.958-0.373-2.704-1.12L21.184,26.592c-1.494-1.494-1.494-3.915,0-5.409c1.494-1.493,3.915-1.493,5.409,0l52.143,52.142c1.494,1.494,1.494,3.915,0,5.409C77.988,79.481,77.01,79.854,76.03,79.854z"/></g><g><path d="M23.888,79.854c-0.979,0-1.958-0.373-2.704-1.12c-1.494-1.494-1.494-3.915,0-5.409l52.142-52.142c1.493-1.493,3.915-1.493,5.409,0c1.494,1.494,1.494,3.915,0,5.409L26.593,78.734C25.846,79.481,24.867,79.854,23.888,79.854z"/></g></g></svg>');
	opacity: 0.3;
}

.easyrtc_closeButton:hover {
	opacity: 1;
}



.transit {
	transition: all 400ms ease-out;
	-moz-transition: all 400ms ease-out;
	-webkit-transition: all 400ms ease-out;
	-o-transition: all 400ms ease-out;
	/*transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, transform 1s;*/
	/*-moz-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, -moz-transform 1s;*/
	/*-webkit-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, -webkit-transform 1s;*/
	/*-o-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s,-o-transform 1s;*/
}

.hidden {
	background-color: transparent;
	border-color: 2px solid blue;
}

.boxCommon {
	position:absolute;
}

.closeButton {
	display: none; /* hide the easyApp's close button because we have our own */
}

.thumbCommon {
	z-index:2;
	/*box-shadow: 5px 5px 5px #000000;*/
}



.video-canvas {
	width:100%;
	padding-bottom:74.9%;
	background:#999;
	max-height:50%;
	//necessary for setting a max-height for videos
	position:relative;
}

.v {
	width:50%;
	height:auto;
	position:absolute;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
	max-height:100%;
}

.v-v {left:0; top:23%; cursor:nwse-resize;}
.v-g {right:0; top:23%; cursor:nwse-resize;}

.v-me {
	//bottom:5px;
	bottom:-20px; //due to pos:rel on .video-canvas
	left:5px;
	width:60px;
	z-index:5;
	border-radius:5px;
	transition-delay: 200ms;
	transition-duration:300ms;
}

.v-me:hover {width:50%;}
.v-me {
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
}

.v-zoom-v .v-v {width:100%; top:0; z-index:1;cursor:pointer;}
.v-zoom-v .v-g {width:60px; right:0; top:0; z-index:2; cursor:sw-resize;}

.v-zoom-g .v-v {width:60px; left:0; top:0; z-index:2; cursor:se-resize}
.v-zoom-g .v-g {width:100%; top:0; z-index:1; cursor:pointer;}
