.plugin-tool {
	border: 0;
	text-align: left;
	color: black;
	font-size: 14px;
	//box-shadow: 0 15px 40px -10px #823458;
	display: inline-block;
}

.drag-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
}

.plugin-floating {
	position: absolute;
	height: 1px;
	width: 2px;
	z-index: 999;

	.plugin-tool-handle {
		.plugin-header {
			color: white;
			width: 100%;
			border-radius: 10px 10px 0 0;
			background: #333333;
			display: inline-block;
			padding-left: 10px;
			padding-top: 2px;
		}

		iframe {
			border: 1px solid #333333;
		}

		&:hover {
			cursor: move;
		}
	}
}

.plugin-hidden {
	position: absolute;
	height: 1px;
	width: 1px;
	top: 1px;
	left: 1px;
	z-index: -9999;

	iframe {
		width: 1px;
		height: 1px;
	}
}

.plugin-pinned {
	z-index: 999;

	&.plugin-selected {
		z-index: 999;

		iframe {
			border: none;
		}
	}

	&.plugin-not-selected {
		position: absolute;
		height: 1px;
		width: 1px;
		top: 1px;
		left: 1px;
		z-index: -9999;

		iframe {
			width: 1px;
			height: 1px;
		}
	}

}


@keyframes gradient-move {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}

.plugin-panel {
	display: flex;
	flex-direction: row;
	background: white;
	border-left: 1px solid transparent;

	&.active {
		border-left: 1px solid black;
	}

	.plugin-content {
		overflow: scroll;
		background: white;
	}

	.plugin-toggle-buttons {
		width: 40px;
		display: flex;
		flex-direction: column;
		border-left: 1px solid black;

		.plugin-toggle {
			border-bottom: 1px solid black;
			text-align: center;
			color: black;
			font-size: 20px;

			&.show-notification {
				// background animation for notification on pluginPanelButton
				background: linear-gradient(135deg, white, white, #4ADAB1, white, white, white);
				background-size: 1200% 1200%;
				animation: gradient-move 3s linear infinite;
			}

			.button-notification {
				width: 8px;
				height: 8px;
				background: @component-active-bg;
				border-radius: 50%;
				float: right;
			}

			&.active {
				background: @component-active-bg;
				margin-left: -1px;
				color: white;

				&:before {
					// white arrow
					position: absolute;
					display: block;
					content: '';
					width: 8px;
					margin-top: 7px;
					border-top: 8px solid transparent;
					border-left: 8px solid white;
					border-bottom: 8px solid transparent;
				}

			}
		}
	}
}

.plugin-toast-container {
	position: fixed;
	top: 10px;
	left: 150px;
	display: flex;
	flex-direction: row-reverse;

	.plugin-toast {
		background: white;
		margin-bottom: 0;
		user-select: none;
		max-width: 200px;

		.toast-header {
			display: flex;
			justify-content: space-between;

			.text-wrapper {
				width: 100px;
			}
		}

		.toast-body {
			display: flex;
			justify-content: space-between;
		}
	}
}