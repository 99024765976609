// Import a less-port of bootstrap (currently corresponding to Bootstrap v4.3.1)
@import "../node_modules/bootstrap-less-port/less/bootstrap.less";

// Map Bootstrap 4 variables to the less-variables defined in "/less/theme.less" and "/less/variables.less"
@primary:       @brand-action;
@secondary:     @gray-light;
@success:       @brand-success;
@info:          @brand-info;
@warning:       @brand-warning;
@danger:        @brand-danger;

// Tweak some BS4 paddings, margins and borders to match what was set in BS3
@dropdown-padding-y: 5px;
@dropdown-item-padding-y: 3px;
@dropdown-item-padding-x: 20px;
@modal-header-padding: 12px;
@nav-tabs-border-width: 2px;
@label-margin-bottom: 5px;
@input-focus-border-color: rgba(77, 144, 254, .5); // Used to be #66afe9 with BS3, but now I instead mimic the default Chrome color: "-webkit-focus-ring-color" that's used on checkboxes, tabs, etc.
@input-focus-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
@form-check-input-margin-y: 4px;
@card-heading-padding: 10px 15px;
@card-default-heading-bg: @panel-default-heading-bg;

// Style some elements like in BS3 ("normalize.less" and "code.less")
body {
	font-family: @font-family-sans-serif;
	font-size: @font-size-base;
	line-height: @line-height-base;
}
h1, .h1 { font-size: @font-size-h1; }
h2, .h2 { font-size: @font-size-h2; }
h3, .h3 { font-size: @font-size-h3; }
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }

// Remove the X-icon in input fields of type="Search" (like normalize.less in BS3 did)
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

// Pre-tags are not styled in BS4. Style them as in BS3 (like code.less did)
pre {
	padding: 9.5px;
	margin: 0 0 10px;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
}
