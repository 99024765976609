.content-upload-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}


.content-upload-image-container {
	height: 400px;
}

.content-upload-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.content-upload-file {
	height: 100%;
	width: 100%;
	font-size: 200px;
	object-fit: contain;
	margin-left: 25%;
	display: block;
	color: darkgray;
}

.content-file-symbol::before {
	font-family: 'icon';
	content: '\E891';
	cursor: pointer;
	font-size: 12px;
}

.content-download-button {
	color: @btn-primary-bg;
	background-color: @white;
	border: 2px solid @btn-primary-border;
	display: block;
	width: 20%;
	text-decoration: none;
	padding: 6px 12px;
	text-align: center;
	vertical-align: middle;
	border-radius: 5px;
	font-weight: bold;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		text-decoration: none;
		color: @btn-primary-color;
		background-color: @btn-primary-bg;
	}
}

.content-spinner {
	position: absolute;
	width: 400px;
	height: 400px;
	left: 50%;
	top: 50%;
	margin-left: -200px;
	margin-top: -200px;
	font-size: 30px;
	text-align: center;
	z-index: 2;
	color: @white;

	&:after {
		content: '';
		border-radius: 100%;
		width: 50px;
		height: 50px;
		left: 50%;
		top: 50%;
		margin-left: -25px;
		margin-top: -25px;
		position: absolute;
		z-index: 2;
		/*border-left:2px solid lighten(@brand-success,20%);
		border-right:2px solid lighten(@brand-success,20%);
		border-bottom:2px solid lighten(@brand-success,20%);
		border-top:2px solid @brand-success;*/
		border: 2px solid @brand-success;
		border-bottom-color: transparent;
		.animation(rotation .8s infinite linear);
	}
}

/* Dropzone */
.dropzone {
	padding: 30px;
	border: 2px dashed #666;
	outline: none;
	cursor: pointer;
	height: 250px;
	width: 50%;
	margin: auto;
	line-height: 170px;
	text-align: center;
}

.dropzone-active {
	background-color: #f1e2ff;
	border: 2px solid #00b7c9;
}

.dropzone-content {
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	font-size: 18px;
}

.dropzone-container {
	margin: auto;
	width: 35%;
}


