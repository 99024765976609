.crash-report {
	.profile-pic {
		margin-top: 4px;
		background: transparent;
		border-radius: 100%;
		padding: 3px;
		transition: background 400ms;
		margin-left: 0;

		img {
			width: 40px;
			height: 40px;
			background: #ccc;
			border-radius: 100%;
			border: 2px solid #666;
		}
	}
}

.crash-report-modal {
	z-index: 99999 !important;
}