.statview {
	width: 100%;

	h1 {
		margin-bottom: 30px;
	}

	.spinner {
		left: 40%;
		margin-left: -30px;
		margin-top: -30px;
	}
	.week-picker button {
		border: none;
		background-color: #ddd;
	}
	.stat {
		float:left;
		clear:both;
	}

	> div {
		display: inline-block;
		vertical-align: top;
	}

	#chart-graph {
		width: 590px;

		.chart {
			min-height:405px;
		}
	}

	.explanation {
		margin-left:10px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #777;

		i {
			font-size: 16px;
			position: relative;
			top: 2px;
			color: #999;
		}
	}
	.separator {
		width: 100%;
		border-bottom: 1px solid #e3e3e3;
	}
	.explanation-container {
		background: #f8f8f8;

		display:none;
		border-bottom: 1px solid #e3e3e3;
		padding: 20px 30px;
		margin: -20px 0;
		max-height: 0;
		opacity: 0;
		-webkit-transition: all  .1s linear;
		-moz-transition: all .1s linear;
		-ms-transition: all .1s linear;
		-o-transition: all .1s linear;
		transition: all .1s linear;

		&.visible {
			display:block;
			max-height: 100%;
			margin: -19px 0 20px;
			opacity: 1;
			-webkit-transition: all .1s linear;
			-moz-transition: all .1s linear;
			-ms-transition: all .1s linear;
			-o-transition: all .1s linear;
			transition: all .1s linear;
		}
	}

}

#dc-timeLine-chart {
	svg:not(:root) {
		overflow: visible !important;
	}
}

#dc-totals-metrics {
	&.statistics-components {
		margin: 30px 0;
		max-width: 1110px;
		justify-content: space-around;

		h4 {
			display: flex;
			align-self: flex-start;
			margin: 2px 0;
			font-size: 16px;
		}

		div.metrics-component {
			align-self: center;
				.metrics-content {
				font-size: 16px;
			}
		}

		>div {
			padding: 0;
			flex: initial;
		}
	}
}

#dc-bar-chart-and-data-wrapper {
	min-width: 1190px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
}

#dc-bar-chart-wrapper {
	float: left;
	width: 250px;
}

#dc-table-wrapper {
	float: left;
	width: 860px;
}

.statisticsFilters {
	border-top: 1px solid #d4d4d4;
	border-right: 1px solid #d4d4d4;
	h4 { margin: 20px 0; }
}
.statisticsTable {
	border-top: 1px solid #d4d4d4;
	padding-right: 0;
}

.data-table {
	font-size: 12px;
	padding: 10px 0 0 10px;
	table { width: 100%; }
	th {
		vertical-align: text-top;
		line-height: 14px;
	}
}

.row-chart, #dc-bar-chart-wrapper {
	.sort-chart {
		float: right;
		font-size: 9px;
		color: #888;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.reset-filters {
		float: right;
		font-size: 9px;
		color: #FFF;
		cursor: pointer;
		padding: 3px 5px;
		margin-top: 17px;
		background: #00b7c9;
		border: none;
		border-radius: 5px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.reset-filters[disabled] {
		background: #DDD;
		color: #888;
	}
	label {
		font-size: 12px;
		font-family: 'vngage-semibold', sans-serif;
	}
	svg:not(:root) {
		overflow: visible !important;
	}
}

div.dc-chart { float: none; }
.dc-chart g.row text {
	fill: black;

}
.dc-chart .axis line {
	fill: none;
	stroke: #DDD;
	shape-rendering: crispEdges;
}
.progress-bar {
	overflow: visible;
	float: left;
	width: 0;
	height: 100%;
	font-size: inherit;
	line-height: inherit;
	color: #000;
	text-align: left;
	background-color: #CDE0E6;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: width .6s ease;
	-o-transition: width .6s ease;
	transition: width .6s ease;
}
.dc-table-group{
	font-weight: bold;
	border-top: 1px solid #d4d4d4;
	border-bottom: 1px solid #d4d4d4;
}
.tooltip-inner{
	color:#000;
	background-color:#EEE;
	border: 1px solid #000;
}
.statisticsFilters{
	background-color: #fcf3e4;
	//border-right:20px solid #fbffe6;
	//border-left:10px solid #fbffe6;
}
.statisticsTableAndTotals{
	border-right:1px solid #EEE;
}
.weekPickerInStats{
	float:right;
}

.statisticsTotals {
	.totalWithPercentage {
		color:#888
	}
}

.statisticsTable {
	.header-sticky {
		visibility: hidden;
		position: fixed;
		top: 0;
		background: #fff;
		border-bottom: 1px solid #ccc;
		transition: transform 100ms ease-out;
		box-shadow: 0 6px 8px -6px rgba(0, 0, 0, 0.5);
	}

	.fixed-mode .header-sticky {
		visibility: visible;
	}

	.dateStyle {
		font-style:italic;
		color:#999;
	}
	.parenthesisStyle {
		font-style:italic
	}
	.progress-bar {
		white-space: nowrap;
	}
	.progress-bar span {
		color: #777
	}
	#dc-table-graph th {
		color:#888;
	}
	#timeColumnHeader {
		width:110px;
	}
}

.week-picker {
	padding:10px 15px;

	button {
		display:inline;
		list-style-type:none;
		width:40px;
		margin:0 5px;
		padding:4px 8px;
		border-radius:8px;
		border: 1px solid #AAA;
		color:#000;
	}
	div {
		margin-top:5px;
		font-size:12px;
		color:#888;
	}
}
