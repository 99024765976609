.dialog-panel.maximized {
	position: absolute;
	height: 100%;
	margin: 0 !important;
	z-index: 0;
	border-radius: 0;
	&:hover { z-index: 0; }
	.header { border-radius: 0; }
	.dialog-container {
		border-radius: 0;
	}
	.co-container {
		display: flex;
		flex: 1;
		flex-direction: column;
		border-radius: 0;
		opacity: 1;
		transition: opacity .2s .5s ease;

		.browser-wrapper {
			width: 100%;
			flex-direction: column;
			flex: 1;
			display: flex;
			box-shadow: 5px 0 20px -2px #000;
			margin-right: 30px;
			position: relative;

			.co-header {
				width: 100%;
				height: 35px;
				border-bottom: 1px solid #222;
				background: #555;
				display: flex;
				flex-direction: row;
				.header-menu .dropdown button.handle-menu:before,
				.header-menu .dropup button.handle-menu:before {
					content: '\e885';
					font-size: 20px;
					transform: none;
				}
			}
			.co-address-bar {
				flex: 5;
				display: flex;
				align-items: center;
				padding: 0 20px 0 10px;
				position: relative;

				.wrapper {
					flex: 1;
					display: flex;
					align-items: center;
					background: #fff;
					border-radius: 5px;
					height: 23px;
					position: relative;
					&.error {
						background: #FAE5E3;
					}

					.co-status {
						font-size: 8px;
						padding: 2px 0;
						display: inline-table;
						span {
							vertical-align: text-bottom;
							font-family: 'vngage-semibold', 'sans-serif';
							border-radius: 2px;
							padding: 1px 2px;
						}
						i { font-size: 12px; }

						&.conn-error {
							i { color: #C33A3A; }
							span {
								background: #C33A3A;
								color: #fff;
							}
						}
						&.view-only {
							i { color: #00b3ee; }
							span {
								background: #00b3ee;
								color: #fff;
							}
						}
						&.deactivated {
							span {
								background: #999;
								color: #fff;
							}
						}
						&.restricted {
							//margin-left: 5px;
							i { color: #C33A3A; }
							span {
								background: #C33A3A;
								color: #fff;
							}
						}
						&.blocked {
							cursor: not-allowed;
							i { color: #C33A3A; }
							span {
								background: #C33A3A;
								color: #fff;
							}
						}
					}

					.current-address {
						width: 100%;
						padding: 0 30px 0 3px;

						.handle {
							width: 26px;
							height: 23px;
							position: absolute;
							top: 2px;
							right: 0;
							border-radius: 0;
							text-align: center;
							&:hover { cursor: pointer; }
							&:after {
								content: '';
								display: inline-block;
								width: 0;
								height: 0;
								margin: 0 auto;
								vertical-align: middle;
								border-top: 4px solid;
								border-right: 4px solid transparent;
								border-left: 4px solid transparent;

							}
						}
						.caret {
							position: absolute;
							top: 10px;
							right: 15px;
							border-radius: 0;

						}
					}

					.dropdown {
						position: absolute;
						top: 30px;
						left: 0;
						background: white;
						width: 100%;
						max-height: 250px;
						overflow-y: auto;
						z-index: 600;
						padding: 10px 20px;
						border-radius: 3px;
						box-shadow: 0 7px 50px -20px #000;
						.list {
							.item {
								.owner { text-transform: capitalize; }
								&:hover { background: #EBEBEB; }
							}
							.disabled {
									pointer-events: none;
									color: lightgrey;
								}
						}
					}

					.btn-toolbar {
						width: 100%;
						.dropdown {
							width: 100%;
							button {
								background-color: transparent;
								border: 0;
								text-align: left;
								color: #444;
								box-shadow: none;
								font-size: 12px;
								width: 100%;

								span { border-radius: 0; }
								.caret {
									position: absolute;
									top: 10px;
									right: 25px;
								}
							}
							.dropdown-menu {
								width: 100%;
								left: -20px;
								top: 25px;
							}
						}
					}
				}
				.indicate-visitor-url {
					position: absolute;
					top: 32px;
					left: 0;
					padding: 0 20px 0 10px;
					z-index: 1;
					width: 100%;
					pointer-events: none;
					.visitor-url {
						padding: 5px 10px;
						color: white;
						background: rgba(0,0,0,.6);
						border-radius: 4px;
					}
				}
			}
			.co-toolbar {
				display: flex;
				//width: 160px;
				flex: 1;
				margin: 0 0 0 15px;
				color: #fff;
				align-items: center;
				.dropdown-menu {
					margin-top: 6px;
					right: 2px;
					&:before {
						content: '';
						display: inline-block;
						width: 0;
						height: 0;
						border: 6px solid;
						border-color: transparent transparent #fff transparent;
						position: absolute;
						top: -12px;
						right: 6px;
					}
					li {
						&.list-header {
							margin-left: 5px;
							text-align: center;
							a:hover {
								background: transparent;
								cursor: default;
							}
						}
					}
					li a {
						padding: 5px 5px;
					}
					i:before {
						font-size: 20px;
					}
				}
				.toolbar-item {
					flex: 1;
					font-size: 20px;
					margin: 0 5px;
					&:hover {
						color: #cecece;
						cursor: pointer;
					}
					&.disabled,
					&disabled:hover {
						color: #999;
						cursor: default;
						pointer-events: none;
					}
					&.active {
						color: #00b7c9;
					}
					button {
						background: transparent;
						border: 0;
						&:active,
						&:focus,
						&:hover {
							box-shadow: none;
						}
						&:hover { color: #00b7c9; }
					}
					.open {
						button {
							color: #00b7c9;
						}
					}
					.dropdown-menu {
						li {
							&.list-header {
								a:hover {
									background-color: transparent;
								}
							}
							a:hover {
								background-color: #EBEBEB;
							}
						}
					}
					.docked-right-actions-menu {
						width: 200px;
						height:100%;
						color: #444;
						font-size: 12px;
						background: white;
						box-shadow: -2px 3px 20px -3px #000;
						position: absolute;
						z-index: 1;
						right: 0;
						margin-top: 4px;
						animation-name: slideToLeft;
						transform-origin: center bottom;
						animation-duration: 0.3s;
						padding: 0 10px;
						overflow-y: scroll;

						.header {
							background: #fff;
							position: fixed;
							width: 200px;
							margin-left: -10px;
							color: #444;
							font-size: 12px;
							border-bottom: 1px solid #BFBFBF;
							display: flex;
							align-items: center;
							margin-bottom: 10px;
							padding: 0 5px;
							z-index: 2;
							i { font-size: 16px; }
							.title {
								margin-left: 5px;
							}
						}
						.list-container {
							margin: 45px 0;
						}
						.filter-wrapper {
							input.filter {
								width: 100%;
								border-radius: 3px;
								border: 1px solid #BFBFBF;
								font-size: 12px;
								padding: 6px;
							}
						}
						.action {
							margin: 10px 0 20px;
							.item {
								border-bottom: 1px solid #ccc;
								width: 180px;
								height: 40px;
								position: relative;
								overflow: hidden;
								background: #fff;
								i {
									font-size: 16px;
									width: 40px;
									height: 40px;
									display: inline-block;
									position: absolute;
									z-index: 1;
									padding: 8px;
								}
								.title-wrapper {
									width: 140px;
									left: 40px;
									top:11px;
									line-height: 1.3;
									.action-title {
									}
									.alt-btn {
										width: 40px;
										height: 40px;
										background: #d3d3d3;
										display: inline-block;
										position: absolute;
										right: 0;
										top: 0;
										i { background: transparent; }
										&:hover { color: #00b7c9; }
									}
								}
								&:hover {
									background-color: #f1f1f1;
								}
								&.url {
									.title-wrapper {
										/*&:hover {
											left: 0;
											transition: left .3s ease;
											transition-delay: .5s;
										}*/
									}
								}
							}
						}
					}
				}
				label {
					&.flipswitch { margin: 0; }
					.flipswitch-label {
						color: #444;
						text-shadow: none;
						font-size: 14px;
					}
				}
			}
			.restricted-overlay {
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.9);
				position: absolute;
				z-index: 100;
				.container {
					position: absolute;
					width: 510px;
					height: 300px;
					left: 50%;
					top: 50%;
					margin-left: -255px;
					margin-top: -150px;
					font-size: 30px;
					z-index: 2;
					color: @white;
					h1 { color: @white; }
					p {
						font-size: 14px;
						word-wrap: break-word;
					}
					.restricted {
						font-size: 11px;
						padding: 2px 0;
						display: inline-table;
						i {
							color: #C33A3A;
							font-size: 14px;
						}
						span {
							background: #C33A3A;
							color: #fff;
							vertical-align: text-bottom;
							font-family: 'vngage-semibold', 'sans-serif';
							border-radius: 2px;
							padding: 1px 2px;
						}
					}
				}
			}
			.co-iframe {
				flex: 1;
				position: relative;
				overflow: auto; /* Don't show scrollbars unless necessary */
				iframe {
					transform-origin: top left;
					transition: transform 400ms ease, height 400ms, width 400ms;
					width: 100%;
					height: 100%;
					position: absolute;
					border: 0;
				}
				.vngage-icon-co-browsing {
					font-size: 130px;
					text-align: center;
					width: 200px;
					height: 200px;
					position: absolute;
					left: 50%;
					margin-left: -100px;
					top: 50%;
					margin-top: -100px;
				}
				.disabled {
					background: rgba(0,0,0,.9);
					position: absolute;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					.message-wrapper {
						position: absolute;
						width: 350px;
						height: 250px;
						text-align: center;
						font-size: 16px;
						top: 50%;
						margin-top: -125px;
						left: 50%;
						margin-left: -175px;
						i { font-size: 80px; }
						p { color: #fff; }
						a { text-decoration: underline; }
					}
				}
				.view-only-overlay {
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,.9);
					position: absolute;
					z-index: 100;
					.container {
						position: absolute;
						width: 500px;
						height: 300px;
						left: 50%;
						top: 50%;
						margin-left: -250px;
						margin-top: -150px;
						font-size: 30px;
						z-index: 2;
						color: @white;
						h1 { color: @white; }
						p { font-size: 14px;}
						.checkbox {
							label {
								font-size: 12px;
								line-height: 20px;
							}
						}
						.view-only {
							font-size: 8px;
							padding: 2px 0;
							display: inline-table;
							i {
								color: #00b3ee;
								font-size: 12px;
							}
							span {
								background: #00b3ee;
								color: #fff;
								vertical-align: text-bottom;
								font-family: 'vngage-semibold', 'sans-serif';
								border-radius: 2px;
								padding: 1px 2px;
							}
						}
					}
				}
			}
			&.pending-content-upload {
				.spinner-container {
					position: absolute;
					width: 500px;
					height: 300px;
					left: 50%;
					top: 50%;
					margin-left: -250px;
					margin-top: -150px;
					font-size: 30px;
					text-align: center;
					z-index: 2;
					color: @white;

					&:after {
						content: '';
						border-radius: 100%;
						width: 50px;
						height: 50px;
						left: 50%;
						top: 50%;
						margin-left: -25px;
						margin-top: -25px;
						position: absolute;
						z-index: 2;
						/*border-left:2px solid lighten(@brand-success,20%);
						border-right:2px solid lighten(@brand-success,20%);
						border-bottom:2px solid lighten(@brand-success,20%);
						border-top:2px solid @brand-success;*/
						border: 2px solid @brand-success;
						border-bottom-color: transparent;
						.animation(rotation .8s infinite linear);
					}
				}

				&:after {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: rgba(0,0,0,.9);
					z-index: 1;
				}
			}
			.disconnected-overlay {
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.9);
				position: absolute;
				z-index: 100;
				.container {
					position: absolute;
					width: 500px;
					height: 300px;
					left: 50%;
					top: 50%;
					margin-left: -250px;
					margin-top: -150px;
					font-size: 30px;
					z-index: 2;
					color: @white;
					h1 { color: @white; }
					p { font-size: 14px;}
				}
			}
		}
	}

	//no need for box-shadow when panel is maximized
	&::after {
		display:none;
		opacity:0;
	}

	&.active {
		z-index: 0;
	}
	.refCode {
		top: 1px;
		z-index: 99;
		font-size: 9px;
		right: 2px;
	}
}

.co-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 599;
}

.co-container {
	flex: 0;
	display: none;
	opacity: 0;
	//transition: opacity .2s ease;
}

.co-sendPageModal {
	.modal-body {
		padding-left: 30px;
		padding-right: 30px;
	}
}
