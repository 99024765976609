.overlay-wrapper {
	z-index: 9000;
	height: 100%;
	width: 100%;
	position: absolute;
	pointer-events: none;
}

.overlay-fullscreen {
	flex: 1;
	height: 100%;
}

.overlay-content {
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 0;
	background: #444;
	border-radius: 3px;
	&:hover {
		.overlay-close {
			display: inline-block;
		}
	}

	.overlay-body {
		padding: 0;
		overflow: hidden;
		flex: 1;
		display: flex;
	}
}

.pointer-events-auto {
	pointer-events: auto;
}

.overlay-top-bar {
	pointer-events: none;
	display: flex;
	padding: 3px;
	width: 100%;
	z-index: 500;
	position: absolute;
}

.overlay-close {
	display: none;
	pointer-events: auto;
	i {
		color: #fff;
		font-size: 20px;
		&:hover {
			color: #00b7c9;
			cursor: pointer;
		}
	}
}

.overlay-header {
	padding: 5px;
	&:hover { cursor: move; }
	border-bottom: hidden;

	h5 {
		margin-bottom: 5px;
		color: #fff;
	}
}

.overlay-filler {
	flex: 1;
}