.loading-content-container {
	width: 273px;
	height: 80%;
	max-height: 100%;
	margin: 0 5px;
	background: #fff;
	z-index: 1;
	flex-direction: row;
	flex-shrink: 0;
	border-radius: 10px 10px 0 0;
	//set a subtle shadow, but never change it through animation
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	transform-origin: bottom center;
	position: absolute;
	bottom: 0;
	//.animation(fade-in .4s linear);

	.close-button-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;

		.close-button {
		}

		h3 {
			width: 90%;
			padding-bottom: 10px;
		}
	}

	.loader-header {
		width: 100%;
		border-bottom: 1px solid #ddd;
		height: 35px;
		color: #999;
		padding: 3px 8px;

		.title {
			margin: 5px;
			display: inline-block;
		}

		.loader-dialog-connector {
			margin: 5px;
			width: 20px;
			height: 5px;
			font-size: 5px;
			float: left;

			> div {
				height: 100%;
				width: 100%;
				border-radius: 5px;
				display: block;
				margin-bottom: 0.6em;
				-webkit-animation: stretchdelay 1.1s infinite ease-out;
				animation: stretchdelay 1.1s infinite ease-out;
			}

			.rect2 {
				-webkit-animation-delay: -1s;
				animation-delay: -1s;
			}

			.rect3 {
				-webkit-animation-delay: -0.7s;
				animation-delay: -0.7s;
			}

		}
	}

	.loader-visitor-meta {
		height: 50px;
		border-bottom: 1px solid #ddd;
		width: 100%;
		padding: 13px 15px 10px;

		.loader-placeholder {
			height: 7px;
		}
	}

	.loader-toolbar {
		height: 42px;
		border-bottom: 1px solid #ddd;
		display: flex;
		flex: 1;

		.loader-tool {
			flex: 1;

			&:after {
				content: '';
				display: flex;
				width: 25px;
				height: 25px;
				border-radius: 7px;
				border: 2px solid #ddd;
				margin: 8px auto;
			}
		}
	}

	.loader-content {
		width: 100%;
		background: #e3e3e3;
		padding: 20px 15px 10px;
		//opacity: 1;
		//transition: opacity 20s linear;
		//.animation(fade-in .8s .6s linear);

		&.inverse {
			background: transparent;
		}
	}

	.loader-placeholder {
		background: #fff;
		height: 7px;
		margin-bottom: 8px;

		&.short {
			width: 30%;
		}

		&.medium {
			width: 70%;
		}

		&.long {
			width: 90%;
		}

		&.inverse {
			background: #e3e3e3;
		}
	}

	.loader-footer-container {
		width: 100%;
		height: 100px;
		position: absolute;
		bottom: 0;

		.loader-input {
			width: 93%;
			height: 50px;
			margin: 0 auto;
			border: 1px solid #ddd;
			border-radius: 6px;
		}

		.loader-options {
			display: inline-block;
			width: 180px;
			margin: 7px 10px;
			height: 25px;
			border: 1px solid #ddd;
			border-radius: 4px;
			position: relative;

			&:before,
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				right: 5px;
				border-width: 4px;
				border-style: solid;
				border-color: transparent;
			}

			&:before {
				border-bottom-color: #ccc;
				top: 2px;
			}

			&:after {
				border-top-color: #ccc;
				bottom: 2px;
			}
		}

		.loader-action {
			display: inline-block;
			width: 60px;
			height: 25px;
			border-radius: 4px;
			border: 1px solid #ddd;
			position: relative;
			top: -7px;
			right: 0;
		}
	}
}

.pending-auto-routing {
	position: absolute;
	width: 100%;
	height: 100%;
	margin-top: 0px;
	background: rgba(0, 0, 0, .7);
	z-index: 1;

	.urging-action-container {

		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.buttons-container {
			position: relative;
			top: -85px;
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.separator {
			width: 20px;
		}

		.label-container {
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;
			color: @white;
			width: 90px;
			max-width: 90px;

			> button {
				margin-bottom: 10px;
			}
		}

		.select-btn {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			position: relative;
			border: 2px solid;
			border-color: @white;
			background-color: @brand-success;
			color: @white;
			font-size: 9px;
			text-transform: uppercase;
			box-shadow: 0 4px 2px 2px rgba(0, 0, 0, .1);

			&.none {
			}

			&.warning {
				background-color: @brand-warning;

				&:before {
					box-shadow: 0px 0px 2px 2px @brand-warning;
				}
			}

			&.danger {
				background-color: @brand-danger;

				&:before {
					box-shadow: 0px 0px 2px 2px @brand-danger;
				}
			}

			&.beacon {
				&:before {
					position: absolute;
					content: "";
					height: 60px;
					width: 60px;
					left: -2px;
					top: -2px;
					background-color: transparent;
					border-radius: 50%;
					//box-shadow:0px 0px 2px 2px @brand-danger;
					-webkit-animation: active 2s infinite linear;
					animation: active 2s infinite linear;
				}
			}

			i {
				font-size: 20px;
			}

			.select-btn-label {
				letter-spacing: .03em;
			}
		}

		.urging-action-message {
			top: -65px;
			position: relative;
			margin-bottom: 50px;
			padding: 0 10px;
			text-align: center;
			color: @white;
			font-family: 'vngage', sans-serif;

			.urging-timer {
				font-family: 'vngage-semibold', sans-serif;
			}

			.loading-spinner {
				display: inline-block;
				border-radius: 100%;
				width: 24px;
				height: 24px;
				margin-top: 15px;
				border: 1px solid @brand-success;
				border-bottom-color: transparent;
				.animation(rotation .8s infinite linear);
			}
		}
	}

}

@-webkit-keyframes active {
	0% {
		opacity: 0;
	}
	70% {
		-webkit-transform: scale(1.1);
		opacity: 1;
	}
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

@keyframes active {
	0% {
		opacity: 0;
	}
	70% {
		transform: scale(1.1);
		opacity: 1;
	}
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}
