.help {
    color: #cecece;
}

.form-group {
	margin-bottom: 23px;

	&.vngage-validation {
		input[required] {
			border-left: solid 5px #201f1f;
		}

		//label:after {
		//	content: '*';
		//}
	}

  .alert {
    border-radius: 0;
    padding: 4px;
  }

  .alert-danger {
    display:none;
  }

  &.has-error {
    .alert-danger {
      display:block;
    }
  }

	.help {
		float: right;
	}

	.preview-selected-icon {
		width: 101px;
		background-color: #f9f9f9;
		border-radius: 2px;
		margin-top: -34px;
		float: right;
		margin-right: -137px;
		padding: 6px 9px;

		span {
			vertical-align: middle;
			font-family: 'vngage-semibold', sans-serif;
		}
	}
	label {
		.sub-label {
			color: #999;
			display: block;
			font-size: 11px;
		}
	}
}

label {
	display: block;
	//font-family: 'vngage-semibold', sans-serif;
	font-weight: normal;
	.sub-label {
		color: #999;
		display: block;
		font-size: 11px;
	}
}
.radio label, .checkbox label {
	display: inline-block;
}

.heading-controls {
	.btn {
		margin-top: 5px;
	}
}

.identity-id {
	vertical-align: middle;
}

.url-category-color {
	display: inline-block;
	border-radius: 3px;
	position: relative;
	//border: 1px solid #333;
	top: -1px;
	right: -2px;
	i {
		color: #fff;
		text-shadow: 0 1px 0 rgba(0,0,0,.2);
	}
}
.edit-translations {
	.title {
		margin-bottom:15px;
	}
	.card {
		padding:6px 10px;
		min-height:55px;
	}
	.card.disabled {
		color:#AAA;
	}
	.form-control.highlight {
		border:1px solid #777;
	}
}

@-webkit-keyframes rollDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(1px);
	}
}
@-moz-keyframes rollDown {
	0% {
		-moz-transform: translateY(-100%);
	}
	100% {
		-moz-transform: translateY(0px);
	}
}
@-o-keyframes rollDown {
	0% {
		-o-transform: translateY(-100%);
	}
	100% {
		-o-transform: translateY(0px);
	}
}
@keyframes rollDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0px);
	}
}

.default-line-btn-contrast {
	background-color: transparent;
	border-color: @white;
	color: @white;
	transition: color, border, .1s ease;
	&:focus {
		background-color: transparent;
		border-color: @white;
		color: @white;
	}
	&:hover,
	&:active {
		background-color: transparent;
		border-color: #00b7c9;
		color: #00b7c9;
		transition: color, border, .1s ease;
	}
}
