@identified-color: #7FDBFF;
@identified-color-faded: fade(@identified-color, 80%);
.ui-grid-cell-contents {
     &.selected {
        background-color: #FFEEAA;
    }
}
div.ui-grid-row:hover {
    span {
        text-decoration: underline;
        color: @link-color;
    }
    .ui-grid-cell {
        background-color: @table-bg-hover;
    }
}

.ui-grid-vngage-header-cell {
    background-color: blue;
}

.ui-grid-row:nth-child(odd) .ui-grid-cell {
  background-color: transparent;
}

.ui-grid-row:nth-child(even) .ui-grid-cell {
  background-color: @table-bg-accent;
}

.ui-grid-top-panel {
    background-color: #fdf3e5;
    background: #fdf3e5;
    border-radius: 5px 5px 0px 0px;
}

.ui-grid {
    border-radius: 5px 5px 5px 5px;
}

i.glyphicon.glyphicon-chevron-left:before {
    font-family:'icon';
    content: '\e830';
}

i.glyphicon.glyphicon-chevron-left {
    font-family:'icon';
    content: '\e830';
}

i.glyphicon.glyphicon-chevron-right:before {
    font-family:'icon';
    content: '\e831';
}

i.glyphicon.glyphicon-chevron-right {
    font-family:'icon';
    content: '\e831';
}

.case-browser-filter {
    padding-left: 0;
    padding-right: 0;
    margin-top: -30px;
}

.card-case-browser {
    box-shadow: inset 0 0;
    -webkit-box-shadow: inset 0 0;
    border: 0px;
    min-height: 190px;
    border-bottom: 2px solid #e3e3e3;
    border-left: 2px solid #e3e3e3;
    border-right: 2px solid #e3e3e3;
    border-radius: 0;
    margin-bottom: 40px;

    input.ng-invalid { border-color: @brand-danger; }
}

h1.inline-header {
    margin: 0;
}

.ui-grid-header-cell {
    span:first-of-type {
        font-family: vngage-semibold;
    }
    span:first-of-type:before {
        content: '\00a0\00a0';
    }
}

.caseBrowserModalForm {

    .modal-body {
        padding: 15px;
    }
    .center-text {
        text-align: center;
        position: relative;
        background: @identified-color-faded;
        z-index: 1;
        .inner {
            padding: 0 5px;
            display: inline-flex; // this fixes gaps between spans in inner
        }

    }
    .text-with-line {
        background: #fff;
        text-align: center;
        position: relative;
        z-index: 1;
        &:before {
            border-top: 2px solid #dfdfdf;
            content:"";
            margin: 0 auto; /* this centers the line to the full width specified */
            position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
            top: 50%; left: 0; right: 0; bottom: 0;
            width: 95%;
            z-index: -1;
        }

        .inner-white {
            /* to hide the lines from behind the text, you have to set the background color the same as the container */
            background: #fff;
            padding: 0 5px;
            display: inline-flex; // this fixes gaps between spans in inner
        }

    }

    .case-day-date {
        text-align: center;
        margin-bottom: 10px;
        white-space: nowrap;
        clear: both;

        &:before,
        &:after {
            content: '';
            height: 1px;
            background: #f1f1f1;
            width: 34%;
            display: inline-block;
            position: relative;
            top: -5px;
        }
        &:before { left: -10px; }
        &:after { right: -10px; }
    }

    .case-day-dates {
        text-align: center;
        margin-bottom: 10px;
        white-space: nowrap;
        clear: both;

        &:before,
        &:after {
            content: '';
            height: 1px;
            background: #f1f1f1;
            width: 22%;
            display: inline-block;
            position: relative;
            top: -5px;
        }
        &:before { left: -10px; }
        &:after { right: -10px; }
    }
}

.satisfaction-score {
    padding: 0px 4px 2px 5px;
    width: 46px;
    text-align: center;
    display:block;
    text-shadow: 0.5px 0.5px #000;
}

.conversation-header {
    padding: 0 35px 0 35px;
    .heading {
        float: left;
        display: inline-block;
    }
}

.conversation-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    .message-list {
        width: 100%;
        .message {
            padding: 10px 25px;
            word-break: break-word;

            p {
                margin-bottom: 0;
                line-height: 21px;
                + p { margin-top: 10px; }
            }

            &.agent {
                background-color: #F2F2F2;
            }
            &.visitor {
                background-color: #fff;
            }
            &.visitor-profile {
                .info-box {
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    border: 2px dashed lightgrey;
                    padding-left: 5px;
                    padding-top: 5px;
                }

                .web-visitor {
                    vertical-align: middle;
                    font-size: 28px;
                    margin-left: -6px;
                }

                .icon-big:before {
                    vertical-align: middle;
                    font-size: 28px;
                    margin-left: 0px;
                }

                .visitor-information {
                    font-family: vngage-bold, sans-serif;
                    .time-stamp { font-family: vngage-semibold, sans-serif; }
                }
                .claims-container {
                    padding-top: 8px;
                    .claim-row {
                        padding-bottom: 2px;
                        //padding-left: 36px;
                    }
                    .claim-name {
                        width: 150px;
                        float: left;}
                    .claim-value {
                        margin-left: 150px;
                    }
                }
            }
            &.note {
                color: #bababa;
                font-style: italic;
            }

            &.identified {
                padding-left: 30px;
                border-right: 7px solid @identified-color-faded;
                border-left: 7px solid @identified-color-faded;
            }

            &.identified-status {
                padding: 0px 0px;
            }
            &.day-break {
                top: 0;
                z-index: 100;
                position: -webkit-sticky;
                position: sticky;
                margin: auto;
                padding: 0px 0px 0px 0px;
            }
            .speaker {
                font-family: vngage-bold, sans-serif;
                .time-stamp { font-family: vngage-semibold, sans-serif; }
            }
            &.visitor-navigation {
                color: #ccc;
                background: #fff;
                font-style: italic;
                .link { text-decoration: underline; }
            }
            i {
                font-style: normal;
                position: relative;

                &.photo:before {
                    font-family: 'icon';
                    content: '\e84b';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.pdf:before {
                    font-family: 'icon';
                    content: '\e84a';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.link:before {
                    font-family: 'icon';
                    content: '\e84c';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.video:before {
                    font-family: 'icon';
                    content: '\e849';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.render:before {
                    font-family: 'icon';
                    content: '\e81f';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.content-image:before {
                    font-family: 'icon';
                    content: '\e84b';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.content-video:before {
                    font-family: 'icon';
                    content: '\e849';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }
                &.content-file:before {
                    font-family: 'icon';
                    content: '\e84a';
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 16px;
                }

            }
            .video-container {
                iframe {
                    width: 100%;
                    height: auto;
                    min-height: 300px;
                }
            }
            .photo-container {
                img {
                    width: 100px;
                    height: auto;
                    &.max {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .agent {
            + .agent {
                padding-top: 0;
                margin-top: -10px;
            }
        }
        .visitor {
            + .visitor {
                padding-top: 0;
            }
            /*+ .visitor-navigation {
                padding-top: 0;
            }*/
        }
        .visitor-navigation {
            /*+ .visitor {
                padding-top: 0;
            }*/
            + .visitor-navigation {
                padding-top: 0;
            }
        }
        .note {
            + .note { margin-top: -15px; }
        }
        .no-show { display: none; }
    }
}
