@import "option-area";
@import "finish-area";

.footer {
	height: 50px;
	padding-bottom: 16px;
	background: white;
	justify-content: space-between;
	flex-direction: row;
	display: flex;
	margin-left: 7px;
	margin-right: 7px;



	.case-list {
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		height: auto;
		max-height: 265px;
	}

	.case-list::-webkit-scrollbar {
		width: 1em;
	}

	.case-list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.case-list::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}

	.scroll-up-indicator {
		position: fixed;
		margin-top: 40%;
		height: 16px;
		width: 100%;
		text-align: left;
		pointer-events: none;
	}

	.case-switch {
		display: flex;
		flex: 2;
		padding: 8px 0px 0 0px;

		.caret {
			display: none;
		}

		.btn-group {
			display: flex;
			flex: 1;
		}

		.btn {
			background: transparent;
			color: black;
			border-color: #BFBFBF;
			width: 100%;
			max-width: 162px;
			text-align: left;
			padding: 3px 5px;

			:before {
				content: '';
				display: inline-block;
				border: 4px solid;
				height: 0;
				width: 0;
				margin-right: 5px;
				border-color: transparent;
				border-top-color: black;
				position: absolute;
				right: 0;
				top: 13px;
			}

			:after {
				content: '';
				display: inline-block;
				border: 4px solid;
				height: 0;
				width: 0;
				margin-right: 5px;
				border-color: transparent;
				border-bottom-color: black;
				position: absolute;
				right: 0;
			}
		}

		.dropdown-menu {
			max-width: 254px;

			a {
				color: #444;
				padding-left: 20px;
				white-space: normal;

				&:hover {
					cursor: pointer;
				}
			}

			li a:hover {
				background-color: #EBEBEB;
			}
		}
	}
}