.desktopLegacy {
	font-family: Helvetica, Arial, Sans-Serif;
	font-size: 11px;
	color: #666;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
	background: #F3F3F3;
	position: relative;
	z-index:1;
	height: 100%;
	width: 100%;


	@import "all/style";
	@import "all/expandablearea";
	//@import "all/icons";
	@import "modules/case";
	@import "modules/chat";
	@import "modules/cobrowser";
	@import "modules/coworker";
	@import "modules/dialogboxes";
	@import "modules/filter";
	@import "modules/queuepanel";

	//in vergic.css legend font-size is 21px !!!
	legend {font-size: 11px;}

}


@import "all/avgrund";

@import "all/chathistory";
//alignment for autotext+case history popover
.popover.left {left:auto;}

//fixes z-index for desktopLegacy
#___contentSectiononlineList {
	z-index:1029 !important;
}

//#application #content > div { height:100%; }
@import "all/notification";



/*   NOTIFY   */
.notify {
	color: #444;
	background: #eee;
	border-radius: 5px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
	font-size: 12px;
	font-weight: bold;
	line-height: 35px;
	padding: 0 12px;
	position: absolute;
	min-width: 320px;
	top: 8px;
	right: 50%;
	margin-right: -160px;
	-webkit-transition: all 350ms ease;
	-moz-transition: all 350ms ease;
	transition: all 350ms ease;
	white-space:nowrap;
	z-index: 1;
	opacity: .5;
	&:hover { opacity: 1; }

	&.stick-right {

	}
	&.intro {
		opacity: 1;
		right: 50%;
		-webkit-transform: translate(0, 100px);
		-moz-transform: translate(0, 100px);
		transform: translate(0, 100px);
	}
	&.hidden {
		-webkit-transform: translate(0, -50px);
		-moz-transform: translate(0, -50px);
		transform: translate(0, -50px);
	}
	&.no-transition {
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
	}
	button {
		cursor: pointer;
		color: #fff;
		background: #55043B;
		border-radius: 5px;
		border: 0;
		font-size: 12px;
		font-weight: bold;
		line-height: 1.8;
		padding: 0 15px 1px;
		margin-left: 20px;
		-webkit-transition: background 350ms ease;
		-moz-transition: background 350ms ease;
		transition: background 350ms ease;

		&:hover {
			background: #780955;
		}
	}
}

//meeting code styling for angular-toastr
.meeting-code {
	font-size:24px;
	line-height:1;
	letter-spacing: 0.05em;
}


//Temporary tooltips!

//.legacy-tip{display:inline;position:relative}
.legacy-tip{position:relative;}
.legacy-tip:hover{text-decoration:none;}

.legacy-tip:before, .legacy-tip:after {
	position: absolute;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	visibility: hidden;
	opacity: 0;
	z-index: 1000000;
	-webkit-transition: 400ms 200ms ease;
	-moz-transition: 400ms 200ms ease;
	transition: 400ms 200ms ease;
	pointer-events: none;

}
//ignore focus state for now
//.legacy-tip:focus:before,
//.legacy-tip:focus:after,
.legacy-tip:hover:before,
.legacy-tip:hover:after {
	visibility: visible;
	opacity:1;
}


.legacy-tip.legacy-tip-right:after {
	left:auto;
	right:0;
}
.legacy-tip.legacy-tip-center:after {
	left:-100%;
	right:auto;
}

.legacy-tip.legacy-tip-top:before {
	border-top-color: rgba(0,0,0,.8);
	margin-top: 1px;
}
.legacy-tip.legacy-tip-top:after {
	top: -25px;
	left: -100%;
	right: auto;
}

.legacy-tip:after {
	background:#111;
	background:rgba(0,0,0,.8);
	border-radius:5px;
	top:24px;
	color:#fff;
	content:attr(tip);
	display:block;
	left:0;
	white-space:nowrap;
	text-shadow: 0 -1px 0 black;
	padding: 8px 10px;
	font-size: 12px;
	line-height: 12px;
}

.legacy-tip:before {
	margin-top:10px;
	content: '';
	position: absolute;
	background: transparent;
	border: 6px solid transparent;
	border-bottom-color:rgba(0,0,0,.8);
	z-index: 1000001;
}



.modal-security {

	.modal-dialog { width:400px; margin-top:80px;}
	.modal-footer {text-align: center; border:none; padding-top:0;}
	.modal-body {padding:20px 40px;}

	ol, ul {
		margin:1em 0;
		padding-left:0;
	}
	li {
		margin:.5em 0 .5em 1.5em;

	}
}

.smiler {
	display: block;
	margin:0 auto -20px;
	width:80px;
	height:80px;
}
.smil {fill:#00B7C9;}
//.smil-r-eye, .smil-l-eye

strong, b {
	font-family: 'vngage-semibold', sans-serif;
}

.refCodeArea {
	position: absolute;
	top: -36px;
	right: 0;
	font-size: 10px;
	color: rgb(142, 142, 142);
}


.coworker-container {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
}

.coworker-col {
	display:flex;
	flex-direction:column;
}

.col-flex, .flex1 {
	-webkit-flex:1;
	flex:1;
}


.coWorkerCoBrowsingArea {
	display: -webkit-flex;
	display: flex;
	-webkit-flex:1;
	flex:1;
}
.psBrowserWrapper,
.vg-wrapper-thingy{
	display: -webkit-flex;
	display: flex;
	-webkit-flex:1;
	flex:1;

	flex-direction:column;
}
.psIframeWrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex:1;
	flex:1;
	//position:relative;
}

.coworker-container .visual-guidance-container .vg-legacy-iframe-wrap {
	background:transparent;
}


.desktopLegacy button.btn-video-startstop {
	margin-left:5px;
	margin-bottom: 5px;
}

.desktopLegacy {

	.psExpandableAreaContent {
		.btn-xs {
			padding: 2px 5px 0;
			font-weight: bold;
			font-size: 11px;
			background-color: #777;
			border-color: #777;

			&:hover {
				background-color: #444;
				border-color: #444;
			}

			&.btn-success {
				background-color: #1b9f2a;
				border-color: #1b9f2a;

				&:hover {
					background-color: #178924;
					border-color: #178924;
				}
			}
		}
	}

	#videowrap {
		background-color: #222;
		.video-toolbar {
			label { margin-top: 1px; }
		}
	}

	.legacy-tool-bar {
		button:hover {
			box-shadow: none;
		}
	}
}

.legacy-tool-bar {
	padding:5px 0 0;

	.dropdown-menu {
		> li a {
			&:focus, &:active { outline: none; }
		}
	}

	.btn {
		font-size:11px;
		font-weight:bold;
		color: #fff;
			&:before, &:after {
				:focus, :active {
					outline: none;
			}
		}
	}
	.split-button {
		margin: 0 5px;
		display: inline-block;
	}
	.btn-xs {
		&.btn-success {
			padding: 1px 5px 0;
		}
	}
	.btn-default {
		background-color: #777;
		border-color: #777;
		&:hover {
			background-color: #444;
			border-color: #444;
		}
	}
}

.desktopLegacy {
	.conversation-participants {
		//margin:5px 0 0;
		padding:3px 0;
		border-top:1px solid #ddd;
	}
	.participant {
		float:left;
		.dropdown-toggle .author-name {
			display:none;
		}

		.dropdown-menu {
			padding-top:0;

			li:first-child.dropdown-header {
				padding-top:8px;
				margin-bottom:4px;
				background:#ddd;
				color:#222;
				font-weight:bold;
				border-bottom:1px solid #ccc;
				border-radius: @dropdown-border-radius @dropdown-border-radius 0 0;
			}

			a {outline:none; background:transparent;}

			//TODO: ugly hack to force label and switch to be on the same line in the dropdown
			.flipswitch {min-width:180px;}

			.flipswitch-label {
				font-size:14px;
			}
		}
	}
}

#videoChatVidWrap > div {
	border-top:1px solid #ddd;
	padding:4px 0;
}

.unread:last-child {
	.message-text {
		animation: new-message 1.5s ease-in-out infinite;
		transition: opacity;
	}
}

.modal-backdrop {
	z-index: 9;
}

.psExpandableAreaContent {
	.well { padding: 7px; }
}

.psExpandableAreaContent {
	.well { padding: 7px; }
}

@-moz-keyframes new-message {
	0%   { opacity: 1; }
	50% { opacity: .5; }
	100% { opacity: 1; }
}
@-o-keyframes new-message {
	0%   { opacity: 1; }
	50% { opacity: .5; }
	100% { opacity: 1; }
}
@keyframes new-message {
	0%   { opacity: 1; }
	50% { opacity: .5; }
	100% { opacity: 1; }
}
//Moved to dialogguest for inclusion
@import "modules/videochat";
