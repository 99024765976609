.ui-grid-icon-up-dir:before { font-family:'icon'; content: '\e82b'; }
.ui-grid-icon-down-dir:before { font-family:'icon'; content: '\e82a'; }
.ui-grid-icon-sort-alt-up:before { font-family:'icon'; content: '\e828'; }
.ui-grid-icon-sort-alt-down:before { font-family:'icon'; content: '\e829'; }
.ui-grid-icon-cancel:before { font-family:'icon'; content: '\e818'; }
.ui-grid-icon-angle-down:before { font-family:'icon'; content: '\e82d'; }
.ui-grid-icon-menu:before { font-family:'icon'; content: '\e82c'; }

.ui-grid-menu-button {
	border: 0;
	background: transparent;
}

.ui-grid,
.ui-grid-header {
	border-width: 2px;
}
.ui-grid-render-container-body {
	width: auto;
}