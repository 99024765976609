.hide-modal-debug {
	display: none !important;
}


.debugger-tool {
	color: #999;
	background: rgba(0, 0, 0, .7);
	padding: 5px;
	width: 350px;
	border-radius: 3px;
	box-shadow: 0 15px 40px -10px #823458;

	.btn-default {
		&.dark {
			color: #fff;
			background: #666;
			border: 0;
			font-weight: lighter;

			i {
				font-size: 14px;
			}
		}

		+ .btn-block {
			margin-left: 0;
		}

		&.dark-lang-button {
			color: #fff;
			background: #666;
			border: 0;
			font-weight: lighter;
			font-size: 12px;
			margin-top: 3px;
			//border-radius: 10px;

			&.active {
				border: 2px solid #00b7c9;
			}
		}
	}

	.dropdown {
		&.dark {
			.btn-default {
				color: #fff;
				background: #666;
				border: 0;
				font-weight: lighter;
				width: 100%;

				i {
					font-size: 14px;
				}
			}
		}
	}
}

.minimized-debug-container {
	position: absolute;
	border-radius: 0px 0px 6px 0px;
	cursor: pointer;
	z-index: 1;

	&.badge {
		text-shadow: 0px 1px 2px #000;
		padding: 2px 10px 4px 10px;
	}
}

.debugger-tool-handle {
	padding: 5px 10px 10px;
	border-bottom: 1px solid #999;
	margin-bottom: 5px;

	.badge {
		display: block;
		text-shadow: none;
		padding: .2em 0 .3em;
		font-size: 65%;
	}

	.flip-container {
		display: inline-block;
	}

	.badge-btn {
		display: block;
		padding: 10px 10px 10px 10px;
		position: absolute;
		top: 5px;
		left: 73%;
		border: none;
		cursor: pointer;

		.badge-icon {
			background-color: #fff;
			display: block;
			width: 20px;
			height: 1px;
		}
	}

	.collapse-bar {
		padding: 1px 10px 10px 1px;
		background-color: #333;
		position: absolute;
		width: 20px;
		left: 85%;
	}

	.debugger-header {
		display: inline-block;
		margin-left: 5px;
		padding-left: 10px;
		border-left: 1px solid #999;

		h5 {
			margin-bottom: 3px;
			color: #fff;
		}
	}

	&:hover {
		cursor: move;
	}
}

.debugger-tool-container {
	.lang-buttons {
		display: flex;
		flex-direction: row;

		h5 {
			margin-bottom: 3px;
			color: #fff;
		}
	}

	input[type="file"] {
		display: block;
		height: 32px;
		width: 100%;
		margin-top: 5px;
	}

	#debug-file-import {
		overflow: hidden;

		&::-webkit-file-upload-button {
			visibility: hidden;
		}

		&::before {
			content: 'Import debug zip- or json-file';
			width: 100%;
			outline: none;
			color: #fff;
			background: #666;
			border: 0;
			font-weight: lighter;
			display: inline-block;
			margin-bottom: 0;
			text-align: center;
			vertical-align: middle;
			touch-action: manipulation;
			cursor: pointer;
			background-image: none;
			white-space: nowrap;
			padding: 6px 12px;
			font-size: 14px;
			line-height: 1.42857143;
			border-radius: 4px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	.badge {
		text-shadow: none;
	}

	.well {
		padding: 10px 0;
		font-size: 12px;
		color: #fff;
		background: rgba(0, 0, 0, .6);
		border: 0;

		button {
			margin-left: 3px;
		}
	}

	.row {
		margin-left: 0;
	}
}

.tab-container {
	display: flex;

	.tab-nav {
		display: flex;
		flex-direction: column;
		width: 68px;
		color: #fff;
		border-right: 1px solid #999;
		padding-right: 5px;

		.nav-item {
			padding: 10px;
			text-align: center;
			transition: background .2s ease;
			align-self: center;
			width: 100%;
			border-radius: 10px;
			position: relative;

			&.active {
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					height: 4px;
					border-radius: 3px;
					width: 34px;
					left: 50%;
					margin-left: -17px;
					margin-top: 5px;
					//background: #66d4df;
					background: rgba(160, 53, 147, 1);
				}
			}

			&:hover {
				cursor: pointer;
				background: #555;
				transition: background .2s ease;
			}

			.badge {
				display: block;
				padding: 0;
			}
		}
	}

	.tab-content {
		display: flex;
		flex: 1;
		padding: 10px;
		max-width: 350px;
	}

	.group-container {
		padding: 10px 0;
		display: inline-block;
		font-size: 80%;
		width: 100%;

		+ .group-container {
			padding-top: 0;
		}

		&.step-controls {
			color: #fff;

			.control-item {
				display: inline-block;
				font-size: 18px;

				.badge {
					display: block;
					color: #888;
					font-size: 8px;
					text-align: center;
					padding: 0;
					margin-top: -7px;
				}
			}
		}

		&.history-states {
			margin-bottom: -10px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;

			.padding-right {
				padding-right: 5px;
			}

			.padding-left {
				padding-left: 5px;
			}

			span {
				display: inline-block;
			}
		}

		.switchContainer {
			float: left;
		}

		.iButton {
			margin-top: 15px;
			float: right;
		}
	}
}
