@-webkit-keyframes pad1 {
  0% {top:25px;}
  11% {top:25px;}
  22% {top:50px;}
  32% {top:50px;}
  42% {top:0px;}
  52% {top:0px;}
  62% {top:50px;}
  70% {top:50px;}
  81% {top:0px;}
  92% {top:0px;}
  100% {top:25px;}
}

@-webkit-keyframes pad2 {
  0% {top:0px;}
  11% {top:25px;}
  22% {top:25px;}
  30% {top:50px;}
  41% {top:50px;}
  51% {top:0px;}
  60% {top:0px;}
  72% {top:50px;}
  80% {top:50px;}
  91% {top:0px;}
  100% {top:0px;}
}
@-webkit-keyframes ball {
  0% {top:30px; left:5px;}
  10% {top:30px; left:45px;}
  20% {top:55px; left:5px;}
  30% {top:55px; left:45px;}
  40% {top:10px;left:5px;}
  50% {top:10px;left:45px;}
  60% {top:55px;left:5px;}
  70% {top:55px;left:45px;}
  80% {top:10px;left:5px;}
  90% {top:10px;left:45px;}
  100% {top:30px;left:5px;}
}


.pad1, .pad2 {
  width: 5px;
  height: 20px;
  background: #333;
  position: absolute;
  border-radius: 2px;
}
.ball {
  width: 5px;
  height: 5px;
  background: #333;
  border-radius: 50%;
  position: absolute;
}
.pad1 { -webkit-animation: pad1 5s infinite; }
.pad2 { -webkit-animation: pad2 5s infinite; left: 50px; }
.ball { -webkit-animation: ball 5s infinite; }

#loader {
  background: rgba(255,255,255, .8);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: none;
  visibility: hidden;

  &.loader-active {
//    visibility: visible;
  }

  .ping-box {
    width: 55px;
    height: 70px;
    position: absolute;
    margin-left: 50%;
    left: -25px;
    top: 50%;
    margin-top: -45px;
  }
}