//icon styling for url categories in QueuePanel
.vngage-url-category {
	font-size: 14px;
	display: inline-block;
	border-radius: 2px;
	line-height: 20px;
	width: 22px;
	height:20px;
	text-align: center;
	margin: 0 1px 0 0;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
	cursor: help;
}

.vngage-bg-purple			{background-color: rgb(102,45,145);}
.vngage-bg-green			{background-color: rgb(0,104,55);}
.vngage-bg-blue				{background-color: rgb(0,57,91);}
.vngage-bg-red				{background-color: rgb(193,39,45);}
.vngage-bg-orange			{background-color: rgb(241,90,36);}
.vngage-bg-brown			{background-color: rgb(96,56,19);}
.vngage-bg-yellow			{background-color: rgb(251,176,59);}
.vngage-bg-gray				{background-color: rgb(77,77,77);}
.vngage-bg-lightGray	{background-color: rgb(100,100,100);}


#navmenu li :hover i.vngage-icon-stash,
#navmenu li.active :hover i.vngage-icon-stash,
#navmenu li.active i.vngage-icon-stash,
.vngage-icon-stash {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDQ4M0IzM0E4NzlCMTFFNTlBQkZBREM5MEIxMTQ1MjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDQ4M0IzM0I4NzlCMTFFNTlBQkZBREM5MEIxMTQ1MjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozRTUyNUY3Rjg3OTkxMUU1OUFCRkFEQzkwQjExNDUyOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozRTUyNUY4MDg3OTkxMUU1OUFCRkFEQzkwQjExNDUyOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pi9QOOEAAACBSURBVHjaYvz//z8DOYBxBGhkAGrsAGlGwp5AvAmI/aB4E1QMWU0HiHgNxIJAzATEuUD89z8EvIfi/1CxXKgakNrXIKeeBlqsDcQ/gVgAyTGHoLQdktgHIGYH4qsgGxWAeBcQ/wbi70C8D4gjoKYzQdn7oHK/oWoVGEcTAG4AEGAAqYm3TlG8gH0AAAAASUVORK5CYII=');
	background-repeat: no-repeat;
	background-position: 50%;
}


//legacyDesktop icon styles
.ps_icon_title {
	border: 1px solid #ccc;
	background-color: #fff;
	color: #000;
	left: 0;
}

.ps_icon_big {
	position: relative;
	height: 24px;
	width: 38px;
	text-align: center;
	font-size: 14px;
	margin: 7px;
	cursor: pointer;
	border-radius: 2px;
	border: none;

	.vngage-icon {
		position: absolute;
		top: 0;
		left: 0;
		height: 2px;
		line-height:20px;
		width: 38px;
		text-align:center;
		color:#fff;
	}

	.ps_icon_title {
		position: absolute;
		text-align: center;
		padding: 0 2px;
		line-height: 11px;
		max-height: 33px;
		overflow: hidden;
		top: 23px;
		display: block;
		width: 47px;
		margin-left: -5px;
		font-size:9px;
	}
}

.ps_icon_wide {
	margin: 7px;
	position: relative;
	height: 18px;
	width: 31px;
	cursor: pointer;
	border-radius: 2px;
	border: 1px solid #888;

	.vngage-icon {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		line-height:20px;
		width: 24px;
		text-align:center;
		color:#fff;
	}

	.ps_icon_title {
		margin-top: -1px;
		display: block;
		width: auto;
		position: absolute;
		height: 18px;
		left: 23px;
		z-index: 500;
		white-space: nowrap;
		line-height: 19px;
		padding: 0 6px;
		font-size: 10px;
		border-radius:0 2px 2px 0;
		border: 1px solid #888;
		max-width: 190px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
