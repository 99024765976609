@keyframes bounce {
	from, 20%, 53%, 80%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -20px, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -5px, 0);
	}

	90% {
		transform: translate3d(0,-4px,0);
	}
}

@keyframes bounceagain {
	from, 20%, 53%, 80%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		transform: translate3d(0,-4px,0);
	}
}

@keyframes wiggle {
	from, 20%, 53%, 80%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(10px, 0, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(-20px, 0, 0);
	}

	90% {
		transform: translate3d(4px, 0, 0);
	}
}

@keyframes wiggle-again {
	from, 20%, 53%, 80%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(10px, 0, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(-20px, 0, 0);
	}

	90% {
		transform: translate3d(4px, 0, 0);
	}
}

@keyframes fadeout {
	0% { background-color: rgba(228,244,250,1); }
	100% { background-color: rgba(228,244,250,0); }
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes conversation-end {
	0% {
		opacity: 1;
		height: 80%;
		animation-timing-function: ease-in;
	}
	100% {
		opacity: 0;
		height: 0%;
		animation-timing-function: ease-out;
	}
}

@keyframes fadeinout {
	0% { opacity: 0; }
	50% {opacity: 1;}
	100% { opacity: 0; }
}

@keyframes slideToLeft {
	0% {
		transform: translate3d(230px, 0, 0);
		animation-timing-function: cubic-bezier(.06,.37,.25,1);
		opacity: 0;
	}
	100% {
		transform: translate3d(0, 0, 0);
		animation-timing-function: cubic-bezier(.06,.37,.25,1);
		opacity: 1;
	}
}

@keyframes color-swap {
	0% { background-color: #9a9a9a; }
	30% {background-color: #64bd6b;}
	80% {background-color: #64bd6b;}
	100% { background-color: #9a9a9a; }
}

.fadeIn {
	animation-name: fade-in;
	transform-origin: center bottom;
	animation-duration: 0.5s;
}

.bounce0 {
	animation-name: bounce;
	transform-origin: center bottom;
	animation-duration: 0.5s;
}

.bounce1 {
	animation-name: bounceagain;
	transform-origin: center bottom;
	animation-duration: 0.5s;
}

.wiggle0 {
	animation-name: wiggle;
	transform-origin: center bottom;
	animation-duration: 0.3s;
}

.wiggle1 {
	animation-name: wiggle-again;
	transform-origin: center bottom;
	animation-duration: 0.3s;
}

@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

.user-typing {
	font-size: 11px;
	font-style: italic;
	margin-left: 10px;
}

.user-typing:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4,end) 900ms infinite;
	animation: ellipsis steps(4,end) 900ms infinite;
	content: "\2026"; /* ascii code for the ellipsis character */
	width: 0px;
}