.banner-edit-translation {
	.well {
		margin-top: 6px;
		margin-bottom: 35px;
	}
}

.translation-list {
	clear: both;
	padding-top: 5px;
	padding-bottom: 10px;

	.list-group-item {
		cursor: pointer;

		&.active {
			background: @state-info-bg;
			border-color: #ddd;
			color: #222;
		}
		.default-translation-label {
			font-size: 8px;
			color: #009dff;
			position: absolute;
			top: 4px;
			left: -35px;
			z-index: 2;
		}
	}
}

.list-group {
	.map-item {
		display: flex;
		.map-group {
			flex:1
		}
		.map-link-icon {
			flex:1;
			text-align: center;
		}
		.map-translation {
			flex:1
		}

		.remove-mapping i {
			float: right;
			display: none;
		}
		&:hover {
			.remove-mapping i {display: block;}
		}
	}
}

.translation-add-btn {
	margin-bottom: 8px;
}
.translation-edit-link {
	cursor: pointer;
	margin-left:8px;
	color:#A0A0A0;
	font-size:10px;
	text-decoration:underline;
	vertical-align: middle;
}
.translation-remove-link {
	cursor: pointer;
	margin-left:8px;
	color:#FF5555;
	font-size:10px;
	text-decoration:underline;
	vertical-align: middle;
}

.translation-keys-list {
	padding:10px;
	background: #FFF;
	height: 310px;
	overflow-x: hidden;
	overflow-y: scroll;
	border: 1px solid #ddd;
	border-radius: 4px;

	.translation-keys-list-item {
		background: #FFF;
		label {
			font-weight:bold;
			font-size: 12px;
		}
	}
}

.translation-custom-keys-list {
	padding:8px 8px 0 8px;
	background: #FFF;
	max-height: 310px;
	overflow-x: hidden;
	overflow-y: scroll;
	border: 1px solid #ddd;
	border-radius: 4px;
	list-style-type: none;
	margin-top:10px;

	.translation-keys-list-item {
		background: #FFF;
		label {
			font-weight:bold;
			font-size: 12px;
		}
	}
}


.translation-add-custom-key {
	margin-top: 20px;
	width:100%;

	.errorMessage {
		padding: 5px 0;
		color: #AA0000;
		font-weight: bold;
	}

	input {
		margin-right: 3px;
	}
}

.translation-add-error-key {
	margin-top: 20px;
	width:100%;

	.errorMessage {
		padding: 5px 0;
		color: #AA0000;
		font-weight: bold;
	}
}

.queueReservationOpeningHours {
	.overrideIntervalError span {
		color: red;
		font-weight: bold;
	}
}

.queueReservationOpeningHoursModal {
	select {
		&.form-control {
			width: unset;
			padding: 0.175rem 0.45rem;
			height: auto;
			min-height: unset;
		}
	}

	.errorText {
		color: #B00;
		padding-left: 5px;
		padding-right: 5px;
	}

	.override {
		background-color: #ffc0c0;
	}

	.applyToWeekDays .disabled {
		color: #909090;
	}

	.block {
		padding: 8px 0;
		&.edited {
			background-color: #e9eaee;
		}
		&.inConflict {
			background-color: #fcdcdf;
		}
	}

	.opening-hours-block {
		select {
			color: #000;
			option {
				color: unset;
			}
		}
		.valid-on-workdays-only, .valid-on-workdays-and-holidays, .valid-on-holidays-only {
			color: #000;
			padding-left: 12px;
			&::before{
				font-size: 16px;
				margin-right: 5px;
			}
		}
		.valid-on-workdays-and-holidays {
			color: #B50;
		}
		.valid-on-holidays-only {
			color: #B00;
		}
	}
}
