.meassureWrapper {
	/*width:300px;*/
}

.filterListWrapper {
	/*min-width:630px;*/
	border-bottom: 1px solid #fff;
}

	.filterListWrapper input, .filterListWrapper select, .filterListWrapper textarea {
		width: 118px;
		font-size: 10px;
		margin: 0 2px 1px 0;
		padding: 0 0 0 2px;
	}

.filterWrapper {
	padding: 2px 0;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #fff;
}

	.filterWrapper span {
	}

	.filterWrapper .btn-remove {
		padding: 1px 4px;
		font-size: 15px;
		line-height: 12px;
		vertical-align: middle;
		-webkit-text-shadow: 0 1px 0px #444;
		text-shadow: 0 1px 0px #444;
		height: 16px;
		line-height: 12px;
		margin-left: 2px;
		margin-top: 0;
	}

.filterHeader {
	background-color: #FAF1D4;
	border-bottom: 1px solid #888888;
	border-top: 1px solid #888888;
	;
	padding: 1px 0;
}

	.filterHeader span {
		display: inline-block;
		font-weight: bold;
		width: 120px;
		text-align: center;
	}

.filterAddCondition {
	display: inline-block;
	width: 80px;
	margin-left: 0;
}

	.filterAddCondition select {
		width: 75px;
	}

.filterHeader .filterAddCondition {
	width: 80px;
	text-align: center;
}
