.markdown-input {
	resize: vertical;

	textarea& {
		min-height: 130px;
	}
}

.markdown-label {
	position: relative;
}

.markdown-legend {
	float: right;

	> span {
		cursor: pointer;
		color: #ccc;
		transition: color .2s;

		&:hover,
		&.active {
			color: #333;
		}
	}

	.markdown-table {
		color: #fff;
		background: rgba(0, 0, 0, 0.8);
		border-radius: 5px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.7);
		padding: 10px;
		position: absolute;
		top: -15px;
		right: -15px;
		transform: translateX(100%);

		&.ng-enter, &.ng-leave {
			transition: opacity .3s, transform .2s;
		}

		&.ng-enter,
		&.ng-leave.ng-leave-active {
			opacity: 0;
			transform: scale(0.9) translateX(100%);
			transform-origin: right center;
		}

		&.ng-enter.ng-enter-active,
		&.ng-leave {
			opacity: 1;
			transform: scale(1) translateX(100%);
			transform-origin: right center;
		}

		table {
			th, td {
				padding: 5px 10px;
			}

			th:nth-child(1) {
				min-width: 150px;
			}
			th:nth-child(2) {
				min-width: 240px;
			}

			td {
				border-top: solid 1px rgba(255, 255, 255, 0.2);
				font-size: 12px;
				font-family: 'vngage', sans-serif;
			}
			tr:first-child td {
				border-top-width: 2px;
			}
			td + td,
			th + th {
				border-left: solid 1px rgba(255, 255, 255, 0.2);
			}
		}
	}
}

.markdown-preview {
	display: block;
	background: #f1f1f1;
	border-radius: 4px;
	padding: 12px 20px 8px;
	overflow: hidden;
	word-wrap: break-word;

	label {
		color: #bbb;
	}
}