@textareaHeightDefault: 50px;
@textareaHeightActive: 120px;
@panelAnimationTime: 200ms;
@import "animations";
@import "helpers";
@import "routing/index";
@import "header/index";
@import "visitorMeta/index";
@import "sections/index";
@import "chat/index";
@import "footer/index";
@import "cobrowse/index";
@import "chat/content-upload";
@import "chat/emoji-picker";

.dialog-panel-wrapper {
	display: flex;
	flex-direction: row;
	height: 100%;
	flex: 1;
	overflow: hidden;
	z-index: 0;
	position: relative;
	background: #fff;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAFVBMVEXv7u/f3d/n5uff3N/w7/Dv7e/w7vA7L+vAAAAAK0lEQVR42k3KsQ0AIAwDwXeA7D8yBoSS4grLD0gPEdfQRPY3rN6knd+ymg0KYwBQlnIIcgAAAABJRU5ErkJggg==");
}

.dialog-panel.collapsed .header .notifier {
	position: absolute;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	background: #DC2727;
	right: 260px;
	top: -13px;
	display: block;
	line-height:20px;
	text-align: center;
	color: #fff;
}



.isMaximized {
	padding-left: 300px;
}

.dialog-panel{
	width: 273px;
	height: 80%;
	max-height: 100%;
	margin: 0 5px;
	background: #fff;
	display: flex;
	z-index: 1;
	flex-direction: row;
	flex-shrink: 0;
	border-radius: 10px 10px 0 0;
	//set a subtle shadow, but never change it through animation
	box-shadow: 0 0 4px rgba(0,0,0,0.2);
	//transition: right 1s .2s cubic-bezier(.01,.65,.25,1);
	//transform: translate3d(0,50%,0);
	transform-origin: bottom center;
	//transition-delay: 1s;
	position: absolute;
	bottom: 0;
	//transition: transform @panelAnimationTime ease, height @panelAnimationTime, width @panelAnimationTime;
	.animation(fade-in .8s linear);

	.dialog-container {
		display: flex;
		flex-direction: column;
		box-shadow: none;

		.dialog-action-area {
			height: 100%;
			display: flex;
			flex-direction: column;
			box-shadow: none;
			overflow-y: hidden;
		}
	}

	.dialog-menu {
		display: flex;
		flex-direction: column;
		box-shadow: none;
		min-height: 85px;
		flex-shrink: 0;
	}

	.closing-dialog {
		font-size: 180px;
		text-align: center;
		color: #71ce61;
		border-radius: 10px 10px 0 0;
		bottom: 100px;
		z-index: 20;
	}

	&.remove-out {
		pointer-events: none;
		box-shadow: none;
		animation-name: conversation-end;
		animation-duration: .4s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;
		&.active:after {
			box-shadow: none;
		}
	}

	// Create a hidden pseudo-element to allow for smooth box-shadow animation on :hover
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		opacity: 0;
		box-shadow: 0 5px 15px rgba(0,0,0, 0.4);
		border-radius: 10px 10px 0 0;
		transition: opacity 300ms ease-in-out;
	}


	&:hover {
		z-index: 10;

		// fade in box-shadow end-state
		&::after { opacity:1; }
	}

	&.active{
		z-index: 11;

		.conversation {
			.read-write {
				.input-menu {
					bottom: @textareaHeightActive + 10;
					transition: all .4s cubic-bezier(.01,.65,.25,1);
				}
				form.compose-message-wrapper {
					height: @textareaHeightActive;
					transition: height .4s cubic-bezier(.01,.65,.25,1);
				}
			}
		}

		&::after {
			opacity:1;
			// double box-shadow to indicate active dialog.
			// There's probably a better color for this.
			//box-shadow:
			//0 0 2px rgba(0, 0, 0, .8),
			//0 3px 5px 2px hotpink;
			box-shadow: 0 5px 15px rgba(0,0,0, 0.4);
		}

		&.hovered{
			z-index: 11;
		}
	}
}
