@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@-moz-keyframes rotation {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(359deg);
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@startScale: .8;
@endScale: 1.0;

@-webkit-keyframes enterQueue {
	from {
		-webkit-transform: scale(@startScale);
	}
	to {
		-webkit-transform: scale(@endScale);
	}
}

@-moz-keyframes enterQueue {
	from {
		-moz-transform: scale(@startScale);
	}
	to {
		-moz-transform: scale(@endScale);
	}
}

@keyframes enterQueue {
	from {
		transform: scale(@startScale);
	}
	to {
		transform: scale(@endScale);
	}
}

.queue-container {
	display: flex;
	flex-direction: row;
}

.queue-panel {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	z-index: 9;
	top: 0;
	height: 100%;
	background-color: #fff;
	border-left: 2px solid #999;
}

.queue-toolbar {
	padding: 4px 6px 15px;
	display: flex;
	flex-direction: row;

	.separator {
		width: 210px;
		height: 1px;
		background: #cecece;
		top: 30px;
		left: 50%;
		position: absolute;
		margin-left: -105px;
		opacity: 0;
	}
	.status {
		width: 75%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
	}
	.queue-meta,
	.agents-meta,
	.dialogs-meta {
		display: flex;
		flex: 1;
		align-items: center;
		flex-direction: column;
		i {
			font-size: 18px;
			color: #A4A3A3;
		}
	}
	.queue-count,
	.agents-count,
	.dialogs-count {
		font-size: 9px;
		line-height: 0;
		position: relative;
		left: -10px;
		top: 7px;
		font-family: vngage-bold, sans-serif;
	}
}


.queued-item {
	position: relative;
	background: #fff;
	color: #777;
	.animation(enterQueue .1s linear);

	&:hover {
		transition: all .2s ease;
		cursor: default;

		.summary {
			background-color: #fff;
			border-color: #64BD6B;
			color: #64BD6B;
			cursor: pointer;
			box-shadow: 0 1px 1px 2px rgba(0, 0, 0, .1);
			transition: color .2s ease, box-shadow .2s ease;
			&:hover {
				box-shadow: 0 4px 2px 2px rgba(0, 0, 0, .1);
				transition: box-shadow .2s linear;
			}
			&:before {
				content: '';
				display: inline-block;
				width: 50px;
				height: 50px;
				position: absolute;
				border-radius: 100%;
				left: -2px;
				top: -2px;
			}

			.vngage-icon-queue {
				color: #64BD6B;
				transition: color .2s ease;
			}

			.vngage-icon-queue:before {
				content: '\e86c';
			}

			.duration {
				display: none;
			}
			.action-label {
				display: block;
				text-decoration: underline;
			}
		}
		&.in-transfer,
		&.is-lost {
			.summary {
				border-color: #64BD6B;
				color: #64BD6B;
				.vngage-icon-queue {
					color: #64BD6B;
				}
			}
		}
		.details {
			.separator {
				display: none;
			}
		}
	}

	&.in-transfer {
		.summary {
			border-color: #00B7C9;
			color: #00B7C9;
			.vngage-icon-queue {
				color: #00B7C9;
			}
		}
		.tag {
			color: #00B7C9;
		}
	}
	&.is-lost {
		.summary {
			border-color: #F04E5E;
			color: #F04E5E;
			.vngage-icon-queue {
				color: #F04E5E;
			}
		}
		.tag {
			color: #F04E5E;
		}
	}

	.summary {
		width: 50px;
		height: 50px;
		border: 2px solid #888;
		background: #fff;
		border-radius: 100%;
		text-align: center;
		font-size: 14px;
		position: absolute;
		top: 10px;
		left: -25px;
		z-index: 20;
		transition: all .1s ease;

		.vngage-icon-queue {
			margin-top: 7px;
			margin-bottom: -1px;
			display: block;
			line-height: 0;
			&:before {
				content: '\e871';
				font-size: 20px;
			}
		}
		.duration {
			font-size: 9px;
		}
		.action-label {
			font-size: 9px;
			display: none;
			text-align: center;
		}
	}

	.details {
		height: 70px;
		padding: 18px 7px 0 35px;
		flex: 1;

		.tag {
			font-size: 10px;
			font-family: vngage-bold, sans-serif;
			position: absolute;
			top: 7px;
		}
		.title {
			font-size: 12px;
			margin-bottom: -5px;
		}
		.bookedMeetingTitle {
			font-size: 12px;
			margin-bottom: -5px;
			white-space: nowrap;
		}
		.score {
			font-size: 10px;
			display: inline-block;
			margin-right: 5px;
			vertical-align: bottom;
		}
		.device {
			font-size: 12px;
			display: inline-block;
			margin-right: 5px;
			vertical-align: bottom;

		}
		.lock-visitor {
			transition: all .1s ease;
			border: 1px solid #ccc;
			border-radius: 2px;
			background-color: #fff;
			color: #999;
			font-size: 9px;
			padding: 0 3px 0 0;
			margin-right: 5px;
			letter-spacing: .03em;
			&:hover {
				transition: all .1s ease;
				cursor: pointer;
				color: @brand-primary;
			}
		}
		.meta {
			font-size: 10px;
			display: none;
		}
		.navigation-history {
			display: inline-block;
			ul {
				margin: 0;
				padding: 0;
			}
			li {
				list-style: none;
				display: inline;
				font-size: 6px;
				.history-marker {
					width: 6px;
					height: 6px;
					background: #ccc;
					display: inline-block;
					border-radius: 100%;
					margin-right: 7px;
					position: relative;
					z-index: 1;

					&:after {
						content: '';
						width: 10px;
						height: 2px;
						background: #cecece;
						display: inline-block;
						position: absolute;
						top: .3em;
						left: 6px;
					}
					&.green {
						background: #84b859;
					}
					&.yellow {
						background: #ffcb05;
					}
					&.orange {
						background: orange;
					}
					&.purple {
						background: #ab3192;
					}
					&.blue {
						background: #2f4b9b;
					}
					&.red {
						background: #f04e5e;
					}
					&.brown {
						background: #d2612a;
					}
					&.gray {
						background: #444;
					}
					&.lightgray {
						background: lightgray;
					}
				}
				&:last-child {
					.history-marker {
						&:after {
							display: none;
						}
					}
				}
			}

			.history-url {
				visibility: hidden;
				opacity: 0;
				width: 0;
				position: absolute;
			}
		}

		.separator {
			width: 100%;
			height: 1px;
			background: #cecece;
			left: -15px;
			top: 14px;
			position: relative;
		}

		.loading-meta-spinner {
			border-radius: 100%;
			width: 10px;
			height: 10px;
			margin-top: 9px;
			position: absolute;
			z-index: 2;
			border: 1px solid @brand-success;
			border-bottom-color: transparent;
			.animation(rotation .8s infinite linear);
		}

	}

	&.processing {

		.summary {
			display: none;

			.vngage-icon-queue,
			.action-label,
			.duration {
				display: none;
			}

		}
		.details {
			height: 0;
			opacity: 0;
			transition: opacity .2s ease, height .5s ease;

		}
	}

	&.expanded-navigation-history {
		position: relative;
		z-index: 5;
		.summary {
			background: #fff;
			z-index: 601;
		}
		.details {
			background: #fff;
			.meta {
				display: inline-block;
			}
			.navigation-history {
				min-height: 1px;
				position: absolute;
				width: 100%;
				margin-top: 5px;
				left: -2px;
				padding: 0 0 10px;
				background: #fff;
				display: block;
				border-bottom: 2px solid #999;
				border-left: 2px solid #999;
				box-shadow: 0 15px 20px -15px #666;
				z-index: 600;
				cursor: default;

				li {
					list-style: none;
					display: block;
					padding: 5px 10px 7px;
					font-size: 14px;
					&:hover {
						background: #f1f1f1;
						.history-marker:after {
							background: #f1f1f1;
							box-shadow: 11px 0 8px #f1f1f1;
						}
					}

					.history-marker {
						width: 6px;
						height: 6px;
						background: #999;
						display: inline-block;
						border-radius: 100%;
						top: -3px;
						position: relative;

						&.green {
							background: #84b859;
						}
						&.yellow {
							background: #ffcb05;
						}
						&.orange {
							background: orange;
						}
						&.purple {
							background: #ab3192;
						}
						&.blue {
							background: #2f4b9b;
						}
						&.red {
							background: #f04e5e;
						}
						&.brown {
							background: #d2612a;
						}
						&.gray {
							background: #444;
						}
						&.lightgray {
							background: lightgray;
						}

						&.marked {
							width: 10px;
							height: 10px;
							left: -2px;
							top: 1px;
							&:before {
								left: 4px;
								top: 10px;
								height: 28px;
							}
						}

						&:before {
							content: '';
							width: 2px;
							height: 35px;
							background: #cecece;
							display: inline-block;
							position: absolute;
							left: 2px;
							top: 6px;
							z-index: -1;
						}

						&:after {
							content: '';
							box-shadow: 11px 0 8px #fff;
							width: 10px;
							display: inline-block;
							height: 17px;
							position: absolute;
							background: #fff;
							top: -3px;
							left: 10px;
							z-index: 1;
						}
					}
					&:last-child {
						.history-marker {
							&:before {
								display: none;
							}
						}
					}
					.history-url {
						visibility: visible;
						position: absolute;
						opacity: 1;
						font-size: 12px;
						width: 215px;
						text-indent: -100%;
						text-align: right;
						color: #999;
						overflow: hidden;
						right: 10px;
					}
				}
			}
		}
	}
}

.queue-pin-area {
	height: 100%;
	&.not-pined {
		cursor: url('./img/arrow-left-black.png'), auto;
	}
	&.pined {
		cursor: url('./img/arrow-right-black.png'), auto;
	}
}

.pinnedQueue {
	.queue-panel {
		.queue-toolbar .status,
		.queue-toolbar .separator {
			opacity: 1;
		}
	}
}

.popover.group-status {
	max-width: 250px;
	padding: 0;
	border: 0;
	.arrow {
		border-bottom-color: #cecece;
	}
	.popover-title {
		font-size: inherit;
		padding: 5px;
		background: #cecece;
	}
	.popover-content {
		font-size: 14px;
		.group-status {
			.flipswitch {
				width: 100%;
				text-shadow: none;
			}
			.list-group {
				margin: 5px;
			}
			.list-group-item {
				&:first-child {
					border-radius: 0;
				}
				color: #fff;
				background-color: #444;
				border-color: #222;
			}
		}
	}
}

.group-status {
	&.modal-body {
		padding-bottom: 0;
	}
	.flipswitch {
		text-shadow: none;
		.micro-label {
			font-size: 9px;
			display: inline-block;
			position: relative;
			top: -3px;
			color: #00b7c9;
		}
	}
	.table {
		margin-bottom: 0;
		> thead > tr {
			> th {
				font-size: 12px;
				font-family: vngage-bold, sans-serif;
				text-align: center;
				border-bottom: 1px solid #979797;
				border-top: none;
				&:first-child {
					text-align: left;
				}
			}
		}
		> tbody {
			tr {
				&.away {
					th {
						color: #999;
						.flipswitch .micro-label {
							color: #999;
						}
					}
				}
			}
			th {
				text-align: center;
				vertical-align: middle;
				&:first-child {
					text-align: left;
				}
			}
		}
	}
}

.code-start-modal {
	.code-start-wrapper {
		text-align: center;
		font-size: 38px;
		position: relative;
	}

	.btn-default {
		&:active,
		&:focus {
			background: @btn-default-bg;
			color: @btn-default-color;
		}
	}
	i {
		cursor: pointer;
	}

	&.animate {
		.code-start-number {
			opacity: 0;
		}
	}

	.refresh-code-icon {
		text-align: center;
		color: #999;
		position: absolute;
		width: 50px;
		left: 50%;
		margin-left: -25px;
		top: -3px;
		opacity: 0;
		&:before {
			content: '\e838';
			font-family: "icon";
			font-style: normal;
			font-weight: normal;
			speak: none;
			display: inline-block;
			text-decoration: inherit;
			width: 100%;
			text-align: center;
			font-variant: normal;
			font-size: 28px;
			text-transform: none;
			line-height: 1em;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transition: color .3s ease;
		}
		&.animate-rotate {
			opacity: 1;
		}
		&.animate-rotate:before {
			transform: rotate(360deg);
			color: #666;
			transition: all .4s ease;
		}
	}
	.copy-code-msg {
		color: #444;
		position: absolute;
		opacity: 1;
		visibility: hidden;
		top: 20px;
		width: 100%;
		margin-left: -15px;
		font-size: 18px;

		&.animate-copy {
			opacity: 1;
			visibility: visible;
			color: #f1f1f1;
			top: 10px;
			transition: all .9s ease;
		}
	}

	.refresh-code-label {
		font-style: normal;
		text-align: center;
		font-size: 10px;
		color: #999;
	}
	.copy-code-label {
		font-style: normal;
		text-align: center;
		font-size: 10px;
		color: #999;
	}
}

#compact-navigation-tooltip .tooltip-arrow {
	margin-left: -8px;
	border-top-color: #999;
}

#compact-navigation-tooltip .tooltip-inner {
	margin-right: 10px;
	background: #fff;
	border: 2px solid #999;
	color: #999;
	padding: 15px 15px 5px;
}

#compact-navigation-tooltip.in,
#expanded-navigation-tooltip.in {
	opacity: 1;
}

#expanded-navigation-tooltip .tooltip-arrow {
	margin-left: -8px;
	border-left-color: #999;
}

#expanded-navigation-tooltip .tooltip-inner {
	background: #fff;
	border: 2px solid #999;
	color: #999;
	padding: 15px 15px 5px;
}

@keyframes selectQueue {
	from {
		border-radius: 100%;
		height: 50px;
		width: 50px;
		margin-top: -260px;
		bottom: 0;
		margin-left: 0;
		border-color: #84b859;
	}
	to {
		border-radius: 10px;
		border-color: #eee;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.selected-items {
	z-index: 1;
	position: relative;
}

.selected-item {
	position: absolute;
	//transition: transform .5s cubic-bezier(.1, .81, .49, .88);
	transform-origin: center center;

	&.pending-init {
		transition: transform .2s ease-in;
		bottom: 100%;

		.loading-content-container {
			margin-left: -142px;
			bottom: -260px;
			border-radius: 10px;
		}
	}

	.loader-indication {
		border-radius: 100%;
		width: 50px;
		height: 50px;
		left: 50%;
		top: 50%;
		margin-left: -25px;
		margin-top: -25px;
		position: absolute;
		z-index: 9999;
		border: 2px solid @brand-success;
		border-radius: 100%;
	}

	.loading-content-container {
		border: 1px solid #ddd;
		bottom: 0;
		border-radius: 100%;
		transform-origin: bottom center;
		position: absolute;
		.loader-content {
			.animation(fade-in .8s linear);
		}
		.loader-footer-container {
			.animation(fade-in .8s linear);
		}
	}
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleX(0.8);
		background-color: #ddd;
		box-shadow: 0 0 0 rgba(10, 10, 10, .1);
	}
	20% {
		-webkit-transform: scaleX(1);
		background-color: @brand-success;
		box-shadow: 0 5px 6px rgba(10, 10, 10, .2);
	}
}

@keyframes stretchdelay {
	0%, 40%, 100% {
		background-color: #ddd;
		transform: scaleX(0.8);
		-webkit-transform: scaleX(0.8);
	}
	20% {
		background-color: @brand-success;
		transform: scaleY(1);
		-webkit-transform: scaleX(1);
	}
}
