@font-face {
  font-family: 'icon';
  src: url('../font/icon.eot?95825500');
  src: url('../font/icon.eot?95825500#iefix') format('embedded-opentype'),
       url('../font/icon.woff2?95825500') format('woff2'),
       url('../font/icon.woff?95825500') format('woff'),
       url('../font/icon.ttf?95825500') format('truetype'),
       url('../font/icon.svg?95825500#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon';
    src: url('../font/icon.svg?95825500#icon') format('svg');
  }
}
*/
 
 [class^="vngage-icon-"]:before, [class*=" vngage-icon-"]:before {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.vngage-icon-upload-cloud:before { content: '\21'; } /* '!' */
.vngage-icon-eye:before { content: '\22'; } /* '&quot;' */
.vngage-icon-search:before { content: '\23'; } /* '#' */
.vngage-icon-equalizer:before { content: '\45'; } /* 'E' */
.vngage-icon-globe:before { content: '\62'; } /* 'b' */
.vngage-icon-lightbulb:before { content: '\63'; } /* 'c' */
.vngage-icon-gauge-1:before { content: '\64'; } /* 'd' */
.vngage-icon-target:before { content: '\68'; } /* 'h' */
.vngage-icon-network:before { content: '\69'; } /* 'i' */
.vngage-icon-layers:before { content: '\6a'; } /* 'j' */
.vngage-icon-flash-1:before { content: '\6c'; } /* 'l' */
.vngage-icon-cog-alt:before { content: '\6d'; } /* 'm' */
.vngage-icon-logout:before { content: '\6e'; } /* 'n' */
.vngage-icon-chart-pie:before { content: '\6f'; } /* 'o' */
.vngage-icon-chart-line:before { content: '\70'; } /* 'p' */
.vngage-icon-chart-area:before { content: '\73'; } /* 's' */
.vngage-icon-gauge:before { content: '\e800'; } /* '' */
.vngage-icon-chat:before { content: '\e801'; } /* '' */
.vngage-icon-export:before { content: '\e802'; } /* '' */
.vngage-icon-sitemap:before { content: '\e803'; } /* '' */
.vngage-icon-tasks:before { content: '\e804'; } /* '' */
.vngage-icon-right-circled:before { content: '\e805'; } /* '' */
.vngage-icon-th:before { content: '\e806'; } /* '' */
.vngage-icon-settings:before { content: '\e807'; } /* '' */
.vngage-icon-chart-bar:before { content: '\e808'; } /* '' */
.vngage-icon-wrench:before { content: '\e809'; } /* '' */
.vngage-icon-cloud:before { content: '\e80a'; } /* '' */
.vngage-icon-right-small:before { content: '\e80b'; } /* '' */
.vngage-icon-right-open-big:before { content: '\e80c'; } /* '' */
.vngage-icon-box:before { content: '\e80d'; } /* '' */
.vngage-icon-help-circled:before { content: '\e80e'; } /* '' */
.vngage-icon-forward:before { content: '\e80f'; } /* '' */
.vngage-icon-comment:before { content: '\e810'; } /* '' */
.vngage-icon-edit:before { content: '\e811'; } /* '' */
.vngage-icon-align-left:before { content: '\e812'; } /* '' */
.vngage-icon-forward-1:before { content: '\e813'; } /* '' */
.vngage-icon-up-circled:before { content: '\e814'; } /* '' */
.vngage-icon-download:before { content: '\e815'; } /* '' */
.vngage-icon-torso:before { content: '\e816'; } /* '' */
.vngage-icon-plus:before { content: '\e817'; } /* '' */
.vngage-icon-cancel:before { content: '\e818'; } /* '' */
.vngage-icon-bookmark:before { content: '\e819'; } /* '' */
.vngage-icon-info:before { content: '\e81a'; } /* '' */
.vngage-icon-gift:before { content: '\e81b'; } /* '' */
.vngage-icon-credit-card:before { content: '\e81c'; } /* '' */
.vngage-icon-basket:before { content: '\e81d'; } /* '' */
.vngage-icon-paper-plane:before { content: '\e81e'; } /* '' */
.vngage-icon-flash:before { content: '\e81f'; } /* '' */
.vngage-icon-group:before { content: '\e820'; } /* '' */
.vngage-icon-left-circled:before { content: '\e821'; } /* '' */
.vngage-icon-up-open:before { content: '\e822'; } /* '' */
.vngage-icon-down-open:before { content: '\e823'; } /* '' */
.vngage-icon-left-open-big:before { content: '\e824'; } /* '' */
.vngage-icon-info-circled:before { content: '\e825'; } /* '' */
.vngage-icon-vcard:before { content: '\e826'; } /* '' */
.vngage-icon-sort-up:before { content: '\e827'; } /* '' */
.vngage-icon-sort-alt-up:before { content: '\e828'; } /* '' */
.vngage-icon-sort-alt-down:before { content: '\e829'; } /* '' */
.vngage-icon-down-dir:before { content: '\e82a'; } /* '' */
.vngage-icon-up-dir:before { content: '\e82b'; } /* '' */
.vngage-icon-menu:before { content: '\e82c'; } /* '' */
.vngage-icon-angle-down:before { content: '\e82d'; } /* '' */
.vngage-icon-calendar:before { content: '\e82e'; } /* '' */
.vngage-icon-sort-down:before { content: '\e82f'; } /* '' */
.vngage-icon-left-open:before { content: '\e830'; } /* '' */
.vngage-icon-right-open:before { content: '\e831'; } /* '' */
.vngage-icon-lock:before { content: '\e832'; } /* '' */
.vngage-icon-users:before { content: '\e833'; } /* '' */
.vngage-icon-to-end:before { content: '\e834'; } /* '' */
.vngage-icon-desktop:before { content: '\e835'; } /* '' */
.vngage-icon-tablet:before { content: '\e836'; } /* '' */
.vngage-icon-mobile:before { content: '\e837'; } /* '' */
.vngage-icon-arrows-cw:before { content: '\e838'; } /* '' */
.vngage-icon-heart:before { content: '\e839'; } /* '' */
.vngage-icon-clock:before { content: '\e83a'; } /* '' */
.vngage-icon-chat-1:before { content: '\e83b'; } /* '' */
.vngage-icon-beaker:before { content: '\e83c'; } /* '' */
.vngage-icon-phone:before { content: '\e83d'; } /* '' */
.vngage-icon-ok:before { content: '\e83e'; } /* '' */
.vngage-icon-hourglass:before { content: '\e83f'; } /* '' */
.vngage-icon-agent:before { content: '\e840'; } /* '' */
.vngage-icon-agent-female:before { content: '\e841'; } /* '' */
.vngage-icon-attention:before { content: '\e842'; } /* '' */
.vngage-icon-flag-checkered:before { content: '\e843'; } /* '' */
.vngage-icon-hangup:before { content: '\e844'; } /* '' */
.vngage-icon-stopwatch:before { content: '\e845'; } /* '' */
.vngage-icon-cog:before { content: '\e846'; } /* '' */
.vngage-icon-radar:before { content: '\e847'; } /* '' */
.vngage-icon-screensharing:before { content: '\e848'; } /* '' */
.vngage-icon-videocam:before { content: '\e849'; } /* '' */
.vngage-icon-doc-inv:before { content: '\e84a'; } /* '' */
.vngage-icon-picture:before { content: '\e84b'; } /* '' */
.vngage-icon-link:before { content: '\e84c'; } /* '' */
.vngage-icon-home:before { content: '\e84d'; } /* '' */
.vngage-icon-users-1:before { content: '\e84e'; } /* '' */
.vngage-icon-volume:before { content: '\e84f'; } /* '' */
.vngage-icon-case_management:before { content: '\e850'; } /* '' */
.vngage-icon-chat_2:before { content: '\e851'; } /* '' */
.vngage-icon-history:before { content: '\e852'; } /* '' */
.vngage-icon-pencil:before { content: '\e853'; } /* '' */
.vngage-icon-minus:before { content: '\e854'; } /* '' */
.vngage-icon-doc-add:before { content: '\e855'; } /* '' */
.vngage-icon-star-empty:before { content: '\e856'; } /* '' */
.vngage-icon-star:before { content: '\e857'; } /* '' */
.vngage-icon-user-plus:before { content: '\e858'; } /* '' */
.vngage-icon-user-times:before { content: '\e859'; } /* '' */
.vngage-icon-file-pdf:before { content: '\e85a'; } /* '' */
.vngage-icon-eye-1:before { content: '\e85b'; } /* '' */
.vngage-icon-eye-off:before { content: '\e85c'; } /* '' */
.vngage-icon-file-video:before { content: '\e85d'; } /* '' */
.vngage-icon-file-image:before { content: '\e85e'; } /* '' */
.vngage-icon-users-2:before { content: '\e85f'; } /* '' */
.vngage-icon-dot-3:before { content: '\e860'; } /* '' */
.vngage-icon-block-1:before { content: '\e861'; } /* '' */
.vngage-icon-dot-circled:before { content: '\e862'; } /* '' */
.vngage-icon-video-small:before { content: '\e863'; } /* '' */
.vngage-icon-video-medium:before { content: '\e864'; } /* '' */
.vngage-icon-video-fullscreen:before { content: '\e865'; } /* '' */
.vngage-icon-more:before { content: '\e866'; } /* '' */
.vngage-icon-ico_networkerror:before { content: '\e867'; } /* '' */
.vngage-icon-user:before { content: '\e868'; } /* '' */
.vngage-icon-agent-line:before { content: '\e869'; } /* '' */
.vngage-icon-case-mange-line:before { content: '\e86a'; } /* '' */
.vngage-icon-user-line:before { content: '\e86b'; } /* '' */
.vngage-icon-queue-line:before { content: '\e86c'; } /* '' */
.vngage-icon-chat-line:before { content: '\e86d'; } /* '' */
.vngage-icon-videocam-1:before { content: '\e86e'; } /* '' */
.vngage-icon-cog-line:before { content: '\e86f'; } /* '' */
.vngage-icon-group-line:before { content: '\e870'; } /* '' */
.vngage-icon-multi-chat-line:before { content: '\e871'; } /* '' */
.vngage-icon-highlight:before { content: '\e872'; } /* '' */
.vngage-icon-single-chat:before { content: '\e873'; } /* '' */
.vngage-icon-line-download:before { content: '\e874'; } /* '' */
.vngage-icon-line-expand:before { content: '\e875'; } /* '' */
.vngage-icon-line-image:before { content: '\e876'; } /* '' */
.vngage-icon-line-play:before { content: '\e877'; } /* '' */
.vngage-icon-link-1:before { content: '\e878'; } /* '' */
.vngage-icon-pause:before { content: '\e879'; } /* '' */
.vngage-icon-line-note:before { content: '\e87a'; } /* '' */
.vngage-icon-line-text:before { content: '\e87b'; } /* '' */
.vngage-icon-line-desktop:before { content: '\e87c'; } /* '' */
.vngage-icon-line-video:before { content: '\e87d'; } /* '' */
.vngage-icon-line-fullscreen:before { content: '\e87e'; } /* '' */
.vngage-icon-line-lightning:before { content: '\e87f'; } /* '' */
.vngage-icon-line-phone:before { content: '\e880'; } /* '' */
.vngage-icon-line-restore-screen:before { content: '\e881'; } /* '' */
.vngage-icon-line-sound:before { content: '\e882'; } /* '' */
.vngage-icon-line-tablet:before { content: '\e883'; } /* '' */
.vngage-icon-line-close:before { content: '\e884'; } /* '' */
.vngage-icon-line-list:before { content: '\e885'; } /* '' */
.vngage-icon-line-zoom-out:before { content: '\e886'; } /* '' */
.vngage-icon-line-zoom-in:before { content: '\e887'; } /* '' */
.vngage-icon-stop:before { content: '\e888'; } /* '' */
.vngage-icon-backward:before { content: '\e889'; } /* '' */
.vngage-icon-fast-backward:before { content: '\e88a'; } /* '' */
.vngage-icon-faster-backward:before { content: '\e88b'; } /* '' */
.vngage-icon-forward-2:before { content: '\e88c'; } /* '' */
.vngage-icon-fast-forward:before { content: '\e88d'; } /* '' */
.vngage-icon-faster-forward:before { content: '\e88e'; } /* '' */
.vngage-icon-login:before { content: '\e88f'; } /* '' */
.vngage-icon-logout-1:before { content: '\e890'; } /* '' */
.vngage-icon-attach:before { content: '\e891'; } /* '' */
.vngage-icon-ico_cobrowsing:before { content: '\e892'; } /* '' */
.vngage-icon-email:before { content: '\e893'; } /* '' */
.vngage-icon-facebook-messenger:before { content: '\e894'; } /* '' */
.vngage-icon-sms:before { content: '\e895'; } /* '' */
.vngage-icon-telephone:before { content: '\e896'; } /* '' */
.vngage-icon-whatsapp:before { content: '\e897'; } /* '' */
.vngage-icon-docs:before { content: '\f0c5'; } /* '' */
.vngage-icon-smile:before { content: '\f118'; } /* '' */
.vngage-icon-mic:before { content: '\f130'; } /* '' */
.vngage-icon-mute:before { content: '\f131'; } /* '' */