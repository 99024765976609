form.compose-message-wrapper {
	height: @textareaHeightDefault;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	width: 100%;
	border-top: 1px solid #BFBFBF;
	border-bottom: 1px solid #BFBFBF;
	border-radius: 0;
	flex-shrink: 0;
	transition: height .4s ease-out;
	//overflow: hidden;

	.menu-trigger {
		display: flex;
		flex-direction: column;
		color: #ccc;
		text-align: center;
		width: 40px;
		height: 100%;
		background-color: #fff;
		justify-content: center;
		border: 0;
		border-radius: 0;
		/*border-bottom-left-radius: 5px;
		border-bottom-right-radius: 0;
		border-top-left-radius: 5px;
		border-top-right-radius: 0;*/

		&:after {
			border: 0;
			position: absolute;
			top: 50%;
			margin-top: -.5em;
			left: 50%;
			margin-left: -.5em;

			content: '\e817';
			font-family: "icon";
			font-style: normal;
			font-weight: normal;
			speak: none;
			display: inline-block;
			text-decoration: inherit;
			width: 1em;
			height: 1em;
			margin-right: .2em;
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: 1em;
		}

		&.btn-group.open,
		&.dropdown-toggle {
			box-shadow: none;
		}

		&:hover {
			cursor: pointer;
			color: #BFBFBF;
		}

		&:focus,
		&:active {
			background-color: #BFBFBF;
			color: #fff;
			box-shadow: none;
			border-radius: 0;
			/*border-bottom-left-radius: 3px;
			border-bottom-right-radius: 0;
			border-top-left-radius: 3px;
			border-top-right-radius: 0;*/
		}
	}

	@import "input-menu";

	textarea {
		flex: 1;
		border: 0;
		padding: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		box-shadow: none;
		resize: none;

		&.message-composer[disabled] {
			background-color: @white;
		}
	}

	.show {
		.menu-trigger {
			background: #BFBFBF;
			color: #fff;
		}

		.caret:after {
			content: '\e854';
		}
	}
}