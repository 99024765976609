.case-manager-section-modal {
	display: flex;
	flex-direction: column;

	.modal-header {

		.subtitle {
			font-size: 12px;
			color: #999;
		}
	}

	.h4 {
		font-family: 'vngage-bold'
	}

	button.close {
		opacity: 1;
	}

	.case-type-area,
	.closure-area,
	.case-close-area {
		display: flex;
		flex-direction: column;
		padding-bottom: 15px;

		.area-head,
		.area-subhead {
			display: flex;
			overflow: hidden;

			h5 {
				display: table;
				padding-right: 15px;
				font-family: 'vngage-bold', sans-serif;

			}

			.inline-divider {
				top: 9px;
			}
		}

		.area-subhead {
			h5 {
				font-style: italic;
				margin-bottom: 0;
			}
		}

		.form-description {
			margin-bottom: 10px;
			display: inline-block;
			font-size: 12px;
		}
	}

	.case-type-area {
		.form-horizontal {
			margin-top: 10px;

			.form-group {
				margin-bottom: 15px;

				.form-control {
					&:focus {
						border-color: #ccc;
						-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
						box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
					}
				}

				.form-label {
				}
			}

			.btn-toolbar {
				text-align: right;
				float: right;

				.btn-link {
					color: #00b7c9;
				}

				.btn-success {
					padding: 6px 12px;

					&.indicate-sent {
						animation-name: color-swap;
						transform-origin: center bottom;
						animation-duration: 2s;
					}
				}
			}
		}
	}

	.closure-area {
		.form-wrapper {
			padding: 10px 10px;

			.form-group {
				.form-check {
					color: #444;
					margin-bottom: 5px;
				}
			}
		}

		.form-group {
			margin-bottom: 0;
		}
	}

	.case-close-area {
		.area-content {
			padding: 5px 40px;
			display: flex;
			justify-content: space-between;

			.finish-area {
				display: flex;
				flex-direction: column;

				.btn-default {
					margin-left: 26px;
					color: #fff;
					background-color: #71ce61;
					border-color: #71ce61;

					&:not(:disabled):not(.disabled):hover {
						background: #9a9a9a;
						color: #fff;
					}
				}

			}

			.form-group {
				.form-check {
					color: #444;
					margin-bottom: 5px;
				}
			}

			.save-label {
				font-size: 12px;
				color: #cbcbcb;
				margin: 5px 0 0;
				font-style: italic;
				//position: absolute;
				right: 0;
			}

			.case-options {
				margin-bottom: 0;
			}
		}
	}
}