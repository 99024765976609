.flex-container {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
}
.flex1 {
	-webkit-flex: 1;
	flex: 1;
}
.flex2 {
	-webkit-flex: 2;
	flex: 2;
}
.flex3 {
	-webkit-flex: 3;
	flex: 3;
}

.workarea {
	display: flex;
	flex-direction: row;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	margin: 0;
	bottom: 0;

	.dialog-container {
		width: 380px;
		z-index: 1;
		display: flex;
		flex-direction: column;

		.vngage-icon-cog {
			font-size: 17px;
			margin-top: 0;
			color: #cecece;
			float: right;
			display: none;
		}
	}
}


.btn-invite-participant {
	.vngage-icon-torso {
		margin:0 -3px;
		font-size:11px;
	}
	.vngage-plus {
		line-height:0;
		font-weight:bold;
		vertical-align:super;
	}
}

ul.meta-participants { padding: 0; }

//ul.meta-participants {
//	font-size: 12px;
//	list-style: none;
//	margin: 0 0 5px;
//	//pointer-events: none;
//	padding: 0;
//
//	> li {
//		margin-right: 5px;
//		display: inline-block;
//
//		.author-title {
//			font-family: 'vngage-semibold';
//		}
//
//		.author-name {
//			font-size: 9px;
//		}
//
//		i {
//			display: inline-block;
//			color: #999;
//			font-size: 10px;
//			vertical-align: text-bottom;
//
//			&.status-lost {
//				color: orange;
//			}
//			&.status-ok {
//				color: #00b7c9;
//			}
//			&.status-dead {
//				color: #f04e5e;
//			}
//			&.status-terminate {
//				color: #f04e5e;
//			}
//		}
//	}
//}
.participant {

	.author-title {
		font-family: 'vngage-semibold';
	}

	.author-name {
		font-size: 9px;
	}

	i {
		display: inline-block;
		color: #999;
		font-size: 10px;
		vertical-align: text-bottom;

		&.status-lost {
			color: orange;
		}
		&.status-ok {
			color: #00b7c9;
		}
		&.status-dead {
			color: #f04e5e;
		}
		&.status-terminate {
			color: #f04e5e;
		}
	}
}

.dialog-container {
	box-shadow: 5px 0px 9px -5px #000;
}

.dialog-meta {
	flex: 0 1 auto;
	min-height: 113px;
	font-size: 12px;
	padding: 0 15px;
	z-index: 1;

	.flex1 { padding-bottom: 5px; }

	.indicate-overflow-top {
		box-shadow: 0px 4px 8px -2px #cecece;
		height: 10px;
		margin: -10px -15px;
		opacity: .4;
	}

	.caret {
		color: #cecece;
		margin-left: 5px;
	}
	.open > .dropdown-menu {
		display: inline-table;
	}
	.dropdown {
		display: inline;
		cursor: pointer;
	}
	.dropdown-menu {
		border-radius: 0;
		padding: 10px;
		border-color: #999;
		top: 25px;
		min-width: 180px;
		box-shadow: none;

		&:before {
			content: '';
			border-width: 10px;
			border-style: solid;
			border-color: transparent transparent #fff transparent;
			position: absolute;
			top: -20px;
			left: 41px;
		}

		&:after {
			content: '';
			border-width: 11px;
			border-style: solid;
			border-color: transparent transparent #555 transparent;
			position: absolute;
			top: -22px;
			z-index: -1;
			left: 40px;
		}
	}

	.icon-large {
		font-size: 36px;
		text-align: center;
		position: relative;
		top: -6px;
		width: 65px;
		margin-right: 5px;

		span {
			position: absolute;
			font-size: 12px;
			color: white;
			left: 42%;
			bottom: 10px;
		}
	}

	.meta-title {
		margin-bottom: 5px;
		font-family: 'vngage';
		cursor: default;
	}

	.dropdown{
		ul.meta-participants {
			padding: 10px;
			li {
				display: block;
			}
		}
	}

	.meta-actions {
		margin: 0 -15px;
		padding: 8px 15px 8px;
		border-top: 3px solid #f1f1f1;
		background: #fff;

		a {
			background: #f1f1f1;
			padding: 4px 6px;
			color: #00b7c9;
			display: inline-block;
			border-radius: 4px;
			font-size: 11px;
			font-family: vngage-semibold;
			margin: 0 2px;
			&.active {
				text-decoration: none;
				cursor: pointer;
				background: #00b7c9;
				color: #fff;
			}

			&:hover {
				text-decoration: none;
				cursor: pointer;
				background: #00b7c9;
				color: #fff;
			}
			&:active,
			&:focus {
				background: #808080;
				color: #f1f1f1;
			}
		}
	}
}

.dialog-list-container {
	flex: 2 2 auto;
	overflow-y: scroll;
	min-height: 0;
	padding: 20px 15px 0;
}

.dialog-list {
	padding: 0;
	margin: 0;

	> li {
		list-style: none;
		margin: 10px 0;
		padding: 0 10px 0 6px;
		word-wrap: break-word;
		border-radius: 4px;
		border-left:4px solid transparent;

		&.unread {border-left-color:#1b9f2a;}
		&.message-guest {
			padding-bottom: 10px;

			+ .message-guest {
				margin-top: -15px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}


		&.message-agent {
			padding-top: 10px;
			padding-bottom: 10px;
			margin: 10px 0 15px;
			background-color: rgb(241, 241, 241);
			.message-note,
			.message-navigation {
				padding: 5px 10px;
			}
			+ .message-agent {
				margin-top: -15px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}

		&.message-visitor {
			padding-top: 10px;
			padding-bottom: 10px;
			background-color: #e5ebf9;

			margin: 10px 0 15px;
			.message-note,
			.message-navigation {
				padding: 0 10px;
			}
			+ .message-visitor {
				margin-top: -15px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}

		.flex-container {
			flex-wrap: wrap;
		}

		p, h5 {
			font-size: 13px;
			a {
				text-decoration: underline;
			}
		}

		h5.message-author-name {
			display: inline-block;
			margin: 0 0 2px;
			flex: 1 1 auto;
			cursor: default;

			.author-name {
				font-size: 10px;
				font-family: 'vngage';
				color: #999;
				float: right;
				margin-right: 3px;
				margin-top: 3px;
			}
		}

		span.message-createdAt {
			flex: none;
			font-size: 10px;
			color: #999;
			margin-top: 2px;
			&:hover {
				cursor: pointer;
			}
		}

		.message-text, .message-navigation, .message-note {
			flex: 1 1 100%;
			p {
				margin: 0;
			}
			p + p {
				margin-top: 1em;
			}
		}

		.message-text {
			a {
				text-decoration: underline;
			}
			i {
				font-style: normal;

				&.photo:before {
					font-family: 'icon';
					content: '\e84b';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.video:before {
					font-family: 'icon';
					content: '\e849';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.pdf:before {
					font-family: 'icon';
					content: '\e84a';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.link:before {
					font-family: 'icon';
					content: '\e84c';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
			}
		}

		.message-note {
			color: #cecece;
			font-size: 11px;
			font-style: italic;
			text-align: center;
			margin: -10px 0;
		}
		.message-navigation {
			color: #cecece;
			font-size: 11px;
			font-style: italic;
			text-align: center;
			margin: 0;
		}

		.message-meta {
			width: 100%;
			border-left: 2px solid #cecece;
			padding-left: 5px;
			margin: 0;

			&.participantJoined {
				color: #b5d39c;
				border-color: #b5d39c;
				div { display: inline; }
			}
			&.participantLeft {
				color: #e39f7e;
				border-color: #e39f7e;
				div { display: inline; }
			}
		}

		iframe {
			max-width: 100%;
			height: auto;
		}
	}
}

.dialog-compose {
	flex: 0 1 auto;
	min-height: 95px;
	padding: 0 15px;
	border-top: 1px solid #cecece;

	textarea {
		border: 0;
		box-shadow: none;
		height: 90px;
		&:active,
		&:focus {
			box-shadow: none;
		}
	}
	textarea::-webkit-input-placeholder { font-style: italic; }
	textarea:-moz-placeholder { font-style: italic; }
	textarea::-moz-input-placeholder { font-style: italic; }
}

.dialog-tools {
	height: 30px;
	border-top: 1px solid #cecece;
	padding: 0 15px;

	[class^="vngage-icon-"]:before, [class*=" vngage-icon-"]:before {
		float: right;
		margin-top: 5px;
		margin-right: 10px;
		display: none;
	}
}

.participants-writing {
	float: left;

	span.participant-writing {
		font-size: 10px;
		margin-right: 5px;
		color: #999;

		.fadeInOut-enter {
			opacity: 0.01;
			transition: opacity 5s ease-in;
		}

		.fadeInOut-enter.fadeInOut-enter-active {
			opacity: 1;
			color: red;
		}

		.fadeInOut-leave {
			opacity: 1;
			transition: opacity .5s ease-in;
		}

		.fadeInOut-leave.fadeInOut-leave-active {
			opacity: 0.01;
		}

	}
}
.vg-legacy-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
	min-height:100px;
}

.visual-guidance-container {
	display: flex;
	flex: 1;
	flex-direction: column;

	.vg-iframe-wrap {
		border: 0;
		flex: 1;
		position: relative;
		z-index: 0;
		box-sizing:border-box;
		overflow-y:hidden;
		background:#efefef;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADJJREFUeNosibERADAIAoVzC3dx/4GkJhQpgHseu2v77qqqJQEIkOwk38x8k4lMPwEGABooD569Ac8UAAAAAElFTkSuQmCC");
		&.display {
			.restricted-backdrop {
				opacity: 1;
				visibility: visible;
				transition: opacity .3s ease;
			}
		}

		.restricted-backdrop {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 999;
			background: rgba(0,0,0,.8);
			transition: opacity .3s ease;

			.restricted-container {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 400px;
				margin-top: -150px;
				margin-left: -200px;
				text-align: center;
				color: #fff;
				cursor: default;
				i {
					font-size: 100px;
					color: #444;
					display: inline-block;
					margin-bottom: -30px;
				}
				a {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}

		&:after {
			//make sure the bg-icon doesnt conflict with the page
			pointer-events: none;
			content: '\e848';
			color: #222;
			font-family: 'icon';
			text-align: center;
			font-size: 200px;
			position: absolute;
			width: 200px;
			height: 200px;
			top: 50%;
			left: 50%;
			margin: -200px 0 0 -100px;
		}
	}

	.vg-iframe {
		display: flex;
		//width: 100%;
		//height: 100%;
		position: absolute;
		z-index: 1;
		border: none;
		-webkit-transition: all 400ms ease-in-out;
		-webkit-transform-origin: top left;
		-moz-transition: all 400ms ease-in-out;
		-o-transition: all 400ms ease-in-out;
		transition: all 400ms ease-in-out;
		-moz-transform-origin: top left;
		-ms-transform-origin: top left;
		-o-transform-origin: top left;
		transform-origin: top left;
	}

	//used in desktopLegacy
	.vg-legacy-iframe-wrap {
		display: flex;
		flex: 1;
		box-sizing:border-box;
		overflow-y:hidden;
		overflow-x:auto;
		background:#efefef;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADJJREFUeNosibERADAIAoVzC3dx/4GkJhQpgHseu2v77qqqJQEIkOwk38x8k4lMPwEGABooD569Ac8UAAAAAElFTkSuQmCC");
		width: 100%;
		//height: 100%;
		//position: absolute;
		position:relative;
		z-index: 0;
		border: none;
		-webkit-transition: all 400ms ease-in-out;
		transition: all 400ms ease-in-out;
		-webkit-transform-origin: top left;
		-ms-transform-origin: top left;
		transform-origin: top left;
		//box-shadow:0 0 10px #000;
	}
	.vg-legacy-iframe {
		display: flex;
		flex:1;
		//width: 100%;
		//height: 100%;
		position: absolute;
		z-index: 1;
		border: none;
		-webkit-transition: all 400ms ease-in-out;
		transition: all 400ms ease-in-out;
		-webkit-transform-origin: top left;
		-ms-transform-origin: top left;
		transform-origin: top left;
	}

	.vg-metabar {
		background: #222;
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 35px;
		color: rgba(255,255,255,0.9);
		padding: 5px 20px;

		.vg-metabar-inner {
			display: flex;
			flex: 1;
			font-size: 13px;

			&.paused {
				.vg-meta-info {
					color: #f04e5e;
				}
			}
			&.passive {
				.vg-meta-info {
					color: #f04e5e;
					.vg-metatitle { font-family: 'vngage-semibold'; }
				}
			}
			&.active {
				.vg-meta-info {
					color: #00b7c9;
				}
			}
		}
	}

	.vg-meta-info {
		flex: 1;
		display: block;
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.vg-metatitle {
			display: inline-block;
			flex: 0 1 auto;
			margin-right: 5px;
			cursor: default;
		}

		.vg-status {
			display: inline-block;
			flex: 0 1 auto;
			margin-right: 20px;
		}
	}

	.vg-actions {
		display: flex;
		align-self: flex-end;
		font-size:83%;
		.vngage-icon-cog {
			font-size: 16px;
			display: flex;
		}
		button {
			color: #cecece;
			background: #222;
			display: inline;
			border: none;
			position: absolute;
			top: 9px;
			right: 115px;
		}
		.flipswitch {
			.flipswitch-label {
				text-shadow: none;
				color: #cecece;
			}
		}
		input[type="checkbox"].switch + span:before {
			height: 1.6em;
		}
	}
}

.conversation-input {
	flex: 1 1 auto;
	min-height: 75px;
}

.message-composer {
	padding: 5px 0;
}


.dialog-wrapper {
	&:after {
		content: '\A';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.6);
		opacity: 0;
		visibility: hidden;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		z-index: 1;
	}

	&.connection-UP {
		&:after {
			visibility: hidden;
			opacity: 0;
		}
	}

	&.connection-DOWN {
		&:after {
			visibility: visible;
			opacity: 1;
		}
	}
}

.duration-display {
	display: inline;
	margin-right: 10px;

	i {
		font-size: 10px;
		margin-left: -2px;
		margin-right: 2px;
		vertical-align: text-bottom;
	}
}

.react-container {
	background: #fcfcfc;

	.lobby-container {
		display: flex;
		width: 420px;
		position: absolute;
		left: 50%;
		top: 100px;
		margin-left: -210px;
		min-height: 200px;

		.left-area {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1;
			background: #eaeceb;

			i {
				font-size: 40px;
				text-align: center;
				width: 100%;
				margin-top: 3px;
			}
		}
		.content-area {
			flex: 5;
			padding: 10px 20px;

			.status-message-error {
				color: #f04e5e;
			}
			.status-message {
				color: #00b7c9;
			}
		}
	}
}

/* Legacy */
.desktopLegacy  {
	.dialog-list-container {
		overflow-y: inherit;
		padding: 0;
	}

	.psPanel_content ul {
		border-bottom: none;

		&.meta-participants {
			margin: 0;
			line-height: 12px;

			li i {
				vertical-align: text-top;
			}
		}
	}

	.dialog-list > li {
		border-radius: 0;

		ul, ol {
			padding: 0 0 0 2em;
		}

		blockquote {
			padding: 0 0 0 1em;
			font-size:inherit;
		}

		&.message-visitor,
		&.message-agent,
		&.message-guest,
		&.meta-guest,
		&.meta-visitor {
			margin: 0;
			padding: 5px 10px 5px 6px;
		}

		span.message-createdAt {
			margin-top: 1px;
		}

		&.message-visitor {
			background-color: #e5ebf9;
			h5 .author-title {
				font-family: 'arial', sans-serif;
				font-weight: bold;
				color: #666;
			}
			.message-meta {
				width: 100%;
				border-left: 2px solid #cecece;
				padding-left: 5px;

				&.participantLeft {
					color: #e39f7e;
					border-color: #e39f7e;
					div { display: inline; }
					span { color: #e39f7e; }
				}
				&.participantJoined {
					color: #b5d39c;
					border-color: #b5d39c;
					div { display: inline; }
					span { color: #b5d39c; }
				}

				.author-title,
				.author-name{
					font-family: 'vngage-semibold';
					font-size: 11px;
				}

				span { font-style: italic; }
			}

			+ .message-visitor {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}

				.message-meta {
					margin-top: 5px;
				}
			}
		}

		&.message-agent {
			background-color: @white;
			h5 .author-title {
				font-family: 'arial', sans-serif;
				font-weight: bold;
				color: #666;
			}
			.message-meta {
				width: 100%;
				border-left: 2px solid #cecece;
				padding-left: 5px;

				.author-title,
				.author-name{
					font-family: 'vngage-semibold';
					font-size: 11px;
				}

				span { font-style: italic; }
			}
			+ .message-agent {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}

		&.message-guest {
			background-color: rgb(241, 241, 241);
			h5 .author-title {
				font-family: 'arial', sans-serif;
				font-weight: bold;
				color: #666;
			}
			.message-meta {
				width: 100%;
				border-left: 2px solid #cecece;
				padding-left: 5px;

				&.participantLeft {
					color: #e39f7e;
					border-color: #e39f7e;
					div { display: inline; }
					span { color: #e39f7e; }
				}
				&.participantJoined {
					color: #b5d39c;
					border-color: #b5d39c;
					div { display: inline; }
					span { color: #b5d39c; }
				}

				.author-title,
				.author-name{
					font-family: 'vngage-semibold';
					font-size: 11px;
				}

				span { font-style: italic; }
			}

			+ .message-guest {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}

				.message-meta {
					margin-top: 5px;
				}
			}
		}
		p, h5 { font-size: 11px; }

		.message-text {
			a {
				text-decoration: underline;
			}
			i {
				font-style: normal;

				&.photo:before {
					font-family: 'icon';
					content: '\e84b';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.video:before {
					font-family: 'icon';
					content: '\e849';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.pdf:before {
					font-family: 'icon';
					content: '\e84a';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.link:before {
					font-family: 'icon';
					content: '\e84c';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
			}
		}

		.message-note {
			color: #999;
			width: 100%;
			border-left: 2px solid #cecece;
			padding-left: 5px;
			text-align: left;
			margin: 0;

			.note-meta {
				font-family: 'vngage-semibold';
				font-size: 11px;
				.author-title {
					display: inline-block;
					margin-right: 5px;
				}
			}
			span { font-style: italic; }
		}
	}

	.dialog-tools {
		height: 15px;
		border:none;
		background:#fff;
		line-height:15px;
		padding: 0 10px;

		.participants-writing {
			float:none;
			line-height:15px;
			vertical-align: middle;

			span.participant-writing{
				vertical-align: sub;
				line-height:15px;
				vertical-align: middle;
			}
		}
	}
}

.popover {
	.dialog-list-container {
		overflow-y: inherit;
		padding: 0;
	}

	.dialog-list > li {
		border-radius: 0;

		&.message-visitor,
		&.message-agent,
		&.message-guest {
			margin: 5px 0;
			padding: 5px 10px;
		}

		span.message-createdAt {
			margin-top: 1px;
		}

		&.message-visitor {
			+ .message-visitor {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}

		&.message-agent {
			+ .message-agent {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}

		&.message-guest {
			+ .message-guest {
				margin-top: -5px;
				padding-top: 0;

				h5 {
					display: none;
				}

				span.message-createdAt {
					flex: 1 1 auto;
					text-align: right;
				}
			}
		}
		p, h5 { font-size: 11px; }

		.message-text {
			a {
				text-decoration: underline;
			}
			i {
				font-style: normal;

				&.showImage:before {
					font-family: 'icon';
					content: '\e84b';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.showVideoPlayer:before {
					font-family: 'icon';
					content: '\e849';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
				&.showPDF:before {
					font-family: 'icon';
					content: '\e84a';
					display: inline-block;
					margin-right: 10px;
					font-size: 16px;
				}
			}
		}

		.message-note {
			color: #999;
			width: 100%;
			border-left: 2px solid #cecece;
			padding-left: 5px;
			text-align: left;
			margin: 0;

			.note-meta {
				font-family: 'vngage-semibold';
				font-size: 11px;
				.author-title {
					display: inline-block;
					margin-right: 5px;
				}
			}

			span { font-style: italic; }
		}

	}

	.dialog-tools {
		height: 20px;
		padding: 0 5px;
		.participants-writing span.participant-writing{
			vertical-align: sub;
		}
	}
}

.participantAuthMenu {
	display: none;

	hr {
		margin: 3px auto;
	}

	label {
		font-size: 8px;
		color: gray;
	}

	button {
		font-size: 10px;
	}

	&.visible {
		display: inline-block;
		background-color: white;
		padding: 8px;
		border: 1px solid #DADADA;
	}
}
.participant .btn,
.participant.open .btn {
	background-color:#fff;
	text-shadow: 0 1px 0 #fff;
	border-color: #ccc;
	color:#666;
}
.btn-toolbar span.participant {
	border: 1px solid transparent;
	font-size:12px;
	padding:1px 3px;
	display:inline-block;
}
.dropdown-menu > li > a { cursor:pointer; }
.dropdown-menu .divider {margin:4px 0;}


.dropdown-item .flipswitch {
	display: block;
	padding:1px 10px 1px 20px;
	font-size:12px;
	.flipswitch-label {font-weight:normal; float:left;}
	.switch {float:right;}


	label {
		display:inline;
		-webkit-user-select:none;
		-moz-user-select:none;
		user-select:none;
		float: right;

		input[type="checkbox"]:not(:checked),
		input[type="checkbox"]:checked { /* :checked here acting as a filter for older browsers */
			position: absolute;
			opacity: 0;
			cursor: pointer;
			pointer-events: none;
		}

		input[type="checkbox"] + i { color: #ccc; }
		input[type="checkbox"]:checked + i { color: #00b7c9; }

		i {
			font-size: 16px;
			display: inline-block;
			margin: 3px 5px 0 0;
			&:hover {
				cursor: pointer;
			}
		}

	}

}

#videowrap {
	width: 100%;
	//height: 220px;
	position: relative;
	-webkit-transition: all 400ms ease-out;
	-moz-transition: all 400ms ease-out;
	-ms-transition: all 400ms ease-out;
	-o-transition: all 400ms ease-out;
	transition: all 400ms ease-out;

	.video-toolbar {
		label {
			margin-top: -2px;
		}
	}
}

#videowrap.videolarge {
	position: absolute;
	width: 480px;
	height: 360px;
}

#videowrap.videolarge.videolargeleft {
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	transform: translateX(-100%);
}

#videowrap.videolarge.videolargeright {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
}

/*
 *  These styles define the appearance of the default error dialog box.
 */
#easyrtcErrorDialog {
	background-color: #ffe0e0;

	position:fixed;
	right: 10px;
	top:20px;
	z-index: 30;
	opacity: 0.95;
	padding: 0.5em;
	border-radius:10px;
	border-color: red;
	border-style: solid;
	border-width: 1px;
	-webkit-box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.9);
	box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.9);
}

.easyrtcErrorDialog_title {
	position:static;
	text-align:center;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 0.5em;
	clear:both;
}

#easyrtcErrorDialog_body{
	position:static;
	height:150px;
	overflow-y:auto;
}

.easyrtcErrorDialog_element {
	position:static;
	font-style: italic;
	font-size: 12px;
	width:300px;
	margin-bottom: 0.5em;
	clear: both;
	float:left;
}

.easyrtcErrorDialog_okayButton {
	position:static;
	clear:both;
	float:right;
}

.easyrtcMirror {
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
}

.easyrtc_closeButton {
	z-index: 2;
	position: absolute;
	width: 40px;
	height:40px;
	right: 0px;
	top: 0px;
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><g stroke="#b0b0b0" stroke-width="7" ><g>		<path  d="M76.03,79.854c-0.979,0-1.958-0.373-2.704-1.12L21.184,26.592c-1.494-1.494-1.494-3.915,0-5.409c1.494-1.493,3.915-1.493,5.409,0l52.143,52.142c1.494,1.494,1.494,3.915,0,5.409C77.988,79.481,77.01,79.854,76.03,79.854z"/></g><g><path d="M23.888,79.854c-0.979,0-1.958-0.373-2.704-1.12c-1.494-1.494-1.494-3.915,0-5.409l52.142-52.142c1.493-1.493,3.915-1.493,5.409,0c1.494,1.494,1.494,3.915,0,5.409L26.593,78.734C25.846,79.481,24.867,79.854,23.888,79.854z"/></g></g><g fill="#000000"><g><path  d="M76.03,79.854c-0.979,0-1.958-0.373-2.704-1.12L21.184,26.592c-1.494-1.494-1.494-3.915,0-5.409c1.494-1.493,3.915-1.493,5.409,0l52.143,52.142c1.494,1.494,1.494,3.915,0,5.409C77.988,79.481,77.01,79.854,76.03,79.854z"/></g><g><path d="M23.888,79.854c-0.979,0-1.958-0.373-2.704-1.12c-1.494-1.494-1.494-3.915,0-5.409l52.142-52.142c1.493-1.493,3.915-1.493,5.409,0c1.494,1.494,1.494,3.915,0,5.409L26.593,78.734C25.846,79.481,24.867,79.854,23.888,79.854z"/></g></g></svg>');
	opacity: 0.3;
}

.easyrtc_closeButton:hover {
	opacity: 1;
}



.transit {
	transition: all 400ms ease-out;
	-moz-transition: all 400ms ease-out;
	-webkit-transition: all 400ms ease-out;
	-o-transition: all 400ms ease-out;
	/*transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, transform 1s;*/
	/*-moz-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, -moz-transform 1s;*/
	/*-webkit-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, -webkit-transform 1s;*/
	/*-o-transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s,-o-transform 1s;*/
}

.hidden {
	background-color: transparent;
	border-color: 2px solid blue;
}

.boxCommon {
	position:absolute;
	width: 100%;
}

.closeButton {
	display: none; /* hide the easyApp's close button because we have our own */
}

.thumbCommon {
	z-index:2;
	/*box-shadow: 5px 5px 5px #000000;*/
}
