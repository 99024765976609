//Temporary tooltips!
.tool-tip,
.tool-tip:before,
.tool-tip:after,
.tool-tip:focus,
.tool-tip:active:after {
	outline:none;
}


.tool-tip{
	position:relative;
}
.tool-tip:hover{ text-decoration:none; }

.tool-tip:hover:before,
.tool-tip:hover:after {
	visibility: visible;
	opacity: 1;
}

//ignore focus state for now
//.tool-tip:focus:before,
//.tool-tip:focus:after,
.tool-tip:hover:before,
.tool-tip:hover:after {
	-webkit-transition: 400ms 400ms ease;
		 -moz-transition: 400ms 400ms ease;
					transition: 400ms 400ms ease;
}
.tool-tip:before,
.tool-tip:after {
	position: absolute;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	visibility: hidden;
	opacity: 0;
	z-index: 1000000;
	pointer-events: none;
	-webkit-transition: 0ms 0ms ease;
	   -moz-transition: 0ms 0ms ease;
	        transition: 0ms 0ms ease;
}

.tool-tip:after {
	background: #111;
	background: rgba(0,0,0,.8);
	border-radius: 5px;
	top: 24px;
	color: @white;
	content: attr(data-tip);
	display: block;
	left: 0;
	white-space: nowrap;
	text-shadow: 0 -1px 0 black;
	padding: 8px 10px;
	font-size: 12px;
	font-weight:normal;
	line-height: 12px;
}

.tool-tip:before {
	margin-top: 11px;
	left: 10px;
	content: '';
	position: absolute;
	background: transparent;
	border: 7px solid transparent;
	border-bottom-color: rgba(0,0,0,.8);
	z-index: 1000001;
}

// Top
.tool-tip.tool-tip-top-center:after {
	top: -32px;
	left: -50%;
	right:auto;
}
.tool-tip.tool-tip-top-left:after {
	top: -32px;
	left:0;
	right:auto;
}
.tool-tip.tool-tip-top-right:after {
	top: -32px;
	left:auto;
	right:0;
}

.tool-tip.tool-tip-top-center:before {
	left: 50%;
	margin-left: -10px;
	right:auto;
}
.tool-tip.tool-tip-top-left:before {
	left: 10px;
	right: auto;
}
.tool-tip.tool-tip-top-right:before {
	right: 10px;
	left: auto;
}

.tool-tip.tool-tip-top-center:before,
.tool-tip.tool-tip-top-left:before,
.tool-tip.tool-tip-top-right:before {
	border-top-color: rgba(0,0,0,.8);
	border-bottom-color: transparent;
	margin-top: -5px;
}

// Bottom
.tool-tip.tool-tip-bottom-center:after {
	left: -50%;
	right:auto;
}
.tool-tip.tool-tip-bottom-left:after {
	left:0;
	right:auto;
}
.tool-tip.tool-tip-bottom-right:after {
	left:auto;
	right:0;
}

.tool-tip.tool-tip-bottom-center:before {
	left: 50%;
	bottom: 0;
	margin-left: -10px;
	right: auto;
}
.tool-tip.tool-tip-bottom-left:before {
	left: 10px;
	right: auto;
}
.tool-tip.tool-tip-bottom-right:before {
	right: 10px;
	left: auto;
}

.tool-tip.tool-tip-bottom-center:before,
.tool-tip.tool-tip-bottom-left:before,
.tool-tip.tool-tip-bottom-right:before {
	border-bottom-color: rgba(0,0,0,.8);
	bottom: 0;
	margin-bottom: -6px;
}
