form.compose-note-wrapper {
	height: @textareaHeightActive;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	width: 100%;
	border-top: 1px solid #efc86c;
	border-bottom: 1px solid #efc86c;
	border-radius: 0;
	flex-shrink: 0;
	transition: height .4s ease-out;
	textarea {
		flex: 1;
		border: 0;
		padding: 5px;
		background: #fff7e3;
		border-radius: 0;
		resize: none;
		box-shadow: none;
	}
}