
.psPlugin_warning {
	position: absolute;
	z-index: 1002;
	display: none;
	padding: 1px;
	background-color: #c33;
	border: 1px solid #a22;
	font-family: Helvetica, Arial, Sans-Serif;
	font-size:11px;
	/*border: 1px solid rgba(0, 0, 0, 0.2);*/
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	color: #fff;
	height: auto;
	width: 270px;
	margin: 5px 0 0 -10px;
}

.psPlugin_warning_title {
	background-color: #a22;
	border-bottom: 1px solid #922;
	border-radius: 5px 5px 0 0;
	font-size: 11px;
	font-weight: bold;
	line-height: 14px;
	margin: -1px;
	padding: 2px 10px;
	color:#fff;
}

.psPlugin_warning_content {
	padding: 5px 5px 5px 10px;
}

.psPlugin_warning_arrow {
	position: absolute;
	top: 0;
	width: 0;
	height: 0;
	margin: -20px 0 0 10px;
	border: 10px solid transparent;
	border-bottom-color: #a22;
}
