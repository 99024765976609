.spinner {
  position:absolute;
  width:60px;
  height:60px;
  pointer-events:none;
  opacity:0;
  border-left:6px solid rgba(0,0,0,.05);
  border-right:6px solid rgba(0,0,0,.05);
  border-bottom:6px solid rgba(0,0,0,.05);
  border-top:6px solid rgba(0,0,0,.25);
  border-radius:100%;
  .animation(rotation .8s infinite linear);
  .transition(opacity 400ms 200ms);

  margin-left: 140px; //590 - 80 / 2
  margin-top: 70px;


  &.show { opacity:1; }
  &.hide { display:none; }

}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}


/***
**** Metrics component
***/

.statistics-components {
  display: -ms-flexbox;
  display: flex;
  background: #f2f0f1;
  padding: 10px 0;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
	border: 2px solid #e3e3e3;
	//max-width: 1102px;

  div.metrics-component {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    align-self: flex-start;
    justify-content:space-between;

    .metrics-content {
      text-align: center;
      font-size: 22px;
      font-family: @headings-font-family;
      line-height:22px;

      &.metrics-time {
        font-size:16px;

      }
    }

    .metrics-label {
      text-align: center;
      font-size: 11px;
    }
    //override dc.js
    .dc-chart {float:none;}
  }

  >div{
    list-style: none;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 15px;
    line-height: 1.2;
  }
}

.metrics-disclaimer {
  margin:-20px 0 1em;
  color:#999;
  font-size:11px;
}

// Panels on Dashboard
.card {
	&.on-dashboard {
		overflow: hidden;
		margin-bottom:30px;

		&.card-naked {

			.card-heading {
				padding: 0 0 10px 0;
			}
			.card-body {
				padding: 0;
				margin-right: -20px;

				.applications {
					width: 90px;
					height: 90px;
					background: #f2f0f1;
					border: 1px solid #e3e3e3;
					margin: 0 20px 20px 0;
					float: left;
					color: #5a5a5a;
					text-align: center;
					position: relative;

					i {
						font-size: 25px;
						display: block;
			color: #00b7c9;

						margin-top: 15px;
					}
					.app-label {
						text-transform: uppercase;
						font-size: 10px;
						line-height: 1em;
						margin-top: 8px;
						display: block;
						font-family: @headings-font-family;
					}
					&:hover {
						cursor: pointer;
						background: #eae9eb;
						text-decoration: none;
					}
					.app-link {
						width: 13px;
						height: 13px;
						font-size: 10px;
						background: #ddddde;
						border: 1px solid #c7c8c8;
						color: #8a8989;
						line-height: 1.1em;
						font-family: 'icon';
						position: absolute;
						bottom: -1px;
						right: -1px;
					}
					.app-link:before { content: '\e80b';}
				}

			}
		}

		.card-body {
			height: 225px;
			padding: 0;
			overflow-x: hidden;


			.svg-content {
				display: inline-block;
				position: absolute;
				top: 50%;
				margin-top: -90px;
			}

			&.dc-chart {
				float: none;
			}

			svg {
				margin-top: 20px;
				g.chart-body rect.bar:hover {
					fill-opacity: 1;
					cursor: default;
				}
			}

		}

		.card-header {
			font-size: 13px;
			height: 29px;
			padding: 6px 15px;
			color: #646464;
		}
	}
}

.component-striptags-tags {
  margin: 5px 0;

  .component-stringtags-tag {
    &:extend(.badge);
    &:extend(.badge-info);
    padding: 3px 8px;
    color: white;
    display: inline-block;
    font-style: italic;
    font-size: 90%;
    margin: 0 5px 5px 0;
  }

  .component-stringtags-tag-x {
		width: 17px;
    display: inline-block;
    background: rgba(0,0,0,0.2);
    color: white;
    border-radius: 100%;
    height: 17px;
    vertical-align: middle;
    text-align: center;
    font-style: normal;
		margin: 0 5px 0 0;
		padding: 3px 2px 2px 2px;
  }
}

ul.tag-list {
  margin:0;
  padding: 0;
  li {
    list-style: none;
    display: inline-block;
    margin: 0 0 0 5px;
  }
}

.disclaimer {
  color: #ccc;
  font-size: 11px;
  margin-top: 8px;

  strong {
    font-family: 'vngage-semibold';
    font-weight: normal;
  }

  > span {
    display: inline-block;
  }
  .warn-icon {
      background: url('./img/ico_warn.png');
      width: 20px;
      height: 18px;
      margin-bottom: 2px;
      margin-right: 10px;
  }
}

/***
**** MyStats component
***/
.mystats-components {
  padding: 10px 0;
  font-family: 'vngage-semibold';

  > .row {
    margin-left: 0;
    margin-right: 0;
/*    width: 343px;*/
  }
  .mystats-filter {
    border-right: 1px solid #e3e3e3;
    /*height: 205px;
    width: 100px;
    float: left;*/

    .profile-pic {
      margin: 10px auto;
      width: 56px;
      height: 56px;
      img {
        border-radius: 100%;
        width: 100%;
        height: 100%;

      }
    }

    ul {
      list-style: none;
      margin: 10px 0;
      padding: 0;

      li {
        text-align: center;
        font-size: 10px;
        line-height: 26px;

        a { color: #333; text-decoration: none; }
        a:hover { text-decoration: none; color: @link-hover-color; }
        a.active { color: #00b7c9; text-decoration: none; }
      }
    }
  }

  .mystats-container {
    /*width: 225px;
    margin-left: 8px;
    margin-top: 5px;
    padding-left: 30px;
    float: left;*/

    div {
      //padding-left: 0;

      //.row { width: 225px; }
      //.col-lg-6 { width: 112px; float: left; }
    }


    h5 {
      font-size: 11px;
			margin-top: 10px;
      margin-bottom: 0;
    }
    span {
      font-size: 22px;
      &.smaller {
        font-size: 17px;
      }
    }
  }
}
.table tr.row-summary {
	border-top:1px solid #999;
	background: linear-gradient(to bottom, #eee 0%, #fff 50%);
}
/***
**** Group usage component
***/
.group-usage-component {
  &.row {
    margin-left: 0;
  }
  .group-chart {
    position: absolute;
    padding: 20px;

    #groupChart {
			position: relative;
			width: 140px;
			left: 50%;
			margin-left: -70px;

      &.dc-chart {
        float: none;
      }
      svg {
        margin: 0 auto;
      }
    }
    h6 {
      font-size: 10px;
      text-align: center;
			line-height: 3px;
    }
  }
    .group-table {
      float: right;
      border-left: 1px solid #e3e3e3;
      text-align: center;
      min-height: 225px;

      &.col-md-9 {
        padding-left: 1px;
      }
      .table > thead > tr > th {
        border-bottom: none;
        font-size: 11px;
        text-align: center;
				font-weight: normal;
      }
			.table {
        font-size: 10px;
      }
			.table > tbody > tr.dc-table-row {
				border-bottom: 1px solid #e3e3e3;
			}

      .table tr > td.dc-table-column._0 {
        font-weight: bold;
				width: 22%;
				text-align: left;
      }
      .kpi-reached {
        background-color: rgb(240, 78, 83);
        color: white;
        white-space: nowrap;
      }
      .kpi-reached-text {
        color: rgb(240, 78, 83);
      }
    }
}


/* Pagination module*/

.btn + .vngage-pagination {
  margin-right: 15px;
}

.vngage-pagination {
	margin-left: 10px;

	.page-flip-left, .page-flip-right, .page-count-display {
		position: relative;
		float: left;
		padding: 6px 12px;
		line-height: 1.42857;
		text-decoration: none;
		color: @link-color;
		background-color: #FFF;
		border: 1px solid #DDD;
		margin-left: -1px;
	}

	.page-flip-right {
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
	}
	.page-flip-left {
		margin-left: 0px;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
	}

	.page-flip-left:hover, .page-flip-right:hover, .page-flip-left:disabled, .page-flip-right:disabled {
		color: #00717d;
		background-color: #eee;
	}

	.page-count-display:hover {
		cursor: default;
	}
}

/* Users online component */

.users-online-component {
	.no-data {
		position: relative;
		top: 60px;
		left: 50%;
		margin-left: -90px;
		color: lighten(@gray-light, 20%);
		border: 1px dashed;
		border-radius: 3px;
		padding: 10px 20px;
	}

	.table > thead > tr > th {
		padding: 4px 0;
		border-width: 1px;
		font-weight: normal;
		font-size: 11px;
	}
	.table > thead > tr > th:first-child {
		text-align: left;
		padding-left: 16px;
	}
	.dc-table-row {
		height: 47px;
		border-bottom: 1px solid #e3e3e3;
	}
	.dc-table-row:last-child {
		//border-bottom: 0;
	}
	.table > tbody > tr > td {
		vertical-align: middle;
		width: 26%;
		border-top: 0;

		img {
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: table-cell;
			margin-top: 5px;
			margin-right: 0;
			&.presence-away {
				opacity: 0.8;
				background-color: #f04e5e;
				border: 3px solid #f04e5e;
			}
		}

		.username {
			font-size: 12px;
			display: table-cell;
			padding: 3px 0 0 45px;
			line-height: 14px;
			vertical-align: middle;
			font-family: 'vngage-semibold';
			width: 100%;
		}
	}
	.dc-table-column {
		font-weight: normal;
	}
	.dc-table-column._0 {
		width: 100%;
		text-align: left;
		margin-left: 15px;
		display: table;
		table-layout: fixed;
	}

	.chat:after {
		content: '';
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 0 solid transparent;
		border-top: 6px solid #E3E3E3;
		position: absolute;
		bottom: -5px;
		right: 6px;
	}
	.chat {
		width: 30px;
		height: 20px;
		background: #E3E3E3;
		position: relative;
		border-radius: 2px;
		padding-top: 2px;
		text-align: center;
		margin-left: 15px;
		//top: -10px;
		display: inline-block;
		font-size: 12px;
	}
	.active {
		&.chat {
			background: @link-color;
			color: white;
		}
		&.chat:after { border-top: 6px solid @link-color; }
	}
}




//Latency
.is-delayed {
  .aggregated-data {
    color: #ccc;
  }

  .total-dialogusage {
    fill: #ccc;
  }

}
.statistics-latency-warning {
    position:relative;
    cursor:help;

    &.legacy-tip:before {
      top:auto;
      bottom: 10px;
      content: '';
      position: absolute;
      background: transparent;
      border: 6px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.8);
      z-index: 1000001;
    }


    &.legacy-tip:after {
      background: #111;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
      top:auto;
      bottom: 22px;
      color: #fff;
      content: attr(tip);
      display: block;
      left: auto;
      right: 0;
      width:240px;
      white-space: normal !important;
      text-align:left;
      text-shadow: 0 -1px 0 #000000;
      padding: 8px 10px;
      font-size: 12px;
      line-height: 14px;
    }

    i {
      color:#f90;
    }
}

.header-aside {
	color: #666;
	font-size:12px;
	text-align:right;
	float:right;
	line-height:26px;
}
.header-aside-timestamp {
	color: #666;
	font-size:12px;

}
.header-help-icon {
	font-size:14px;
	color:#888;
	cursor:help;
}

.dashboard-header {
	//max-width:1102px;
}

// In BS4, a caret is automatically added to dropdowns/dropups
// Define a class "no-caret" to use where we don't want it (e.g. in monitor's "Agents in desktop"-list and appTemplate)
.dropdown.no-caret .dropdown-toggle::after,
.dropup.no-caret .dropdown-toggle::after {
	display: none;
}

// "alert" is removed in BS4. Re-implement...
.alert {
	padding: @alert-padding;
	> p {
		margin-bottom: 0;
	}
	&.alert-warning {
		background-color: #fff3cd;
		border-color: #ffeeba;
	}
}

// Define class "card-bg" with the same background-color as the old (deprecated) "well"
.card-bg {
	background-color:	@card-bg;
}
