html, body {
	height: 100%;
}

body {
	font-family: @general-font-family;
	overflow-y: hidden;
	overflow-x: hidden;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&.disable-hover {
		* {
			pointer-events: none !important;
		}
	}
}

.row-space-30 {
	margin-bottom: 30px;
}
.row-space-20 {
	margin-bottom: 20px;
}
.row-space-10 {
	margin-bottom: 10px;
}
.row-space-5 {
	margin-bottom: 5px;
}

.space-20 {
	display: block;
	margin-bottom:20px;
	height:1px;
}

.space-around {
	display: block;
	margin-top: 20px;
	margin-bottom:40px;
}

.editor-control-panel {
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 50;
	.input-group-append .input-group-text.force-last {
		border-bottom-right-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}

	label { font-size: 12px; }
	.checkbox-inline { margin-left: 20px; }
}

/**
 * Blank state:
 * `.blank-state` - awesome.
 *
 *     @example
 *     .blank-state
 *       h3 Nothing to dododo
 *       p Oh well, that's life.
 */
.less-editor-wrapper {
	.CodeMirror { height: 100%; }
}
.position-fixed-preview {
	position:fixed;
	top:10px;
}
.position-fixed-area {
	position:fixed;
	top:94px;
}
.editor-preview-area {
	.desktop {
		position: relative;
		overflow: hidden;
		width: 800px;
		height: 600px;
		iframe.visitor {
			position: absolute;
			margin-bottom: -5px;
			border-right:15px solid #444;
			border-bottom:70px solid #444;
			border-top: none;
			border-left: none;
			border-bottom-right-radius: 20px;
			z-index: 100;
			right: 50%;
			margin-right: -300px;
		}
		iframe.background-site {
			position: absolute;
			top: 0;
			z-index: 0;
			right: 50%;
			margin-right: -300px;
			height: 500px;
			border: 1px solid #ccc;
		}
		&:before {
			content: '';
			display: block;
			width: 100px;
			height: 100px;
			background: #444;
			position: absolute;
			bottom: 0;
		}
		&:after {
			content: '';
			display: block;
			width: 300px;
			height: 20px;
			background: #444;
			position: absolute;
			bottom: 0;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
	.mobile {
		margin-top: 0px;
		position: relative;
		width: 320px;
		height: 550px;
		margin-left: 50px;
		overflow: hidden;
		iframe.visitor {
			position: absolute;
			margin-bottom: -5px;
			border-color: #444;
			border-style: solid;
			border-width: 40px 15px 60px;
			border-radius: 20px;
			z-index: 100;
		}
		iframe.background-site {
			position: absolute;
			top: 0;
			z-index: 0;
			border-radius: 20px;
			border: 0;
			margin-top: 40px;
			height: 500px;
		}
		&:before {
			content: '';
			display: block;
			width: 40px;
			height: 10px;
			background: #000;
			border-radius: 20px;
			position: absolute;
			top: 15px;
			left: 50%;
			margin-left: -20px;
		}
		&:after {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			border: 2px solid #000;
			border-radius: 100%;
			position: absolute;
			bottom: 10px;
			left: 50%;
			margin-left: -20px;
		}
	}
	.in-app {
		margin-top: 50px;
		position: relative;
		width: 320px;
		height: 550px;
		margin-left: 50px;
		overflow: hidden;
		iframe.visitor {
			position: absolute;
			margin-bottom: -5px;
			border-color: #444;
			border-style: solid;
			border-width: 40px 15px 60px;
			border-radius: 20px;
			z-index: 100;
		}
		iframe.background-site {
			position: absolute;
			top: 0;
			z-index: 0;
			border-radius: 20px;
			border: 0;
			margin-top: 40px;
			height: 500px;
		}
		&:before {
			content: '';
			display: block;
			width: 40px;
			height: 10px;
			background: #000;
			border-radius: 20px;
			position: absolute;
			top: 15px;
			left: 50%;
			margin-left: -20px;
		}
		&:after {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			border: 2px solid #000;
			border-radius: 100%;
			position: absolute;
			bottom: 10px;
			left: 50%;
			margin-left: -20px;
		}
	}
	@media screen and (max-width : 1630px) {
		.desktop {
			iframe.visitor {
				margin-right: -50%;
			}
			width: 100%;
		}
	}
}

.iframe-preview {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 445px;
	padding-right: 20px;

	iframe { border: 0; }
}

footer {
	background: #2f2f2e;
	height: 50px;
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 100;
	left: 0;
	padding: 0 10px 0 20px;

	.footer-light-logo {
		background: url(@logo-light-footer) no-repeat top right;
		height: 50px;
		width: 30px;
		background-size: 112px 70px;
		opacity: .3;
		float: right;
		margin-top: -5px;
		margin-left: 10px;
	}

	.tag-line {
		color: #fff;
		opacity: .3;
		display: block;
		float: right;
		font-size: 10px;
		margin-top: 27px;
	}
}

#wrapper {
	height: 100%;
}

#application {
	position: relative;
	width: 100%;
	height: 100%;
}

#content {
	height: 100%;
	background: #fff;
	position: relative;
	margin: 0 0 0 40px;
	padding: 0;

	-webkit-transition: all .1s ease;
	-moz-transition: all .1s ease;
	-o-transition: all .1s ease;
	transition: all .1s ease;

	&.has-subnav #main-container {
		margin-left: 160px;
	}
}

#main-container {
	overflow: auto;
	position: relative;
	top: 0;
	bottom: 0;
	height: 100%;
	overflow-x: hidden;
}

#main {
	padding: 60px 40px;

	hr {
		border-top: 1px solid @hr-border;
		margin-bottom: 40px;
	}

	&.row {
		margin-left: 0;
		margin-right: 0;
	}

	@media (min-width: 1400px) {
		padding-left: 80px;
		padding-right: 80px;
	}
}






.selectable {
	li a {
		text-align: center;
	}
	li a:hover {
			background: transparent;
	}
}

.list-control {
	height: 300px;
	overflow: auto;
}

.pointer {
	cursor: pointer;
}

.nav-tabs {
	+ .tab-content {
		margin-top: 30px;
	}
}

.profile-gravatar {
	img {
		border-radius: 100%;
		width: 150px;
		height: auto;
		position: relative;
		margin-left: -10px;
		margin-bottom: 10px;
	}
}

.notfound {
	margin: 60px;

	h1 {
		font-size: 300%;
	}
}

.restrict-width {
	//max-width: 890px;
	min-width: 500px;
	max-width: 100%;
	position: relative;
}

.grid-wrap {
	//max-width: 1155px;

	.g-1_3 {
		width: 405px;
		display: inline-block;
		padding: 0 30px;
		margin-left: -30px;
	}
	.g-2_3 {
		width: 780px;
		display: inline-block;
		padding: 0 30px;
		margin-left: -30px;
	}
}
