.onlineListWrapper {
	width: 425px;
	/*
		min-height:1000px;
	border-radius: 6px;
    background: -webkit-gradient(linear, left top, right bottom, from(rgba(150, 150, 150, 0.2)), to(rgba(255, 255, 255, 0.8)));
	background: -moz-linear-gradient(left,   rgba(150, 150, 150, 0.2),  rgba(255, 255, 255, 0.8));
	opacity:0.6;
	*/
	background-color: #FFF;
	background-color: rgba(255,255,255, .8);
	margin-top: -10px;
	margin-left: 10px; /* to compensate for the queuelist items sticking outside the box*/
	border: 1px solid #CCCCCC;
	-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
	-webkit-box-shadow: 0 2px 3px rgba(0,0,0,.25);
	box-shadow: 0 2px 3px rgba(0,0,0,.25);
	/*overflow:hidden;*/
}
.onlineListQueueWrapper .onlineListHeader {padding-left:0;}
.onlineListHeader {
	font-size: 100%;
	height: 24px;
	line-height: 24px;
	padding-left: 10px;
	font-weight: bold;
	-webkit-text-shadow: 2px 2px 2px #ddd;
	text-shadow: 2px 2px 2px #ddd;
	color: #000000;
	margin-top: -1px;
	border-bottom: 1px solid #CCCCCC;
	border-top: 1px solid #CCCCCC;
	background-color: #F1EFEF;
	padding-right:36px;

	.btn {
		float: right;
		background-color: transparent;
		padding: 3px;
		height: 14px;
		color:#777;
		transition:all 300ms;
		outline:none;

		&:hover {
			background-color:#ccc;
		}
	}
	.vngage-icon {font-size:14px;}

}

.onlineListQueueList {
	height: 280px;
	position: relative;
}

.onlineListProactiveList {
	height: 350px;
	position: relative;
}

.onlineListItem {
    border: 1px solid #CCCCCC;
    display: block;
    height: 60px;
    margin-top: 4px;
	margin-left: -10px;
    position: absolute;
    width: 410px;
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
	background: #fff;
    -webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius: 5px 0 0 5px;
}
.onlineListItem.isTransferred {
	border-color: #55043b;
	background-color: #fdf2fc;

}
.onlineListItem.isTransferred .vngage-icon-torso {
	color: #ab3192;
}
.onlineListItem.isTransferred .transfer-icon {
	color: #ab3192;
    font-size: 12px;
    position: absolute;
    left: 6px;
    bottom: 6px;
}

.onlineListItem.isLostDialog {
	border-color: #55043b;
	background-color: rgba(255, 23, 23, 0.4);
	color: rgba(0, 0, 0, 1);
}
.onlineListItem.isLostDialog .vngage-icon-torso {
	color: #BE1717;
}
.onlineListItem.isLostDialog .transfer-icon {
	color: #ab3192;
	font-size: 12px;
	position: absolute;
	left: 6px;
	bottom: 6px;
}

.onlineListItem .vngage-icon-torso {
	font-size: 17px;
	line-height: 32px;
	margin-left: 5px;
	color: #00b7c9;
}


/* + -indicator for proactive queue */
.onlineListItem.isProactive:before {
	content: "+";
	font-size: 16px;
	position: absolute;
	line-height: 32px;
	padding-left: 2px;
	font-weight: bold;
		color: #156385;
}

	.onlineListItem:hover {
		-moz-transform: translate(-4px,0);
		-webkit-transform: translate(-4px,0);
		-ms-transform: translate(-4px,0);
		-o-transform: translate(-4px,0);
		transform: translate(-4px,0);
	/*box-shadow:0 1px 5px rgba(0,0,0,.5);*/
}

.queueListItemNavigationHistory .ps_icon_small {
	cursor: help;
}

.onlineListItemExpanded {
	z-index: 20;
	height: 150px;
}

.queueListItemTopRow {
	position: relative;
	height: 25px;
}

.queueListItemMessage {
	position: absolute;
	top: 5px;
	left: 25px;
	line-height: 20px;
	font-weight: bold;
	overflow: hidden;
	height: 20px;
	padding-left: 10px;
	min-width: 50px;
	background: url(img/desktop/chat/bg_talkshadow.png) no-repeat top left;
}

.meeting-start-time {
	position: absolute;
	top: 17px;
	left: 35px;
	color: #156385;
	font-size: 10px;
}

.queueListItemButtonArea {
	text-align: right;
	position: absolute;
	top: 5px;
	right: 25px;
	width: 100px;
	line-height: 20px;
	height: 20px;
}

.queueListItemNavigationHistory {
	padding: 0;
	position: absolute;
	left: 35px;
	bottom: 10px;

	i {
		transition: all 0.4s;

		&:hover {
			opacity: 1 !important;
		}
	}

	i:nth-child(1n+4){opacity: 0.8}
	i:nth-child(1n+5){opacity: 0.7}
	i:nth-child(1n+6){opacity: 0.6}
	i:nth-child(1n+7){opacity: 0.5}
	i:nth-child(1n+8){opacity: 0.4}
	i:nth-child(1n+9){opacity: 0.3}
}

.queueListItemScore {
	display: inline-block;
	margin: 2px 0 0;
    text-align: center;
    width: 32px;
}
/*TODO: add the p to view later on */
.queueListItemScore:after {
		content: "p";
}

.queueListItemWaitTime {
	position: absolute;
	margin-top: 20px;
	margin-left: 4px;
	font-size:10px;
}


.usersOnline {
    float:right;
    color:#aaa;
    padding-right:50px;
}
.queueStatusPopup{
    position:absolute;
    top: 25px;
    width: 370px;
    display:none;
    z-index: 2000;
		background:#2f2f2e;
    padding:10px;
}


.icon-meeting-code {
	margin:0 0.2em;
	width:16px;
	height:14px;
	background-position:50% 50%;
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABE0lEQVR42u2U7QnCQAyGO0JHcARHcARH8AM/8ANP1FasYq229Z8jOEJHuBFuhI7QEWJSGhCxnBF/GnjhyOWeuzQvdf4hik6320ZpVIhyfwXV+8MBVus14NqIX1STL+IkgfR6hcl0SuDWJzAXdUfpmn0CfgxloKlay+kAqvFSk6nlEpI0tUKpuIkqCEiv2AVBeWgwHNJBkn6qvXm+D71+n/KuDWoYuvE8BuaoDKWeas0xDO2D4var9hjWeHf5aDyGhVJU05FMXqGgzlL+dlte6kiDX1kNq12t79TFbD4HzkmCYRqHxZAcgWz6TAzk70YOYE9G5zMDDcr9GnqKohJ4iWMLUDAs9CEbvJD9QOwWazn/kMQDN7/ZxQe4IOEAAAAASUVORK5CYII=');
	background-repeat: no-repeat;
	display:inline-block;
	vertical-align: middle;
}
.onlineListHeader > span {
	text-transform: lowercase;
}

.header-visitors {
	min-width: 13em;
	display: inline-block;
}
.visitors-count,
.agents-count {
	background: #888;
	display: inline-block;
	padding: 0px 8px 2px;
	text-shadow: none;
	font-size: 14px;
	line-height: 22px;
	border-right: 1px solid #ccc;
	color: #fff;
	text-align: center;
	margin-right: 2px;
	min-width:12px;
}

.visitors-count {
	background: darkcyan;
}


table.queue-data {
	width:100%;
	font-size:11px;

	td {
		line-height:24px;
		font-size:12px;
	}
}
.queue-data thead th{
	color:#fff !important;
	line-height: 1;

}

.col-available {text-align:left}
.col-available .flipswitch {
	margin:0;
}
.col-name {
	font-weight:bold;
	padding-left:20px;
	color:#ccc;
}
.col-visitors, .col-agents {
	text-align:center;
	color: #ccc;
}
.row-site {
	td {
		font-size: 10px;
		padding-left: 0;
		color: #888;
	}
	.col-name {
		font-weight: bold;
		font-size: 10px;
	}
}



.btn-meeting-code {
	float:right;
	background-color:#ccc;

	&:focus {
		outline:none;
	}

	&:hover {
		background-color:#ddd;
	}

	&:active {
		background-color:#ccc;
		box-shadow: inset 1px 1px 3px rgba(0,0,0,.3);
	}

	.onlineListHeader & {
		background-color:#F1EFEF;
	}
}
