.sortable-list {

	.table {
		th {
    		@headerCol: #fdf3e5;
    		font-family: @headings-font-family;
    		background-color: @headerCol;
    		background-clip: padding-box;
    		position: relative;
    		font-weight: normal!important;
    	}
		td:last-child {
			position: relative;
		}
	}

	.list-action-icons {
		position: absolute;
		top: 4px;
		right: 10px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		i {
			color: rgba(255, 255, 255, 0);
			font-size: 18px;
			margin: 0;
			padding: 0;

			&:before {
				width: 0.8em;
			}
		}
	}

	tr:hover .list-action-icons i {
		color: #c5c5c5;

		&.inactive {
			color: #ddd !important;
		}

		&:hover {
			cursor: pointer;
			&.inactive { cursor: default; }

			&.vngage-icon-up-open,
			&.vngage-icon-down-open{
				color: @brand-action;
			}

			&.vngage-icon-cancel {
				color: @brand-danger;
			}
		}
	}
}