.CodeMirror {
    border: 1px solid #cecece;
    height: auto;
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.CodeMirror-scroll{
    overflow-x: hidden;
}

.CodeMirror-line-error {
    background: #f2dede !important;
}

.CodeMirror-lint-error {
    font-family: arial;
    font-size: 70%;
    background: #ffa;
    color: #a00;
    padding: 2px 5px 3px;
}

.CodeMirror-lint-error-icon {
    color: white;
    background-color: red;
    font-weight: bold;
    border-radius: 50%;
    padding: 0 3px;
    margin-right: 7px;
}

.CodeMirror-merge {
	height: 550px;

	.CodeMirror {
		height: 550px;
	}
}
div.CodeMirror span.CodeMirror-matchingbracket {
    color:black;
    background-color:lightgrey;
}