#vngage-desktop-deux-app {
	display: flex;
	height: 100%;
	margin: 0;
	padding: 0;
	//font-family: arial;
	color: #555;
	overflow: hidden;
}

.desktop-wrapper {
	flex: 1;
	height: 100%;
}

.desktop-content {
	display: flex;
	height: 100%;
	width: 100%;
	position: absolute;
}

@import "user-settings";
@import "panel/index";
@import "queue/index";
@import "debug-tool";
@import "overlay";
@import "plugin-tool";
@import "emoji-mart";
@import "landingpage";
@import "messaging";
@import "dropdowns";
@import "decoupled/cobro";
