.conversation {
	min-height: 150px;
	background: white;
	display: flex;
	flex: 1;
	flex-direction: row;
	//border-left: 1px solid #FFF;
	//border-right: 1px solid #FFF;

	.read-write {
		min-height: 150px;
		position: relative;
		background: white;
		width: 273px;
		display: flex;
		flex-direction: column;

		@import "message-list";
		@import "compose-message";
		@import "compose-note";
		.connection-feedback-error {
			font-size: 10px;
			color: #f04e5e;
			font-style: italic;
			margin-left: 10px;
		}
		.connection-feedback-terminated {
			font-size: 11px;
			font-style: italic;
			color: #999;
			margin-left: 10px;
		}
		.message-toolbar {
			height: 20px;
			display:flex;
			justify-content: flex-end;
			flex-direction: row;
			.user-typing { display: inline-block; }
		}
		.message-toolbar-left-box {
			display:flex;
			align-content: flex-start;
			align-items: center;
			width: 70%;
		}
		.message-toolbar-right-box {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 30%;
		}
		.message-toolbar-btn {
			width: 26px;
			border: 0;
			background: none;
			cursor: pointer;
			&:hover { color: @component-active-bg; }
			&:focus { outline: none; }
			&.active {
				color: @component-active-bg;
			}
			&:disabled {
				pointer-events: none;
				cursor: not-allowed;
				opacity: .5;
				color: @gray-light;
			}
		}
		.notes-symbol::before {
			font-family: 'icon';
			content: '\E87A';
			font-size: 14px;
		}
	}
	&.connection-status-ok {
		form.compose-message-wrapper {
			textarea {
				background-color: #fff;
			}
		}
	}
	&.connection-status-lost {
		form.compose-message-wrapper {
			.menu-trigger { background-color: #ffecec; }
			textarea {
				background-color: #ffecec;
			}
		}
	}
	&.connection-status-terminated {
		form.compose-message-wrapper {
			.menu-trigger { background-color: #fff; }
			textarea {
				background-color: #fff;
			}
		}
	}
}