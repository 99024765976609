[uib-datepicker] {
	/*
		Fix ui-bootstrap-4's quirky svg-styling in datepicker-popup
		Fix below comes from: https://github.com/Morgul/ui-bootstrap4/issues/23
	*/
	.fa-svg-icon {
		display: inline-block;
		vertical-align: middle;
		min-width: 1em;
		min-height: 1em;
		height: 100%;
		position: relative;
		top: -1px;
	}
	.fa-svg-icon svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.fa-svg-icon svg g,
	.fa-svg-icon svg path {
		fill: currentColor;
	}

	/*
		Datepicker in ui-bootstrap-4 sets class "btn-secondary" on its buttons instead of "btn-default" in BS3
		Style and mimic our BS3 look
	*/
	button.btn-secondary {
		background-color: white;
		color: #282828;
		border-color: white;
	}

	button.btn-secondary:hover,
	button.btn-secondary:focus,
	button.btn-secondary.focus,
	button.btn-secondary:active,
	button.btn-secondary.active,
	.open > .dropdown-toggle.btn-secondary {
		color: #9a9a9a;
		background-color: #e6e6e6;
		border-color: #adadad;
	}

	button.btn-secondary:not(:disabled):not(.disabled):active,
	button.btn-secondary:not(:disabled):not(.disabled).active,
	.show > button.btn-secondary.dropdown-toggle {
		color: #ffffff;
		background-color: #808080;
		border-color: #797979;
	}
}
