.input-menu {

	.btn-group {
		height: 100%;
	}

	.dropdown-menu {
		padding-bottom: 10px;
		padding-top: 55px;
		width: 262px;
		left: 5px;
		overflow-x: scroll;
		background-color: #fff;
		font-size: 13px;
		height: 300px;

		.dropdown-item {
			padding: 0px 10px 0px 10px;
			white-space: normal;
		}

		// TAB HEADER
		.dropdown-header {
			padding: 0;

			&.fixed {
				position: relative;
			}

			.tab-header {
				text-align: center;
				height: 55px;
				width: 260px;
				border-bottom: 1px solid #ccc;
				background: @white;
				position: fixed;
				margin-top: -55px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				z-index: 99;
			}

			.tab-title {
				font-size: 11px;
				padding: 4px 0 0;
			}

			.tab-nav {
				display: flex;
				align-items: center;
				height: 30px;
				padding: 0 5px;

				.nav-item {
					margin: 5px;
					font-size: 20px;
					padding: 0;
					border: 0;
					background: transparent;

					&:hover {
						color: @component-active-bg;
					}

					&:active,
					&:focus {
						outline: 0;
					}

					&.active {
						color: @component-active-bg;
					}
				}
			}

			.filter-wrapper {
				padding: 10px;
			}

			.filter {
				width: 100%;
				border-radius: 2px;
				border: 1px solid #ccc;
				padding: 6px;
				font-size: 12px;
			}
		}

		.overflow-shadow {
			position: fixed;
			bottom: 190px;
			height: 10px;
			width: 250px;
			box-shadow: inset 0 -10px 15px 0px white;
			z-index: 990;
			left: 13px;
			overflow: hidden;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		.response {
			padding: 4px 0px 4px 0px;

			strong {
				color: #000;
				margin-top: 5px;
			}

			p {
				margin: 0 0 5px;
			}
		}

		.item-header {
			padding: 3px 10px 7px;
			color: #000;
			margin-top: -5px;

			strong {
				font-family: 'vngage-bold', sans-serif;
				display: inline-block;
				width: 100%;
				border-bottom: 1px dashed #ccc;
				margin-top: 10px;
				padding-left: 5px;
			}

			p {
				margin: 0;
			}
		}

		.item {
			i {
				font-size: 14px;
			}

			span {
				margin-left: 5px;
			}
		}
	}

	.open {
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-width: 10px;
			border-left-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-top-color: #BFBFBF;
			border-style: solid;
			position: absolute;
			bottom: 118px;
			left: 10px;
		}
	}
}
