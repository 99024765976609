@font-face {
  font-family: 'vngage';
  src: url('@{general-font-path}opensans-regular-webfont.eot');
  src: url('@{general-font-path}opensans-regular-webfont.eot') format('embedded-opentype'),
       url('@{general-font-path}opensans-regular-webfont.woff') format('woff'),
       url('@{general-font-path}opensans-regular-webfont.ttf') format('truetype'),
       url('@{general-font-path}opensans-regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vngage-semibold';
  src: url('@{general-font-path}opensans-semibold-webfont.eot');
  src: url('@{general-font-path}opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{general-font-path}opensans-semibold-webfont.woff') format('woff'),
       url('@{general-font-path}opensans-semibold-webfont.ttf') format('truetype'),
       url('@{general-font-path}opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
	font-family: 'vngage-bold';
	src: url('@{general-font-path}opensans-bold-webfont.eot');
	src: url('@{general-font-path}opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{general-font-path}opensans-bold-webfont.woff') format('woff'),
	url('@{general-font-path}opensans-bold-webfont.ttf') format('truetype'),
	url('@{general-font-path}opensans-bold-webfont.svg#open_sanssemibold') format('svg');
	font-weight: normal;
	font-style: normal;

}

h1 {
  margin: 0 0 20px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &.extra-space {
    margin-bottom: 35px;
  }
  &.underlined {
    border-bottom: 1px solid @headings-color;
    padding-bottom: 5px;
  }
}

.view-description {
	margin-bottom: 30px;

	b {
		font-family: 'vngage-semibold', sans-serif;
		font-weight: normal;
	}
}

.bold-text {
	font-family: 'vngage-semibold', sans-serif;
	font-weight: normal;
}

.normal-text {
	font-family: 'vngage', sans-serif;
	font-weight: normal;
}

.break-word {
	overflow-wrap: break-word;
	word-wrap: break-word;
}
