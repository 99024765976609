// Override paths from package from fontello
@font-face {
  font-family: 'icon';
  src: url('@{icon-font-path}icon.eot?24987543');
  src: url('@{icon-font-path}icon.eot?24987543#iefix') format('embedded-opentype'),
       url('@{icon-font-path}icon.woff?24987543') format('woff'),
       url('@{icon-font-path}icon.ttf?24987543') format('truetype'),
       url('@{icon-font-path}icon.svg?24987543#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon';
    src: url('@{icon-font-path}icon.svg?59335316#icon') format('svg');
  }
}


/* Action icons */
.vngage-icon-webpage:before { content: '\e819'; } /* '&quot;' */
.vngage-icon-info:before { content: '\e81a'; } /* 'c' */
.vngage-icon-product:before { content: '\e81b'; } /* '' */
.vngage-icon-pay:before { content: '\e81c'; } /* 'n' */
.vngage-icon-shoppingcart:before { content: '\e81d'; } /* '!' */
.vngage-icon-shipping:before { content: '\e81e'; } /* '' */
.vngage-icon-action:before { content: '\e81f'; } /* 'l' */
.vngage-icon-group:before { content: '\e820'; } /* 'j' */

// vngage-icon-to-end:before, but rotated 90 degrees...
.vngage-icon-state-hungup:before { content: '\e834'; transform: rotate(90deg);} /* '' */
.vngage-icon-home:before { content: '\e84d'; } /* '' */

// Desktop and devices

.large[class^="vngage-icon-"]:before, .large[class*=" vngage-icon-"]:before {
  font-size: 30px;
}

.medium[class^="vngage-icon-"]:before, .medium[class*=" vngage-icon-"]:before {
  font-size: 28px;
}