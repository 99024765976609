/*
The mixins below where part of "vendor-prefix" from Bootstrap 3 and is now deprecated (https://getbootstrap.com/docs/4.0/migration/#vendor-prefix-mixins)
Not sure if needed, but maybe we need to look at "Autoprefixer": https://github.com/postcss/autoprefixer to add vendor-specific stuff
Implement them here for now!
*/
.animation(@arguments) {
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation: @arguments;
}
.transition(@arguments) {
	-webkit-transition: @arguments;
	-moz-transition: @arguments;
	-ms-transition: @arguments;
	-o-transition: @arguments;
	transition: @arguments;
}
