.psExpandableArea {
	margin: -1px -1px 0;
	background:#f5f5f5;

}

.psExpandableAreaHeader {
	background-color: #ccc;
	border: 1px solid #b4b4b4;
	color: #323232;
	-webkit-text-shadow: 0 1px 0 rgba(255,255,255,.3);
	text-shadow: 0 1px 0 rgba(255,255,255,.3);
	font-size: 10px;
	font-weight: bold;
	padding: 0 10px;
	text-align: center;
	height: 16px;
	line-height: 16px;
	position: relative;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
    cursor: pointer;

	.psExpandableAreaHeaderTitle {
		text-align: left;
		.uaString { float: right; color: #888; }
	}
}

.psExpandableAreaContent {
	position: relative;
	overflow: auto;
	overflow-x: hidden;
	border-left:1px solid #ccc;
	border-right:1px solid #ccc;
}
.psExpandableAreaHeaderIcon {
	display: none;
}
/*
.psExpandableAreaHeaderIcon {
	opacity: 0.7;
	position: absolute;
	top: 3px;
	width: 8px;
	height: 12px;
	cursor: pointer;
}

.psExpandableAreaHeaderIconInactive {
	opacity: 0.2;
}

.psExpandableAreaHeaderIcon:hover {
	opacity: 0.4;
}

.psExpandableAreaHeaderIconMinimize {
	right: 14px;
	background: url(img/desktop/expandableArea/minimize.png) no-repeat top;
    display: none;
}

.psExpandableAreaHeaderIconPreview {
	right: 2px;
	background: url(img/desktop/expandableArea/preview.png) no-repeat top;
    display: none;
}

.psExpandableAreaHeaderIconMaximize {
	right: 2px;
	background: url(img/desktop/expandableArea/maximize.png) no-repeat top;
    display: none;
}

.stateCollapsed .psExpandableAreaHeaderIconMaximize,
.stateFull .psExpandableAreaHeaderIconMaximize {
	display: none;
}

.statePreview .psExpandableAreaHeaderIconPreview {
	display: none;
}
*/
