
.dashboard-card {
	.card-body {
		height: 225px;
		padding: 0;
		overflow-x: hidden;

	}
}


.group-overview-card {
	font-size: 10px;
	font-weight: normal;

	th {
		font-size: 11px;
		border-top: none;
	}

	.bold.text {
		font-family: 'vngage-semibold', sans-serif;
		font-weight: normal;
	}

}

.profile-pic {
	width: 35px;
	height: 35px;

	.profile-img {
		border-radius: 100%;
		width: 100%;
		height: 100%;
	}
}

.users-online-card {

	.row {
		display: flex;
		justify-content: space-between;

		.agent {
			display: flex;
			justify-content: space-between;

			.profile-pic {
				width: 35px;
				height: 35px;
			}

			span {
				font-size: 12px;
				padding: 10px 0 0 10px;
				vertical-align: middle;
				font-family: 'vngage-semibold';
			}
		}

		.number {
			padding-top: 8px;

			.number-bubble {
				width: 30px;
				height: 20px;
				background: #E3E3E3;
				position: relative;
				border-radius: 2px;
				padding-top: 2px;
				text-align: center;
				margin-left: 15px;
				display: inline-block;
				font-size: 12px;

				&:after {
					content: '';
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 0 solid transparent;
					border-top: 6px solid #E3E3E3;
					position: absolute;
					bottom: -5px;
					right: 6px;
				}

				&.active {
					background: @link-color;
					color: white;

					&.active:after {
						border-top: 6px solid @link-color;
					}
				}

			}
		}

	}


}


.user-card-content {
	display: flex;
	flex-direction: row;

	.profile {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-size: 10px;
		line-height: 26px;
		border-right: 1px solid #e3e3e3;
		width: 30%;

		.profile-pic {
			margin: 10px auto;
			width: 56px;
			height: 56px;
		}

		.range {
			color: #333;
			text-decoration: none;

		}

		.range:hover {
			text-decoration: none;
			color: @link-hover-color;
		}

		.range.active {
			color: #00b7c9;
			text-decoration: none;
		}

	}

	.stats {
		font-family: 'vngage-semibold';
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		flex-wrap: wrap;
		flex: 1;

		& > div {
			min-width: 50px;
			flex: 0 40%;

			h5 {
				font-size: 11px;
				margin-bottom: 0;
			}

			span {
				font-size: 22px;
			}
		}

	}
}


.metrics-bar {
	display: flex;
	background: #f2f0f1;
	padding: 10px 0;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 30px;
	border: 2px solid #e3e3e3;

	.metric-component {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		justify-content: space-between;
		text-align: center;

		.metric-content {
			font-size: 22px;
			font-family: @headings-font-family;
			line-height: 22px;

			&.metrics-time {
				font-size: 16px;

			}
		}

		.metrics-label {
			font-size: 11px;
		}

		.dc-chart {
			float: none;
		}
	}

}
