.banner-preview-controls {
	background: #f2f2f2;
	border: solid 1px #ddd;
	margin-bottom: 20px;
	padding: 12px;

	.banner-preview-form-group {
		margin-bottom: 5px;
		margin-right: 15px;
	}

	.preview-group-label {
		display: block;
		font-size: 85%;
	}

	.banner-preview-form-group.preview-checkbox {
		margin-left: 16px;
		margin-right: 16px;
		vertical-align: bottom;
		padding-top: 2px;
		small {
			vertical-align: top;
		}
	}

	.preview-address {
		vertical-align: bottom;
	}

	.btn-group-sm > .btn {
		padding: 3px 7px;
	}
}

.banner-preview-wrapper {
	.card;
	.card-default;

	display: block;	// .card sets "display: flex", but it needs to be reset to "block" for the banner-preview to work
	background: linear-gradient(#f2f2f2, #fff);
	overflow-x: auto;
	overflow-y: auto;

	&.hide-overflow {
		overflow-x: hidden;
	}


	.banner-preview {
		width: 800px;
		height: 550px;
		min-width: 100%;
		opacity: 0;
		position: relative;
		padding-right: 1px;
		transform-origin: top left;

		&.active {
			opacity: 1;
			transition: all 0.3s;
		}

		&.scroll-y .preview-content {
			width: ~"calc(100% - 17px)";
		}

		iframe {
			border: 0;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.preview-background {
			z-index: 1;
		}

		.preview-content {
			z-index: 2;
		}
	}
}
#sticky {
	&.position-fixed {
		position: fixed;
		top: 10px;
		bottom: 0;
		overflow-y: auto;
	}
}

.table.less-variable-table {
	td {
		&.break-line {
			word-break: break-all;
			code { word-break: break-word; }
		}
		&.break-line-word {
			word-break: break-word;
		}
		&.one_third {
			width: 33.33%;
		}
	}
}
