.sg-index {
	position: fixed;
	top: 50px;
	right: 0;
	background: white;

	ul.nav {
		border: 1px solid #cecece;
		padding: 10px 0;

		> li > a {
			padding: 5px 15px;
			&.in-view {
				color: #00717d;
				background-color: #eeeeee;
			}
		}
	}
}

.sg-block {
	border-top: 0 !important;
	//border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

hr.sg:after {
	content: '';
	display: table;
	clear: both;
}

// Overrides for styleguide
.sg {
	.vngage-pagination {
		display: inline-block;
	}

	.vngage-selectable-items.items-controls .row .btn [class^="vngage-icon-"]:before,
	.vngage-selectable-items.items-controls .row .btn [class*=" vngage-icon-"]:before {
		margin-left: -2px;
		margin-right: -2px;
	}

	.back-navigation {
		position: relative;
		padding: 0 20px;
		top: 0;
	}
}