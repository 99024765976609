
//DC.js specifics and overrides:
//hide empty group labels in data tables:
.dc-table-label:empty {
  display:none;
}

//By default dc-charts are float left.
//That doesn't go very well with resizableCaharts, so unset it.
.chart-main .dc-chart {
	float:none;
}


div.tooltipchart {
  position: absolute;
  width: 150px;
  height: auto;
  padding: 15px;
  background-color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  font-family: sans-serif;
  font-size:12px;

  > ul {
    list-style-type: none;
    padding-left: 0px;
  }
}


.metrics {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  background: #f2f0f1;
  padding:5px 0;

  > div {
    list-style:none;
    -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;         /* OLD - Firefox 19- */
    width: 20%;               /* For old syntax, otherwise collapses. */
    -webkit-flex: 1;          /* Chrome */
    -ms-flex: 1;              /* IE 10 */
    flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
    padding:0 15px;
    font-size:12px;
    line-height:1.2;
  }
  .num, .number-display {
    display: block;
    font-size:24px
  }

}


.filters {
  margin: 10px 0;

  .filter {
    display: inline-block;
    width: 100%;

    .chart-reset {
      margin-top: 7px;
    }

    .panel {
      .panel-heading {
        overflow: hidden;
        padding: 0 15px;
      }

      .panel-body {
        background: rgba(0,0,0,0.17);
        max-height: 220px;
        overflow-x:hidden;
        overflow-y:scroll;
      }
    }
  }
}

.table-opportunities {
  th, td {text-align:right;}
  ._0 {text-align:left;}
}

.table-solutions {
  th, td {text-align:right;}
  ._0,._1 {text-align:left;}

  ._2,._4 {border-left:1px solid #ccc;}


  td._0, td._1 {
    white-space: nowrap;
    max-width:12em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-cases {
  th, td {text-align:right;}
  ._0 {text-align:left;}
  ._2 {border-left:1px solid #ccc;}
}

.table-users {
  th,td {
    text-align:right;
  }
  ._0 {text-align:left;}

  th._1,
  th._2 {white-space: nowrap}
}

.table-queues {
  th, td {text-align:right;}
  ._0 {text-align:left;}
}
