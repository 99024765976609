.message-list-container {
	flex: 1;
	position: relative;
	flex-direction: column;
	display: flex;
	overflow-y: hidden;
	user-select: text;
	.history-indicator {
		position: absolute;
		text-align: center;
		width: 100%;
		z-index: 2;
		h2.line {
			position: relative;
			z-index: 1;
			font-size: 10px;

			span {
				/* to hide the lines from behind the text, you have to set the background color the same as the container */
				background: #fff;

				.btn {
					font-size: 10px;
					border-radius: 20px;
				}

			}
		}
		&.hidden {
			visibility: hidden;
		}
	}
	.new-messages-indicator {
		position: absolute;
		bottom: -11px;
		text-align: center;
		width: 100%;
		h2.line {
			position: relative;
			z-index: 1;
			font-size: 10px;

			span {
				/* to hide the lines from behind the text, you have to set the background color the same as the container */
				background: #fff;

				.btn {
					font-size: 10px;
					border-radius: 20px;
				}

			}
		}
	}
	.message-list {
		flex: 1;
		overflow-y: scroll;
		overflow-x: hidden;

		> ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {

			&.message-agent,
			&.message-visitor {
				color: #000;
				padding: 10px 10px;
				position: relative;
				.role {
					font-size: 14px;
					margin-right: 5px;
					float: left;
				}
				.message {
					margin: 0;
					word-wrap: break-word;
					display: inline;
					p {
						margin-bottom: 0px;
					}
				}
				.date-time {
					font-size: 10px;
					font-weight: normal;
					display: inline-block;
					color: #999;
					font-style: italic;
					margin-left: 5px;
					&:hover {
						cursor: pointer;
					}
				}
				h5 {
					margin-top: 0;
					margin-bottom: 3px;
				}
				p {
					//font-size: 14px;
					margin-top: 3px;
					line-height: 20px;
				}
				.msg-tools {
					position: absolute;
					top: 2px;
					right: 17px;
					visibility: hidden;
					opacity: 0;
					transition: opacity .2s ease;
					//font-size: 12px;
					font-size: 85.8%;
					box-shadow: 0 0 5px -1px #444;
					background: @white;
					border-radius: 100%;
					.copy-msg {
						color: #000;
						transition: color .3s ease;
						height: 10px;
						width: 10px;

						&.copied {
							color: #00b3ee;
							transition: color .3s ease;
						}
					}

				}
				.system-msg {
					&.active,
					&.active:hover {
						color: #00b3ee;
					}
					&:hover {
						color: black;
					}
				}
				.copy-msg {
					&:hover {
						color: black;
					}
				}
				&:hover {
					.msg-tools {
						visibility: visible;
						opacity: 1;
						transition: opacity .2s ease;
						cursor: pointer;
					}
				}
			}
			&.message-agent {
				&.pending {
					opacity: .6;
				}
				&.error {
					.error-message {
						opacity: .4;
					}
					.error-marker {
						position: absolute;
						bottom: 4px;
						right: 10%;
						.error-text {
							margin-top: 5px;
							color: red;
							font-style: italic;
							font-size: 10px;
						}

						.try-again-button {
							display: none;
							//position: absolute;
							//margin: 0px 32px;
							.btn {
								padding: 0px 5px 0px 5px;
								font-size: 10px;
								border-radius: 20px;
								//min-width: 190px;
								//width: 190px;
								margin: 0 auto;
								i {
									margin-right: 4px;
								}
							}
						}
						&:hover {
							color: black;
							.try-again-button {
								display: block;
							}
							.error-text {
								display: none;
							}
						}
					}
				}

				&.type-chat {
					background-color: #f1f1f1;
					+ .message-agent.type-chat,
					+ .message-agent.type-video,
					+ .message-agent.type-link {
						padding-top: 0;
						//.role { display: none; }
						//p :first-of-type {display: none;}

						.msg-tools {
							top: -5px;
						}

					}
				}
				&.type-divider {
					font-size: 10px;
					background: #fff;
					text-align: center;
					position: relative;
					z-index: 1;
					i {
						background: #fff;
						padding-left: 5px;
						padding-right: 2px;
					}
					&:before {
						border-top: 2px solid #dfdfdf;
						content:"";
						margin: 0 auto; /* this centers the line to the full width specified */
						position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
						top: 50%; left: 0; right: 0; bottom: 0;
						width: 95%;
						z-index: -1;
					}

					.inner-white {
						/* to hide the lines from behind the text, you have to set the background color the same as the container */
						background: #fff;
						padding-right: 5px;
						display: inline-flex; // this fixes gaps between spans in inner
					}
				}
				&.type-historyMarker {
					padding: 3px 10px;
					width: 100%;
					.btn {
						padding: 0px 5px 0px 5px;
						font-size: 10px;
						border-radius: 20px;
						min-width: 190px;
						width: 190px;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;
						flex: 1;
						i {
							margin-right: 4px;
						}
					}
				}
				&.type-content {
					.content-image-icon, .content-video-icon, .content-file-icon {
						cursor: pointer;
					}
					.content-image-icon:before, .content-video-icon:before, .content-file-icon:before {
						font-family: 'icon';
						font-style: normal;
						display: inline-block;
						margin-right: 10px;
						font-size: 16px;
					}
					.content-image-icon:before {
						content: '\e84b';
					}
					.content-video-icon:before {
						content: '\e849';
					}
					.content-file-icon:before {
						content: '\e84a';
					}
					.error {
						color: red;
					}

				}
				&.type-note {
					background-color: #fff;
					color: #aaa;
					padding: 10px 10px;
					font-style: italic;
					.message p {
						//font-size: 12px;
						font-size: 85.8%;
						line-height: 14px;
					}
					.role {
						//font-size: 12px;
						font-size: 85.8%;
					}
					+ .message-agent.type-chat {
						padding-top: 10px;
					}
					+ .message-agent.type-link {
						padding-top: 10px;
					}
					+ .message-agent {
						padding-top: 0;
						.msg-tools {
							top: -5px;
						}

					}
				}
				&.type-link {
					background-color: #f1f1f1;
					+ .message-agent.type-chat,
					+ .message-agent.type-link,
					+ .message-agent.type-video {

						padding-top: 0;
						//.role { display: none; }
						.msg-tools {
							top: -5px;
						}

					}
					+ .message-agent.type-chat {
						//p :first-of-type {display: none;}
					}
					.title,
					.date-time {
						position: relative;
						top: -2px;
					}
					i {
						font-style: normal;
						position: relative;
						top: -2px;

						&.photo:before {
							font-family: 'icon';
							content: '\e84b';
							display: inline-block;
							margin-right: 10px;
							font-size: 16px;
						}
						&.pdf:before {
							font-family: 'icon';
							content: '\e84a';
							display: inline-block;
							margin-right: 10px;
							font-size: 16px;
						}
						&.link:before {
							font-family: 'icon';
							content: '\e84c';
							display: inline-block;
							margin-right: 10px;
							font-size: 16px;
						}
						&.video:before {
							font-family: 'icon';
							content: '\e849';
							display: inline-block;
							margin-right: 10px;
							font-size: 16px;
						}
					}
					.msg-tools {
						i {
							top: 0;
						}
					}
					iframe {
						width: 100%;
						height: auto;
					}
				}
			}
			&.message-visitor.type-navigation {
				+ .message-visitor.type-chat {
					//p :first-of-type {display: inline-block;}
				}
			}
			&.message-visitor {
				+ .message-visitor.type-chat {
					padding-top: 0;
					//p :first-of-type {display: none;}
					.msg-tools {
						top: -5px;
					}

				}
				&.type-navigation {
					color: #aaa;
					p {
						background-color: #fff;
						text-align: left;
						color: #aaa;
						//padding: 10px 20px 10px 10px;
						font-style: italic;
						//font-size: 12px;
						font-size: 85.8%;
						line-height: 18px;
						//.role { display: none; }

					}
				}
				&.type-content {
					.content-image-icon, .content-video-icon, .content-file-icon {
						cursor: pointer;
					}
					.content-image-icon:before, .content-video-icon:before, .content-file-icon:before {
						font-family: 'icon';
						font-style: normal;
						display: inline-block;
						margin-right: 10px;
						font-size: 16px;
					}
					.content-image-icon:before {
						content: '\e84b';
					}
					.content-video-icon:before {
						content: '\e849';
					}
					.content-file-icon:before {
						content: '\e84a';
					}
					.error {
						color: red;
					}

				}
				&.unread0 {
					border-left: 5px solid black;
					animation: fadein 1s;
					&:last-child {
						border-left: 5px solid #EB0D0D;
					}
				}
				&.unread1 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread2 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread3 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread4 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread5 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread6 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread7 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
				&.unread8 {
					border-left: 5px solid black;
					animation: fadeout 1s;
				}
			}
		}
	}
}
