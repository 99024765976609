.messaging-icon {
	&:before {
		font-family: "icon";
		font-style: normal;
		font-weight: normal;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: .2em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: .2em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

}

.facebook-messenger-icon {
	.messaging-icon;

	&:before {
		font-size: 14px;
		content: '\e894';
		color: #3C5A99;
	}
}

.sms-icon {
	.messaging-icon;

	&:before {
		font-size: 14px;
		content: '\e895';
	}
}

.email-icon {
	.messaging-icon;

	&:before {
		font-size: 14px;
		content: '\e893';
	}
}

.telephone-icon {
	.messaging-icon;

	&:before {
		font-size: 14px;
		content: '\e896';
	}
}
