.column-table {
	margin-bottom: 30px;

	&.with-controls {
		td:last-child {
			padding-right: 100px;
		}
	}

	&.wrap-last-col {
		td:last-child {
			word-break: break-all;
			word-wrap: break-word;
			word-break: break-word;
		}
	}

	.table-responsive {
		thead {
			border-bottom: 1px solid #ddd;
		}
		.delegate {
			padding: 20px;

			//aligning single role claim rows
			span.form-control {
				border:none;
				box-shadow:none;
				background-color:transparent;
			}

			td {
				vertical-align: middle;
			}
			.form-group {
				margin-bottom: 0;
			}

		}
	}

	.search {
		position: relative;
		width: 380px;

		.vngage-icon-search {
			color: #d2d2d2;
			font-size: 24px;
			position: absolute;
			top: 0;
			left: 3px;
			pointer-events: none;
		}

		input[type="search"] {
			background: transparent;
			border: 0;
			box-shadow: none;
			padding-left: 42px;

			&::-webkit-input-placeholder { font-style: italic; }
			&::-moz-placeholder { font-style: italic; }
			&:-ms-input-placeholder { font-style: italic; }
		}
	}

	.card {
		border-radius: 5px;

		.card-heading {
			border-radius: 5px 5px 0 0;
			padding-left: 15px;
			padding-right: 10px;
		}
		.card-footer {
			border-radius: 0 0 5px 5px;
		}
	}

	.table {
		margin-bottom: 0;

		.profile-picture {
			width: 32px;
			height: 32px;
			overflow: hidden;
			vertical-align: middle;

			img {
				border-radius: 50%;
				width: 32px;
				height: 32px;
				background-color: #cdcdcd;
			}
		}

		th {
			@headerCol: #fdf3e5;
			font-family: @headings-font-family;
			background-color: @headerCol;
			position: relative;
			font-weight: normal!important;

			&.pointer:hover {
				background-color: saturate(darken(@headerCol, 2%), 3%);
			}
			&.index {
				text-align: center;
			}

			&.sorting:before {
				content: '';
				display: block;
				border: solid 5px transparent;
				border-top-color: #000;
				position: absolute;
				top: 50%;
				right: 10px;
				margin-top: -2.5px;
				transform-origin: 50% 25%;
				transition: all 0.4s;
			}
			&.sorting-reverse:before {
				transform: rotate(180deg);
			}
		}

		td { vertical-align: top; }
		th.index,
		td.index {
			width: 0.01%;
			padding-left: 10px;
			padding-right: 10px;
		}

		.form-inline .input-group > .form-control { width: 400px; }

		td:last-child {
			position: relative;
		}

		.list-action-icons {
			position: absolute;
			top: 4px;
			right: 10px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			i {
				color: rgba(255, 255, 255, 0);
				font-size: 18px;
				margin: 0;
				padding: 0;

				&:before {
					width: 0.8em;
				}
			}
		}

		tr:hover .list-action-icons i {
			color: #c5c5c5;

			&.inactive {
				color: #ddd !important;
			}

			&:hover {
				&.vngage-icon-up-open,
				&.vngage-icon-down-open{
					color: @brand-action;
				}

				&.vngage-icon-cancel {
					color: @brand-danger;
				}
			}
		}

		.select {
			.input-group-addon:first-child {
				border-right: 1px solid #cccccc;
				left: 4px;
				position: relative;
				z-index: 99;
			}
		}
	}
}
