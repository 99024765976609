/* Include this file in your html if you are using the CSP mode. */

.uib-datepicker .uib-title {
  width: 100%;
}

.uib-day button, .uib-month button, .uib-year button {
  min-width: 100%;
}

.uib-left, .uib-right {
  width: 100%
}

.uib-datepicker-popup.dropdown-menu {
  display: block;
  float: none;
  margin: 0;
}

.uib-button-bar {
  padding: 10px;
}

.uib-position-measure {
  display: block !important;
  visibility: hidden !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.uib-position-scrollbar-measure {
  position: absolute !important;
  top: -9999px !important;
  width: 50px !important;
  height: 50px !important;
  overflow: scroll !important;
}

.uib-position-body-scrollbar-measure {
  overflow: scroll !important;
}
[uib-tooltip-popup].tooltip.top-left > .tooltip-arrow,
[uib-tooltip-popup].tooltip.top-right > .tooltip-arrow,
[uib-tooltip-popup].tooltip.bottom-left > .tooltip-arrow,
[uib-tooltip-popup].tooltip.bottom-right > .tooltip-arrow,
[uib-tooltip-popup].tooltip.left-top > .tooltip-arrow,
[uib-tooltip-popup].tooltip.left-bottom > .tooltip-arrow,
[uib-tooltip-popup].tooltip.right-top > .tooltip-arrow,
[uib-tooltip-popup].tooltip.right-bottom > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.top-left > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.top-right > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.bottom-left > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.bottom-right > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.left-top > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.left-bottom > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.right-top > .tooltip-arrow,
[uib-tooltip-html-popup].tooltip.right-bottom > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.top-left > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.top-right > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.bottom-left > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.bottom-right > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.left-top > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.left-bottom > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.right-top > .tooltip-arrow,
[uib-tooltip-template-popup].tooltip.right-bottom > .tooltip-arrow,
[uib-popover-popup].popover.top-left > .arrow,
[uib-popover-popup].popover.top-right > .arrow,
[uib-popover-popup].popover.bottom-left > .arrow,
[uib-popover-popup].popover.bottom-right > .arrow,
[uib-popover-popup].popover.left-top > .arrow,
[uib-popover-popup].popover.left-bottom > .arrow,
[uib-popover-popup].popover.right-top > .arrow,
[uib-popover-popup].popover.right-bottom > .arrow,
[uib-popover-html-popup].popover.top-left > .arrow,
[uib-popover-html-popup].popover.top-right > .arrow,
[uib-popover-html-popup].popover.bottom-left > .arrow,
[uib-popover-html-popup].popover.bottom-right > .arrow,
[uib-popover-html-popup].popover.left-top > .arrow,
[uib-popover-html-popup].popover.left-bottom > .arrow,
[uib-popover-html-popup].popover.right-top > .arrow,
[uib-popover-html-popup].popover.right-bottom > .arrow,
[uib-popover-template-popup].popover.top-left > .arrow,
[uib-popover-template-popup].popover.top-right > .arrow,
[uib-popover-template-popup].popover.bottom-left > .arrow,
[uib-popover-template-popup].popover.bottom-right > .arrow,
[uib-popover-template-popup].popover.left-top > .arrow,
[uib-popover-template-popup].popover.left-bottom > .arrow,
[uib-popover-template-popup].popover.right-top > .arrow,
[uib-popover-template-popup].popover.right-bottom > .arrow {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  margin: 0;
}

[uib-popover-popup].popover,
[uib-popover-html-popup].popover,
[uib-popover-template-popup].popover {
  display: block !important;
}

[uib-typeahead-popup].dropdown-menu {
  display: block;
}
