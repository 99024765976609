.incontact-login {
	margin: auto;
	width: 400px;
	padding: 10px;

	.modal-body {
		min-height: 400px;
	}

	.error-msg {
		border: 2px solid red;
		border-radius: 10px;
		padding: 10px;
		color: red;
	}

	.credentials {
	}

	.form-control {
		margin-bottom: 10px;

		&.danger {
			border: 3px solid @state-danger-text;
		}

		&.success {
			border: 3px solid @label-success-bg;
		}
	}

	.incontact-login-value {
	}

	&.pending {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.desktop-failed {
	.desktop-failed-overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: #000000;
		opacity: .5;
	}

	.desktop-failed-modal {
		position: absolute;
		width: 400px;
		top: 50%;
		left: 50%;
		margin-left: -200px;
		margin-top: -180px;

		.modal-body {
			top: 15px;
			min-height: 150px;
			padding: 20px;
		}

		.modal-footer {
			text-align: center;
		}

		.error-msg {
			border: 2px solid red;
			border-radius: 10px;
			padding: 10px;
			color: red;
		}
	}
}
