.toolbar {
	display: flex;
	padding: 5px;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	border-bottom: 1px solid #cecece;
	border-top: 0;

	.vngage-icon-attention {
		color: #EB0D0D;
		position: absolute;
		font-size: 14px;
		top: 5px;
		left: 0;
		pointer-events: none;
		&:hover { cursor: pointer; }
	}
	.toolbar-btn-container {
		display: flex;
		//flex: 1;
	}
	.toolbar-btn {
		background: transparent;
		color: #444;
		font-size: 20px;
		padding: 0 2.2px;
		border-color: transparent;
		margin-right: 5px;
		//background-color: #EBEBEB;
		width: 100%;
		&.selected {
			color: @component-active-bg;
			&:hover {
				color: @component-active-bg;
				transition: color .2s ease;
			}
		}
		&:hover {
			color: @component-active-bg;
			background-color: transparent;
			transition: color .2s ease;
		}

		&:not(:disabled):not(.disabled):active {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}

		&:focus {
			background-color: transparent;
			transition: color .2s ease;
			box-shadow: none;
		}
		&.disabled {
			opacity: 1;
			color: #ccc;
		}
	}

	.plugin-toolbar-btn {
		-webkit-filter: none;
		filter: none;

		&:hover,
		&:active,
		&:focus,
		&.plugin-button-selected {
			-webkit-filter: contrast(150%) saturate(150%);
			filter: contrast(150%) saturate(150%);
		}
		&.plugin-button-enabled {
			-webkit-filter: none;
			filter: none
		}
		&.plugin-button-disabled {
			-webkit-filter: contrast(150%) saturate(0%) opacity(50%);
			filter: contrast(150%) saturate(0%) opacity(50%);
			cursor: default;
		}
		&.plugin-button-active {
			-webkit-filter: hue-rotate(170deg);;
			filter: hue-rotate(170deg);;
		}
		&.plugin-button-hidden {
			display:none;
		}
	}

	.arrow {
		opacity: 1;
		visibility: visible;
		content: "";
		display: block;
		position: absolute;
		width: 1px;
		height: 1px;
		margin-top: -8px;
		margin-left: 5px;
		border: 10px solid transparent;
		border-bottom-color: #EBEBEB;
	}

}
